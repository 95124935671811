import React, { useContext, useEffect, useState, useRef } from 'react'; 
import { Row, Col, Container, Form, Button, Tab, Nav, Image } from 'react-bootstrap';

import useTranslations from "../../lib/useTranslations";

import QuickBite from './QuickBite'; 

const Category = (props) => {

  const { addItemToCart, business, categories, category, index, hideZero, optionsFirst, isSelected,
    isCollapsed,
    removeItem,
    setItemQuantity,
    selectedMenu } = props;

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const [isOpen, setCollapsed] = useState(isCollapsed == false)

  const toggleCategoryVisible = () => {
    setCollapsed(!isOpen)
  }
  
  const _productIsOffer = (product) => {
    // if the product id is in an offer in the location
    if (selectedMenu.Location == null || selectedMenu.Location.Campaigns == null) {
      return false
    }
    let found = selectedMenu.Location.Campaigns.find((offer) => offer.productId == product.id)

    return found
  }

  const isVisible = (p) => {

    if (hideZero && p.managed === true && p.quantity <= 0 ) {
      // product inventory is managed
      return false
    }

    return p.available === true && p.restricted != true
  }

  let showData = isOpen || isSelected

  const categoryHeaderImage = () => {
    if (category.photo != null && category.photo != "") {
      return <img src={category.photo} width={100} />
    }

    return <img src={business.photo} width={100}  />
  }

  const titleElement = () => {
    //console.log("titleElement", category, business)
    if (showData) {
      return (
        <h5 className="mb-4 mt-3 col-md-12 category-header">

          {categoryHeaderImage()}
          
        <span>
          {category.name} 
        </span>
  
        <span onClick={() => toggleCategoryVisible()}>
          {!showData && <i className="fa fa-chevron-left fa-2x" aria-hidden="true" style={{color: '#999'}}></i>}
          {showData && <i className="fa fa-chevron-down fa-2x" aria-hidden="true" style={{color: '#999'}}></i>}
  
        </span>
  
      </h5>
      )
    }
    return (
      <h3 className="mb-4 mt-3 col-md-12 category-header">

        {categoryHeaderImage()}

      <span>
        {category.name}
        {/*
           <small className="h6 text-black-50">
          {data.Products.filter((p) => p.available === true).length} {tr('ITEMS')}</small>
          */
        }
      </span>

      <span onClick={() => toggleCategoryVisible()}>
        {!showData && <i className="fa fa-chevron-left fa-1x" aria-hidden="true" style={{color: '#999'}}></i>}
        {showData && <i className="fa fa-chevron-down fa-1x" aria-hidden="true" style={{color: '#999'}}></i>}

      </span>

    </h3>
    )
  }

  return (<Row key={index} id={`category_${category.id}`} >
   
      {titleElement()}

    <Col md={12} style={{
      padding: 4
    }}>
      {showData &&
        <div id={`category_content_${category.id}`} className="bg-white rounded border shadow-sm mb-4">
          {category.Products.filter((p) => isVisible(p)).map((product, i) => {
            let isOffer = _productIsOffer(product)

            return (<QuickBite
              key={i}
              product={product}
              categories={categories}
              id={product.id}
              itemClass="menu-list"
              image={product.photo}
              title={product.name}
              price={product.price}
              hasOrdering={true}
              priceUnit='$'
              isOffer={isOffer}
              getValue={(args) => {
                //console.log("getValue add to cart", product.id, args);
                if (args.quantity === 1) {
                  // make a copy of the object
                  let newItem = Object.assign({}, product);
                  newItem.quantity = 1
                  newItem.menu_id = product.menu_id || selectedMenu.id
                  addItemToCart(newItem, args);
                } else if (args.quantity === 0) {
                  removeItem(product, args);
                } else {
                  setItemQuantity(product, args.quantity);
                }

              }}
              detailColour={'#F1AD3E'}
              optionsFirst={optionsFirst}
            />)
          })}

        </div>
      }
    </Col>
  </Row>)
}

export default Category