import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import useTranslations from "../lib/useTranslations";
import { BusinessContext } from '../lib/BusinessContext';

function DeliverTypeButtons(props) {


  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  function selectDeliveryType(type) {
    // console.log('set delivery type', type)

    // go to next stage
    props.deliveryTypeSelected(type)
  }

  //console.log("type selector with props", props)

  return (

    <div className="row get-deliver-type product-delivery" id="deliver-icons">

      {props.hasTableDelivery &&

        <div id="table-delivery" className="col">

          <div id="table-delivery" className="delivery-option delivery-item"
            style={{
              borderColor: props.detailColour,
              color: props.detailColour
            }}
            onClick={() => selectDeliveryType('table')}>
            <div className="row">
              <div className="col centered">
                <img src="/images/table-service.png" alt="" />
              </div>
            </div>

            <div className="row">
              <div className="col centered">
                {tr("To the Table")}
              </div>
            </div>
          </div>
        </div>
      }

      <div id="takeaway-delivery" className="col">

        <div id="takeaway-delivery" className="delivery-option delivery-item"
          style={{
            borderColor: props.detailColour,
            color: props.detailColour
          }}
          onClick={() => selectDeliveryType('takeaway')}>

          <div className="row">
            <div className="col centered">
              <img src="/images/takeaway.png" alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col centered">
              {tr("Takeaway")}
            </div>
          </div>
        </div>
      </div>

      {props.hasHomeDelivery &&
        <div id="home-delivery" className="col " >

          <div className="delivery-option delivery-item"
            style={{
              borderColor: props.detailColour,
              color: props.detailColour
            }}

            onClick={() => {
              selectDeliveryType('delivery')
            }}
          >

            <div className="row">
              <div className="col centered">
                <img src="/images/delivery.png" alt="" />
              </div>
            </div>

            <div className="row">
              <div className="col centered">
                {tr("Delivery")}
              </div>
            </div>
          </div>

          {props.chargeRange &&
            <div className="row">
              <div className="col centered">
                <span className="delivery-charge" style={{ color: 'black' }}>
                  {tr("Delivery Charge Range")}: {props.chargeRange}
                </span>
              </div>
            </div>
          }

          {!props.chargeRange && props.minimumDeliveryCharge > 0 &&
            <div className="row">
              <div className="col centered">
                <span className="delivery-charge" style={{ color: 'black' }}>
                  Minimo Costo de entrega: {props.minimumDeliveryCharge}
                </span>
              </div>
            </div>
          }
        </div>
      }


    </div>

  )
}

export default function DeliverTypeSelector(props) {
  const { menu, order, business, onSelected } = props

  const { configNumber } = useContext(BusinessContext);

  if (order.items.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="empty-cart">
            <div className="empty-cart-text">
              <div className="empty-cart-text-title">
                ¡Tu carrito está vacío!
              </div>
              <div className="empty-cart-text-subtitle">
                ¡Agrega productos a tu carrito!
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  let chargeRange = props.deliveryChargeRange // configValue(business, "deliveryChargeRange", null)

  let minimumDeliveryCharge = configNumber(business, 'minimumDeliveryCharge', 0)

  // console.log("chargeRange", chargeRange)
  // console.log("minimumDeliveryCharge", minimumDeliveryCharge)

  return (
    <div className="menu-cart">

      <DeliverTypeButtons
        detailColour={props.detailColour}
        deliveryTypeSelected={(type) => {
          // change the stage to deliverForm
          onSelected(type)
        }
        }
        minimumDeliveryCharge={minimumDeliveryCharge}
        chargeRange={chargeRange}
        hasTableDelivery={props.hasTableDelivery}
        hasHomeDelivery={menu.deliveries == true}
      />

    </div>
  )
}

DeliverTypeSelector.propTypes = {
  menu: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onSelected: PropTypes.func.isRequired
}
