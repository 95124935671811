import { useState, useEffect } from "react";

import { getFromApi, getApiUrl, postToApi } from "./api";


const useTranslations = () => {

  const packageName = 'cc.zono.mktplace'
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'es_CO')
  const [translations, setTranslations] = useState(JSON.parse(localStorage.getItem('translations')))

  function getTranslations(user) {
    const url = getApiUrl(`/api/v1/translation/app/${packageName}/${locale}`)

    // console.log('getTranslations', url)

    getFromApi(`/api/v1/translation/app/${packageName}/${locale}`, {}, (response) => {
      //console.log("translations result", response.result)

      // convert the translations array into a map
      let trans = response.result.reduce((a, v) => ({ ...a, [v.name.toLowerCase()]: v.value }), {})

      //console.log("translations result", trans)
      //console.log("test", tr("Last 6 Months", trans))

      setTranslations(trans)
      localStorage.setItem("translations", JSON.stringify(trans));
    }, (error) => {
      console.log(url, error)
    })
  }

  function tr(key) {
    if (!translations) return key

    if (translations[key.toLowerCase()])
      return translations[key.toLowerCase()]

    // only add translations for en_US, en_GB and es_CO
    if (locale === 'en_US' || locale === 'en_GB' || locale === 'es_CO') {

      // the translation item does not exist in this locale so add a translation item
      // with the key for future translation
      let map = {
        id: 0,
        locale: locale,
        model: 'business',
        appName: packageName,
        name: key,
        value: key,
        translate: true
      }

      postToApi('/api/v1/translation/', { id: 0 }, map, (result) => {

      }, (error) => {
        console.log("error adding translation", error)
      })
    }

    // save the key as the translation to avoid repeated adds to the API
    translations[key.toLowerCase()] = key

    return key
  }


  const trmap = (key,map) => {
    let text = tr(key)

    //console.log("translate with", key, map)

    Object.keys(map).forEach((key) => {
      //let repl = `{${key.toUpperCase()}}`
      //console.log("replace", repl, "with", map[key])
      text = text.replaceAll(`{${key.toUpperCase()}}`, map[key])

      //console.log("replaced result", text)
    })

    return text
  }

  useEffect(() => {

  }, [locale]);

  return {
    tr,
    trmap,
    getTranslations,
    locale,
    setLocale,
    translations,
    setTranslations,
  };

}

export default useTranslations;