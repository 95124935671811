import React, { useState, useEffect, useContext } from 'react';
//import { Link } from 'react-router-dom';
import { Row, Col, Container, Dropdown, Accordion, Button, Form, Spinner } from 'react-bootstrap';
import Icofont from 'react-icofont';
import PageTitle from './common/PageTitle';
import CardItem from './common/CardItem';
import CategoriesCarousel from './common/CategoriesCarousel';
import { getFromApi } from '../lib/api';
import { /* BusinessProvider, */ BusinessContext } from '../lib/BusinessContext';

import useTranslations from "../lib/useTranslations";

const List = (props) => {
  /* eslint-disable  no-unused-vars */
	const { selectedProvince, selectedCity } = useContext(BusinessContext);
	const [restaurants, setRestaurants] = useState([]);
  /* eslint-disable  no-unused-vars */
	const [originalRestaurants, setOriginalRestaurants] = useState([]);
	const [cities, setCities] = useState([]);
	const [loading, setLoading] = useState(true);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();
  
	useEffect(() => {
		let businessList = [];
		setRestaurants([]);
		setLoading(true);
		getFromApi(`/api/v1/campaign/province/${selectedProvince}/Colombia`, {}, (data) => {
			data.result.forEach(element => {
				if (businessList.find(x => x.Business.name.toLowerCase().replace(/\s/g, '') === element.Business.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
			});
			setLoading(false);

			let cities = []
			businessList.forEach(business => {
				business.locations.forEach(location => {
					if (cities.find(x => x.city.toLowerCase().replace(/\s/g, '') === location.city.toLowerCase().replace(/\s/g, '')) === undefined) cities.push(location);
				});
			});
			setCities(cities);
			setRestaurants(businessList);
			setOriginalRestaurants(businessList);
		}, () => {
			console.log('failed')
		})
	}, [selectedProvince])

	const filterRestaurants = (location, event) => {
		let newRestaurantList = [];
		// if (!event.target.checked) {
		restaurants.forEach(restaurant => {
			//let isExist = false;
			restaurant.show = false;
			restaurant.locations.forEach(location_ => {
				if (location_.city === location.city) restaurant.show = event.target.checked ? true : false;
			});
			newRestaurantList.push(restaurant);
		});
		setRestaurants(newRestaurantList);
		// } else setRestaurants(originalRestaurants)
	}

	// const sortByDistance = () => {
	// 	let newRestaurantList = [];
	// 	// if (!event.target.checked) {
	// 		restaurants.sort(function (a, b) {
	// 		return a.Business.location.indexOf(a) - a.Business.location.indexOf(b);
	// 	});
	// 	restaurants.forEach(restaurant => {
	// 		let isExist = false;
	// 		restaurant.show = false;
	// 		restaurant.locations.forEach(location_ => {
	// 			if (location_.city === location.city) restaurant.show = event.target.checked ? true : false;
	// 		});
	// 		newRestaurantList.push(restaurant);
	// 	});
	// 	setRestaurants(newRestaurantList);
	// }

	return (
		<>
			<PageTitle
				title="Businesses Near You"
				subTitle="The best spots, all in one place"
			/>
			<section className="section pt-5 pb-5 products-listing">
				<Container>
					<Row className="d-none-m">
						<Col md={12}>
							<Dropdown className="float-right">
								<Dropdown.Toggle variant="outline-info">
									Sort by: <span className="text-theme">Distance</span> &nbsp;&nbsp;
								</Dropdown.Toggle>
								<Dropdown.Menu className='dropdown-menu-right shadow-sm border-0'>
									<Dropdown.Item href="#">Distance</Dropdown.Item>
									{/* <Dropdown.Item href="#/no-of-coupons">No Of Offers</Dropdown.Item>
									<Dropdown.Item href="#/rating">Rating</Dropdown.Item> */}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
					<Row>
						<Col md={3}>
							<div className="filters shadow-sm rounded bg-white mb-4">
								<div className="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
									<h5 className="m-0">Filter By</h5>
								</div>
								<div className="filters-body">
									<Accordion defaultActiveKey="0">
										<div className="filters-card border-bottom p-4">
											<div className="filters-card-header" id="headingOne">
												<h6 className="mb-0">
													<Accordion.Toggle as={Button} size='block' variant="link" className='text-left d-flex align-items-center p-0' eventKey="0">
														Location <Icofont icon='arrow-down' className='ml-auto' />
													</Accordion.Toggle>
												</h6>
											</div>
											<Accordion.Collapse eventKey="0">
												<div className="filters-card-body card-shop-filters">
													{cities.map((location, index) => (
														<Form.Check
															custom
															type='radio'
															class="radio"
															defaultChecked={false}
															name="loc"
															onClick={(e) => filterRestaurants(location, e)}
															id={index + 1}
															label={<React.Fragment>{location.city}</React.Fragment>}
														/>
													))}
													{/* <div className="mt-2"><Link to="#" className="link">See all</Link></div> */}
												</div>
											</Accordion.Collapse>
										</div>
									</Accordion>
								</div>
							</div>
							{/* <div className="filters pt-2">
			                     <div className="filters-body rounded shadow-sm bg-white">
			                        <div className="filters-card p-4">
			                           <div>
			                              <div className="filters-card-body card-shop-filters pt-0">
		                              		<Form.Check 
										        custom
										        type='radio'
										        name='partner'
										        defaultChecked={true}
										        id='custom-cb26'
										        label='Gold Partner'
										      />
		                              		<Form.Check 
										        custom
										        type='radio'
										        name='partner'
										        id='custom-cb27'
										        label='Order Food Online'
										      />
		                              		<Form.Check 
										        custom
										        type='radio'
										        name='partner'
										        id='custom-cb28'
										        label='Osahan Eat'
										      />
			                                 <hr />
			                                 <small className="text-success">Use code OSAHAN50 to get 50% OFF (up to $30) on first 5 orders. T&Cs apply.</small>
			                              </div>
			                           </div>
			                        </div>
			                     </div>
			                  </div> */}
						</Col>
						<Col md={9}>
							<CategoriesCarousel />
							<Row>
								{restaurants.map((element, index) => (
									(element.show === true || element.show === undefined) &&
									<Col md={4} sm={6} className="mb-4 pb-2" id={index}>
										<CardItem
											title={element.Business.name}
											subTitle={element.Business.description}
											imageAlt='Product'
											image={element.Business.photo}
											imageClass='img-fluid item-img'
											linkUrl={element.Business.photo}
											// offerText='65% off | Use Coupon OSAHAN50'
											time='15–25 min'
											price='$100 FOR TWO'
											showPromoted={true}
											promotedVariant='dark'
											favIcoIconColor='text-danger'
											rating='3.1 (300+)'
										/>
									</Col>
								))}
								{loading &&
									<Col md={12} className="text-center load-more">
										<Button variant="primary" type="button" disabled="">
											<Spinner animation="grow" size="sm" className='mr-1' />
											Loading...
										</Button>
									</Col>
								}
							</Row>
						</Col>
					</Row>
				</Container>

			</section>
		</>
	);
}
export default List;