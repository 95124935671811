import React, { useContext, useEffect, useState  /*, , useEffect */ } from 'react';
import { Link, useLocation } from 'react-router-dom';
/*  , InputGroup, Button, Tab, Nav, ButtonToolbar, ToggleButton, ToggleButtonGroup, */
import { Form, Row, Col, Container, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';

import PhoneInput from 'react-phone-input-2'
import ChooseAddressCard from './common/ChooseAddressCard';
import CheckoutItem from './common/CheckoutItem';
import AddAddressModal from './modals/AddAddressModal';
import MessageModal from './modals/MessageModal';
import Icofont from 'react-icofont';
import jquery from "jquery";

import { uuidv4 } from '../lib/utils'
import LatLon from '../lib/latlon';
import {getFromApi} from '../lib/api'

import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';
import { BusinessContext } from '../lib/BusinessContext';

import DeliverTypeSelector from '../order/deliver_type'

//import { ConfigProvider, ConfigContext } from "../lib/ConfigContext";

const Checkout = (props) => {

  const [addresses, setAddresses] = useState([])
  const [position, setPosition] = useState(null)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('Cash')
  const [paymentMethods, setPaymentMethods] = useState(['Cash'])
  const [methodData, SetMethodData] = useState(null)
  const [bankName, setBankName] = useState(null)
  const [deliveryChargeRange, setChargeRange] = useState(null)
  const [packingCost, setPackingCost] = useState(null)
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [deliverType, setDeliverType] = useState(null)
  const [minimumOrderValue, setMinimumOrderValue] = useState(0)
  const [minimumOrderMessage, setMinimumOrderMessage] = useState(null)
  const [fixedTable, setFixedTable] = useState(false)

  // takeaway form data
  const [name, setName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)

  // table form data
  const [table, setTable] = useState(null)

  ///////

  const { cartItems, hasAlcohol, ageConfirmed, setAgeConfirmed, dateOfBirth, setDateOfBirth, setItemQuantity, removeItem, setCartItems, setPendingOrder, totalItemsCost } = useContext(CartContext);

  const { configValue, selectedMenu, setSelectedMenu, selectedCity, selectedProvince, locationConfigValue, locationConfigNumber } = useContext(BusinessContext);

  const { tr,  /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const location = useLocation()
  //console.log(location.state)

  useEffect(() => {

    //console.log("checkout with", selectedCity, selectedProvince)

    // ensure any previous order is removed
    localStorage.removeItem('pendingOrder')
    setPendingOrder(null)

    let tn = localStorage.getItem('tableNum')

    if (tn) {
      setTable(tn)
      setDeliverType('table')
      setFixedTable(true)
    }

    let list = localStorage.getItem('addressList')

    if (list) {
      //console.log("address list", list)
      let addresses = JSON.parse(list)
      setAddresses(addresses);

      if (addresses && addresses.length > 0) {
        let fa = addresses[0]
        setName(fa.name)
        setPhone(fa.phone)
        setEmail(fa.email)
      }
    }

    if (location.state) {
      //console.log("useEffect menu", selectedMenu, location.state.selectedMenu )

      setSelectedMenu(location.state.selectedMenu)

      if (location.state.selectedMenu) {
        let mData = location.state.selectedMenu

        let cfgName = "deliveryPaymentOptions"

        if (deliverType === 'takeaway' || deliverType === "table" || table != null)
          cfgName = "takeawayPaymentOptions"

        //console.log("useEffect", deliverType, cfgName, mData)

        let md = locationConfigValue(mData.Business.Configs, mData.Location.id, cfgName, null)

        if (md) {
          //console.log("payment methods", deliverType, md)
          setPaymentMethods(md.split(","))
        }

        //setSelectedMenu(mData)

        // get the delivery charge range from business configs
        /* eslint-disable react-hooks/exhaustive-deps */
        setChargeRange(locationConfigValue(mData.Business.Configs, mData.Location.id, "deliveryChargeRange", null))
        /* eslint-disable react-hooks/exhaustive-deps */
        setPackingCost(locationConfigNumber(mData.Business.Configs, mData.Location.id, "packingCost", null))
        /* eslint-disable react-hooks/exhaustive-deps */
        setMinimumOrderValue(locationConfigNumber(mData.Business.Configs, mData.Location.id, "minimumOrderValue", 0))
        /* eslint-disable react-hooks/exhaustive-deps */
        setMinimumOrderMessage(locationConfigValue(mData.Business.Configs, mData.Location.id, "minimumOrderMessage", null))

        let banc = locationConfigValue(mData.Business.Configs, mData.Location.id, "bancolombia", null)

        if (banc) {
          setBankName('Bancolombia')
        } else {
          banc = locationConfigValue(mData.Business.Configs, mData.Location.id, "davivienda", null)
          if (banc) {
            setBankName('Davivienda')
          }
        }
      }

      if (location.state.items) {
        setCartItems(location.state.items)
      }
    } else {
      window.location.href = "/"
    }

  }, [deliverType]);

  //console.log("using", paymentMethods, minimumOrderValue , deliveryChargeRange)

  const hideAddressModal = () => setShowAddressModal(false);

  const iconForTitle = (title) => {
    switch (title) {
      case tr("Home"): return "home";
      case tr("Work"): return "briefcase";
      default: return 'building-alt'
    }
  }

  const confirmButton = () => {

    /* 
      if the minimumOrderValue > 0 and the orderValue is less than the minimum show an alert message
    */
    if (selectedMenu && minimumOrderValue > 0 && totalItemsCost() < minimumOrderValue) {
      let message = minimumOrderMessage || tr("Order value is less than the minimum")
      return (
        <div className="btn btn-success btn-block btn-lg"
          onClick={() => {
            alert(message)
          }}

        >{tr("Confirm Order")} </div>
      )
    }

    if (!deliverType) {
      return (
        <div className="btn btn-success btn-block btn-lg"
          onClick={() => {
            alert(tr("Please select either delivery or takeaway"))
          }}

        >{tr("Confirm Order")} </div>
      )
    }

    // check a delivery address is selected
    if (deliverType === 'delivery' && !deliveryAddress) {
      return (
        <div className="btn btn-success btn-block btn-lg"
          onClick={() => {
            alert(tr("Please select a delivery address"))
          }}

        >{tr("Confirm Order")} </div>
      )
    } else if ((deliverType === 'takeaway' || deliverType === 'table') && (!name || !phone)) {
      return (
        <div className="btn btn-success btn-block btn-lg"
          onClick={() => {
            alert(tr("Please enter your name and phone number"))
          }}

        >{tr("Confirm Order")} </div>
      )
    } else if (deliverType === 'table' && !table) {
      return (
        <div className="btn btn-success btn-block btn-lg"
          onClick={() => {
            alert(tr("Please enter a valid table number"))
          }}

        >{tr("Confirm Order")} </div>
      )
    }

    // check a payment method is selected
    if (!paymentMethod) {
      return (
        <div className="btn btn-success btn-block btn-lg"
          onClick={() => {
            alert(tr("Please select a payment method"))
          }}

        >{tr("Confirm Order")} </div>
      )
    }

    // check there are no restricted products (in house consumption only)
    if (deliverType !== 'table') {
      let res = cartItems.find((item, index) => item.restricted)

      if (res) {
        return (
          <div className="btn btn-success btn-block btn-lg"
            onClick={() => {
              if (deliverType !== 'delivery') {
                alert(tr("There are restricted items in the cart that cannot be delivered"))
              } else {
                alert(tr("There are restricted items in the cart that cannot be taken away"))
              }
            }}

          >{tr("Confirm Order")} </div>
        )
      }
    }

    let da = deliveryAddress || {}

    // ensure delivery data has province and country
    da.province = selectedMenu.Location.province
    da.country = selectedMenu.Location.country

    let thanksLocation = {
      pathname: '/thanks',
      state: {
        selectedMenu: selectedMenu,
        items: cartItems,
        deliverType: deliverType,
        deliveryData: da,
        paymentMethod: paymentMethod,
        methodData: methodData,
        bankName: bankName
      }
    }

    if (deliverType === 'takeaway') {
      thanksLocation.state.deliveryData = {
        name: name,
        phone: phone,
        email: email,
        city: selectedMenu.Location.city,
        province: selectedMenu.Location.province,
        country: selectedMenu.Location.country
      }
    } else if (deliverType === "table") {
      thanksLocation.state.deliveryData = {
        name: name,
        phone: phone,
        tableNum: table,
        destination: `${tr("Table")} ${table}`,
        city: selectedMenu.Location.city,
        province: selectedMenu.Location.province,
        country: selectedMenu.Location.country
      }
    }

    //console.log("thanks data", thanksLocation)

    if (paymentMethod && methodData && paymentMethod !== 'Cash') {
      return (
        <>
          <Link to={thanksLocation} className="btn btn-success btn-block btn-lg">{tr("Confirm Payment & Order")}
            <Icofont icon="long-arrow-right" /></Link>
        </>
      )
    }

    return (
      <>
        <Link to={thanksLocation} className="btn btn-success btn-block btn-lg">{tr("Confirm Order")}
          <Icofont icon="long-arrow-right" /></Link>
      </>
    )
  }

  const setPaymentDetails = (method) => {
    if (selectedMenu) {
      let mData = selectedMenu
      let methodData

      // if the method is Transfer get the bank and account number
      if (method === 'Transfer') {
        let banc = locationConfigValue(mData.Business.Configs, mData.Location.id, "bancolombia", null)

        if (banc) {
          methodData = banc
          //setBankName('Bancolombia')
        } else {

          banc = locationConfigValue(mData.Business.Configs, mData.Location.id, "davivienda", null)
          if (banc) {
            methodData = banc
            //setBankName('Davivienda')
          }
        }

      } else {
        methodData = locationConfigValue(mData.Business.Configs, mData.Location.id, method.toLowerCase(), null)
        if (!methodData) {
          configValue(mData.Business.Configs, method.toLowerCase(), null)
        }
      }
      // let md = trmap("Pay {AMOUNT} by {METHOD} to {DATA}",
      //   {
      //     AMOUNT: `$${totalItemsCost(cartItems) + packingCost}`,
      //     METHOD: tr(method),
      //     DATA: methodData
      //   })

      //console.log("set paymentData", md)
      // `${tr("Pay")} ${}  ${tr(method)}: ${methodData || ""}`
      SetMethodData(methodData)
    }
  }

  const tableContent = () => {
    //console.log("table content with", name, phone, table)

    return (
      <div>
        <Form>
          <div className="form-row">

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Name")}</Form.Label>
              <span style={{ color: 'red' }}>*</span>
              <Form.Control type="text" placeholder={tr("Your name")}
                defaultValue={name}
                onChange={(event) => {
                  // console.log("phone", event.target.value)
                  setName(event.target.value)
                }} />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Phone")}</Form.Label>
              <span style={{ color: 'red' }}>*</span>
              <PhoneInput
                country={'co'}
                value={phone}
                onChange={(event) => {
                  console.log("phone", event)
                  setPhone(event)
                }}
                inputStyle={{ width: "100%" }}
              />
            </Form.Group>

            {fixedTable ?

              <div className="col-12" style={{
                fontSize: '1.5em'
              }}>
                {tr("Table")} {table}
              </div>
              : <Form.Group className="col-md-12">
                <Form.Label>{tr("Table Number")}</Form.Label>
                <span style={{ color: 'red' }}>*</span>
                <Form.Control type="number"
                  defaultValue={table}
                  onChange={(event) => {
                    console.log("table", event.target.value)
                    try {
                      let num = parseInt(event.target.value)
                      setTable(num)
                    } catch (ex) {
                      console.log(ex)
                    }
                  }} />
              </Form.Group>
            }




          </div>
        </Form>

        <div className="pt-2"></div>

        <PaymentTypeChooser
          detailColour={'#F1AD3E'}
          methods={paymentMethods}
          paymentMethod={paymentMethod}
          methodData={methodData}
          bankName={bankName}
          paymentMethodSelected={(method) => {
            //console.log("payment method", tr(method))
            // set the payment details for the method
            if (method !== 'Cash') {
              setPaymentDetails(method)
            }
            setPaymentMethod(tr(method))
          }}

        />
      </div>
    )
  }

  const takeawayContent = () => {

    //console.log("takeaway content with", name,phone,email)

    return (
      <div>
        <Form>
          <div className="form-row">

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Name")}</Form.Label>
              <span style={{ color: 'red' }}>*</span>
              <Form.Control type="text" placeholder={tr("Your name")}
                defaultValue={name}
                onChange={(event) => {
                  // console.log("phone", event.target.value)
                  setName(event.target.value)
                }} />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Phone")}</Form.Label>
              <span style={{ color: 'red' }}>*</span>
              <PhoneInput
                country={'co'}
                value={phone}
                onChange={(event) => {
                  console.log("phone", event)
                  setPhone(event)
                }}
                inputStyle={{ width: "100%" }}
              />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>{tr("Email")}</Form.Label>
              <Form.Control type="text" placeholder={tr("Email for confirmation and receipt")}
                defaultValue={email}
                onChange={(event) => {
                  // console.log("phone", event.target.value)
                  setEmail(event.target.value)
                }} />
            </Form.Group>


          </div>
        </Form>

        <div className="pt-2"></div>

        <PaymentTypeChooser
          detailColour={'#F1AD3E'}
          methods={paymentMethods}
          paymentMethod={paymentMethod}
          methodData={methodData}
          bankName={bankName}
          paymentMethodSelected={(method) => {
            //console.log("payment method", tr(method))
            if (method !== 'Cash') {
              setPaymentDetails(method)
            }
            //for more than one payment
            setPaymentMethod(tr(method))
          }}

        />
      </div>
    )
  }

  const positionContent = () => {
    // get the position if only 1 address and no position data

    /*
      chrome go to Settings -> Privacy -> Site Settings -> Location and select "Sites can ask for your locaiton"
    */

    return (
      <div className="offer-dedicated-body-left">

        <MessageModal title={tr("Location services required")}
          show={true} onHide={() =>
            setDeliverType("delivery")
          }

          callback={() => {

            navigator.geolocation.getCurrentPosition((position) => {
              console.log("primary geolocation", position.coords)
              let result = { latitude: position.coords.latitude, longitude: position.coords.longitude }

              let address = addresses[0]
              if (address) {
                address.position = result
                localStorage.setItem('addressList', JSON.stringify(addresses))
              }
              setPosition(result)
              setDeliverType("delivery")
            }, () => {
              // proceed without position
              setDeliverType("delivery")
            })
          }}
        >
          <div className="d-flex justify-content-center" style={{
            fontSize: 48
          }}>
            <i className="text-success icofont-pin"></i>
          </div>

          <div className="d-flex justify-content-center m-4 "
            style={{
              fontSize: 40,
              fontWeight: 'bold'
            }}
          >{tr("Location Services")}</div>

          <div className='d-flex text-center justify-content-center'>
            {tr("When prompted allow this site to access your location")}
          </div>

          <div className='d-flex text-center justify-content-center'>
            {tr("On mobiles phones set your Location Mode to High Accuracy")}
          </div>

        </MessageModal>

      </div>

    )
  }

  const calculateDistance = (bizlatLng, position) => {
    let sll = LatLon.fromString(bizlatLng)
    let dll = LatLon(position.latitude, position.longitude)

    return Math.round(sll.distanceTo(dll))
  }

  // const _alsoLike = () => {
  //   return (
  //     <div className="bg-white rounded shadow-sm p-4 mb-4">
  //       <h6 className="mb-3">{tr('You may also like')}</h6>
  //       {/* <ItemsCarousel /> */}
  //     </div>
  //   )
  // }

  const deliveryContent = () => {

    let daid = locationConfigNumber(selectedMenu.Business.Configs, selectedMenu.Location.id, "deliveryServiceAgentId", 0)

    return (
      <div className="offer-dedicated-body-left">

        <div className="pt-2"></div>
        <div className="bg-white rounded shadow-sm p-4 mb-4">

          {addresses.length > 1 &&
            <div>
              <h4 className="mb-1">{tr("Choose a delivery address")}</h4>
              <h6 className="mb-3 text-black-50">{tr("Multiple addresses in this location")}</h6>
            </div>
          }

          <Row>
            {addresses.map((address, idx) =>
              <Col md={6} key={'address_' + idx}>
                <ChooseAddressCard
                  boxclassName="border border-success"
                  title={address.title}
                  icoIcon={iconForTitle(address.title)}
                  iconclassName='icofont-3x'
                  address={address}
                  onDeliverHereClick={() => {
                    // check it has a position
                    setDeliveryAddress(address)
                  
                    // if the business configs have a delivery agent id 
                    if (daid > 0 && address.position) {
                      let distance = calculateDistance(selectedMenu.Location.latlng, address.position)
                      
                      if (distance > 0) {
                        // get the delivery cost from the API
                        let url = `/api/v1/delivery/estimated_cost/${daid}/${distance}`
                        getFromApi(url, {}, (result) => {
                          console.log("estimated_cost", result.result)
                          if (result.result > 0) {
                            setChargeRange(result.result)
                          }

                        }, (error) => {
                          console.log("Error getting estimated delivery cost", error.responseJSON)
                        })

                      }

                    }

                    // otherwise calculate the delivery cost for the distance for the business

                  }}
                  boxClass={deliveryAddress && address.address === deliveryAddress.address ? 'selected' : ''}
                  onDelete={() => {
                    // delete this address
                    let list = addresses.filter((addr) => addr.id !== address.id)
                    setAddresses(list)

                    localStorage.setItem('addressList', JSON.stringify(list))
                  }}
                />
              </Col>

            )}

            <Col md={6}>
              <ChooseAddressCard
                title={newAddress.title}
                icoIcon="search-property"
                iconclassName='icofont-3x'
                type="newAddress"
                address={newAddress}
                onAddNewClick={() => {
                  navigator.geolocation.getCurrentPosition((position) => {
                    let result = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    setPosition(result)
                  }, () => {

                  })
                  setShowAddressModal(true)
                }
                }
              />
            </Col>
          </Row>


        </div>

        <div className="pt-2"></div>

        <PaymentTypeChooser
          detailColour={'#F1AD3E'}
          methods={paymentMethods}
          paymentMethod={paymentMethod}
          methodData={methodData}
          isDelivery={true}
          bankName={bankName}
          paymentMethodSelected={(method) => {
            //console.log("payment method", tr(method))
            if (method !== 'Cash') {
              setPaymentDetails(method)
            }
            //for more than one payment
            setPaymentMethod(tr(method))
          }}

        />

      </div>
    )
  }

  const validDOB = (dob) => {
    if (dob === null) return false;
    if (dob.length < 10) return false;

    // parse the date
    try {
      let d = Date.parse(dob)

      return true
    } catch (ex) {

    }
    return false
  }

  const alcoholContent = () => {
    return (
      <div className="offer-dedicated-body-left">

        <div className="pt-2"></div>
        <div className="bg-white rounded shadow-sm p-4 mb-4">
          <Form>
            <div className="form-row">

              <Row>

                <Form.Group className="col-md-12">
                  <Form.Check
                    custom
                    type="checkbox"
                    id="custom-checkbox"
                    label=" Declaro que soy comprador mayor de edad y 
                      cumplo con los requisitos de la Ley 30 de 1986."

                    onChange={(e) => {
                      console.log("on change", e.target.checked)
                      setAgeConfirmed(e.target.checked)
                    }}
                  />
                </Form.Group>

                <Form.Group className="col-md-12">
                  <Form.Label> Fecha de nacimiento (YYYY/MM/DD)
                  </Form.Label>
                  <Form.Control type="text" placeholder="Fecha de nacimiento (YYYY/MM/DD)"
                    className={validDOB(dateOfBirth) ? "" : "invalid-input"}
                    onChange={(e) => {
                      console.log("on change", e.target.value)
                      if (validDOB(e.target.value)) {
                        setDateOfBirth(e.target.value)
                      }
                    }}

                  />
                </Form.Group>
              </Row>
            </div>
          </Form>

        </div>

        <div className="pt-2"></div>

      </div>
    )
  }

  const getContent = () => {
    if (hasAlcohol() === true && (dateOfBirth === null || ageConfirmed === false)) {
      return alcoholContent()
    }
    if (deliverType === "position") return positionContent()
    else if (deliverType === "delivery") return deliveryContent()
    else if (deliverType === "takeaway") return takeawayContent()
    else if (deliverType === "table") return tableContent()

    //console.log("get delivery type", selectedMenu.tables > 0 && selectedMenu.orders == true )
    return (
      <div className="offer-dedicated-body-left">

        <div className="pt-2"></div>
        <div className="bg-white rounded shadow-sm p-4 mb-4">

          <DeliverTypeSelector menu={selectedMenu} business={selectedMenu.Business} location={selectedMenu.Location} order={{
            items: cartItems
          }}
            onSelected={(type) => {
              if (type == "delivery") {
                if (addresses.length == 0) {
                  setDeliverType("position")
                } else if (addresses.length == 1) {
                  let address = addresses[0]

                  if (!address.position || !address.position.latitude) {
                    console.log("getting position for primary address", address.address)
                    setDeliverType("position")
                  } else {
                    //console.log("primary address has position", address.position)
                    setDeliverType(type)
                  }
                } else {
                  setDeliverType(type)
                }
              } else {
                setDeliverType(type)
              }

            }}
            deliveryChargeRange={deliveryChargeRange}
            hasTableDelivery={selectedMenu.tables > 0 && selectedMenu.orders === true}

          />
        </div></div>
    )
  }

  const _getTotalCostIncPacking = () => {
    if (!deliverType) {
      return totalItemsCost(cartItems)
    }
    if (deliverType !== "table") {
      return totalItemsCost(cartItems) + packingCost
    }
    return totalItemsCost(cartItems)
  }

  let newAddress = {
    title: tr('New Address'),
    name: '',
    phone: ''
  }

  //console.log("checkout with deliverType", deliverType, deliveryChargeRange, packingCost, cartItems)

  if (selectedMenu == null) return <></>

  let totalCost = _getTotalCostIncPacking()

  return (
    <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
      <AddAddressModal show={showAddressModal}
        city={selectedCity}
        province={selectedProvince}

        onHide={hideAddressModal} onSubmit={(addr) => {
          // save to the list of addresses in local storage and set as the delivery address
          const $ = jquery;

          addr.id = uuidv4()

          let key = "AIzaSyCUX9AYx-TJRgujhMclBdzrtXsIZLdCkFs"

          key = "AIzaSyDs0smRFn4gbCSsJxpJH-Sopf_t149tFlQ"
          let address = encodeURIComponent(`${addr.address},${addr.city},${addr.province}, Colombia`)
          let uri = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`

          // use google geocoding API to convert the address to a geolocation
          const options = {
            url: uri,
            method: 'GET',
          }

          // submit to the URI denoted by path
          $.ajax(options)
            .then((data) => {
              //console.log('success', data)

              let result = data.results[0]

              let geom = result.geometry.location
              let position = { latitude: geom.lat, longitude: geom.lng }

              addr.position = position

              // get the barrio
              for(let i = 0; i < result.address_components.length; i++) {
                let comp = result.address_components[i]
                if (comp.long_name.startsWith("Barrio")) {
                  addr.barrio = comp.long_name
                }
              }

              //console.log("push address", addr)

              addresses.push(addr)

              localStorage.setItem('addressList', JSON.stringify(addresses))
              setAddresses(addresses);
              setDeliveryAddress(addr)
              setShowAddressModal(false)
              setPosition(position)

            })
            .catch((data) => {
              //console.log('error', data)
              addr.position = position

              addresses.push(addr)

              localStorage.setItem('addressList', JSON.stringify(addresses))
              setAddresses(addresses);
              setDeliveryAddress(addr)
              setShowAddressModal(false)

            });


        }} />

      <Container>
        <Row>
          <Col md={8}>
            {getContent()}
          </Col>
          <Col md={4}>
            <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
              <div className="d-flex mb-4 osahan-cart-item-profile">
                <Image fluid className="mr-3 rounded-pill" alt="osahan" src={selectedMenu.Business.photo || "/img/2.jpg"} />
                <div className="d-flex flex-column">
                  <h6 className="mb-1 text-white">{selectedMenu.Business.name}
                  </h6>
                  <p className="mb-0 text-white"><Icofont icon="location-pin" /> {selectedMenu.Location.address}, {selectedMenu.Location.city}</p>
                </div>
              </div>
              <div className="bg-white rounded shadow-sm mb-2">
                {cartItems.map((item, index) => {
                  return <CheckoutItem
                    key={'cart_item_' + index}
                    itemName={item.name}
                    cartItem={item}
                    price={item.chosenPrice || item.price}
                    priceUnit="$"
                    id={item.id}
                    quantity={item.quantity}
                    show={true}
                    minValue={0}
                    maxValue={7}
                    setValue={(args) => {
                      //console.log("checkout item", args);
                      if (args.quantity === 0) {
                        removeItem(item, args);
                      } else {
                        setItemQuantity(item, args.quantity);
                      }
                    }}
                  />
                }
                )}

              </div>

              { /* promo code */}

              <div className="mb-2 bg-white rounded p-2 clearfix">
                { /* item cost */}
                <p className="mb-1">{tr("Item Total")} <span className="float-right text-dark">${totalItemsCost()}</span></p>


                {/*  <p className="mb-1">Restaurant Charges <span className="float-right text-dark">$62.8</span></p> */}

                {packingCost && deliverType && deliverType !== "table" &&
                  <p className="mb-1">{tr("Packaging charge")}
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          {tr("Additional charges for packaging material")}
                        </Tooltip>
                      }
                    >
                      <span className="text-info ml-1">
                        <Icofont icon="info-circle" />
                      </span>
                    </OverlayTrigger>
                    <span className="float-right text-dark">{packingCost}</span>


                  </p>
                }

                { /*  delivery charge */}
                {deliveryChargeRange && deliverType === "delivery" &&
                  <p className="mb-1">{tr("Estimated Delivery Fee")}
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          {tr("Actual cost varies")}
                        </Tooltip>
                      }
                    >
                      <span className="text-info ml-1">
                        <Icofont icon="info-circle" />
                      </span>
                    </OverlayTrigger>
                    <span className="float-right text-dark">{deliveryChargeRange}</span>
                  </p>
                }


                {/* total discount*/}
                { /*
                <p className="mb-1 text-success">Total Discount
                  <span className="float-right text-success">$1884</span>
                </p>

                */}
                <hr />
                <h6 className="font-weight-bold mb-0">{tr("TO PAY")} <span className="float-right">${totalCost}</span></h6>

                {deliveryChargeRange && deliverType === "delivery" &&
                  <span className="float-right"> + {deliveryChargeRange}</span>
                }

              </div>

              {confirmButton()}


            </div>

            <div className="pt-2"></div>
            { /*
            <div className="alert alert-success" role="alert">
              You have saved <strong>$1,884</strong> on the bill
            </div>
                  */}
            <div className="pt-2"></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}


export default Checkout;

function PaymentTypeChooser(props) {

  const { methods, bankName, paymentMethod } = props

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const $ = jquery

  //console.log("payment types", methods)

  const _cashMethod = () => {
    return (
      <div className="col-12 col-md-6 ">
        <div className="payment-option"
          id="payment-option-cash"
          onClick={() => {
            //console.log("clicked")
            $(".payment-option").removeClass("selected")
            $("#payment-option-cash").addClass("selected")
            // setPaymentMethod("cash")
            props.paymentMethodSelected('Cash')
          }
          }>
          <img src="/images/cash.png" height={90} alt="Cash" />
        </div>
      </div>
    )
  }
  const _transferMethod = () => {
    return (
      <div className="col-12 col-md-6 ">
        <div className="payment-option"

          id="payment-option-transfer"
          onClick={() => {
            $(".payment-option").removeClass("selected")
            $("#payment-option-transfer").addClass("selected")
            //setPaymentMethod("transfer")
            props.paymentMethodSelected('Transfer')
          }
          }>
          {bankName === 'Bancolombia' && <img src="/img/bank/bancolombia.png" height={90} alt={bankName} />}
          {bankName === 'Davivienda' && <img src="/img/bank/davivienda.png" height={90} alt={bankName} />}

        </div>
      </div>
    )
  }

  const _nequiMethod = () => {
    return (<div className="col-12 col-md-6 ">
      <div className="payment-option"

        id="payment-option-nequi"
        onClick={() => {
          $(".payment-option").removeClass("selected")
          $("#payment-option-nequi").addClass("selected")
          //setPaymentMethod("nequi")
          props.paymentMethodSelected('Nequi')
        }
        }>
        <img src="/images/nequi.png" height={90} alt="Nequi" />
      </div>
    </div>)
  }

  const _daviplataMethod = () => {
    return (
      <div className="col-12 col-md-6 ">
        <div className="payment-option"

          id="payment-option-daviplata"
          onClick={() => {
            $(".payment-option").removeClass("selected")
            $("#payment-option-daviplata").addClass("selected")
            //setPaymentMethod("daviplata")
            props.paymentMethodSelected('Daviplata')
          }
          }>
          <img src="/images/daviplata.png" height={90} alt="Daviplata" />
        </div>
      </div>
    )
  }

  const _cardMethod = () => {
    return (
      <div className="col-12 col-md-6 ">
        <div className="payment-option"

          id="payment-option-card"
          onClick={() => {
            $(".payment-option").removeClass("selected")
            $("#payment-option-card").addClass("selected")
            //setPaymentMethod("daviplata")
            props.paymentMethodSelected('Card')
          }
          }>
          <img src="/images/cards.png" height={90} alt="Card" />
        </div>
      </div>
    )
  }

  const _allMethods = () => {
    return (
      <div className='row get-payment-method product-delivery'>

        {methods.includes('Cash') && _cashMethod()}

        {methods.includes('Transfer') && bankName && _transferMethod()}

        {methods.includes('Nequi') && _nequiMethod()}

        {methods.includes('Daviplata') && _daviplataMethod()}

        {methods.includes('Card') && _cardMethod()}


      </div>
    )
  }

  if (paymentMethod) {
    return (
      <div className="bg-white rounded shadow-sm p-4 osahan-payment">


        {_allMethods()}

      </div>
    )
  }

  return (
    <div className="bg-white rounded shadow-sm p-4 osahan-payment">
      <h4 className="mb-1">{tr("Choose payment method")}</h4>

      {_allMethods()}

    </div>
  )
}

/* 

function promoCode(props) {
  return (
    <div className="mb-2 bg-white rounded p-2 clearfix">
                <InputGroup className="input-group-sm mb-2">
                  <Form.Control type="text" placeholder="Enter promo code" />
                  <InputGroup.Append>
                    <Button variant="primary" type="button" id="button-addon2"><Icofont icon="sale-discount" /> APPLY</Button>
                  </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-0">
                  <InputGroup.Prepend>
                    <InputGroup.Text><Icofont icon="comment" /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="textarea" placeholder="Any suggestions? We will pass it on..." aria-label="With textarea" />
                </InputGroup>
              </div>
  )
}
function complexPaymentTypes(props) {

  return (
    
    <div className="bg-white rounded shadow-sm p-4 osahan-payment">
    <h4 className="mb-1">Choose payment method</h4>
    <h6 className="mb-3 text-black-50">Credit/Debit Cards</h6>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={4} className="pr-0">
          <Nav variant="pills" className="flex-column">
            <Nav.Link eventKey="first"><Icofont icon="credit-card" /> Credit/Debit Cards</Nav.Link>
            <Nav.Link eventKey="second"><Icofont icon="id-card" /> Food Cards</Nav.Link>
            <Nav.Link eventKey="third"><Icofont icon="card" /> Credit</Nav.Link>
            <Nav.Link eventKey="fourth"><Icofont icon="bank-alt" /> Netbanking</Nav.Link>
            <Nav.Link eventKey="fifth"><Icofont icon="money" /> Pay on Delivery</Nav.Link>
          </Nav>
        </Col>
        <Col sm={8} className="pl-0">
          <Tab.Content className='h-100'>
            <Tab.Pane eventKey="first">
              <h6 className="mb-3 mt-0 mb-3">Add new card</h6>
              <p>WE ACCEPT <span className="osahan-card">
                <Icofont icon="visa-alt" /> <Icofont icon="mastercard-alt" /> <Icofont icon="american-express-alt" /> <Icofont icon="payoneer-alt" /> <Icofont icon="apple-pay-alt" /> <Icofont icon="bank-transfer-alt" /> <Icofont icon="discover-alt" /> <Icofont icon="jcb-alt" />
              </span>
              </p>
              <Form>
                <div className="form-row">
                  <Form.Group className="col-md-12">
                    <Form.Label>Card number</Form.Label>
                    <InputGroup>
                      <Form.Control type="number" placeholder="Card number" />
                      <InputGroup.Append>
                        <Button variant="outline-secondary" type="button" id="button-addon2"><Icofont icon="card" /></Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="col-md-8">
                    <Form.Label>Valid through(MM/YY)
                    </Form.Label>
                    <Form.Control type="number" placeholder="Enter Valid through(MM/YY)" />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>CVV
                    </Form.Label>
                    <Form.Control type="number" placeholder="Enter CVV Number" />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Form.Label>Name on card
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter Card number" />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Form.Check
                      custom
                      type="checkbox"
                      id="custom-checkbox1"
                      label="Securely save this card for a faster checkout next time."
                    />
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-0">
                    <Link to="/thanks" className="btn btn-success btn-block btn-lg">PAY $1329
                      <Icofont icon="long-arrow-right" />
                    </Link>
                  </Form.Group>
                </div>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <h6 className="mb-3 mt-0 mb-3">Add new food card</h6>
              <p>WE ACCEPT  <span className="osahan-card">
                <i className="icofont-sage-alt"></i> <i className="icofont-stripe-alt"></i> <i className="icofont-google-wallet-alt-1"></i>
              </span>
              </p>
              <Form>
                <div className="form-row">
                  <Form.Group className="col-md-12">
                    <Form.Label>Card number</Form.Label>
                    <InputGroup>
                      <Form.Control type="number" placeholder="Card number" />
                      <InputGroup.Append>
                        <Button variant="outline-secondary" type="button" id="button-addon2"><Icofont icon="card" /></Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="col-md-8">
                    <Form.Label>Valid through(MM/YY)
                    </Form.Label>
                    <Form.Control type="number" placeholder="Enter Valid through(MM/YY)" />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>CVV
                    </Form.Label>
                    <Form.Control type="number" placeholder="Enter CVV Number" />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Form.Label>Name on card
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter Card number" />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Form.Check
                      custom
                      type="checkbox"
                      id="custom-checkbox"
                      label="Securely save this card for a faster checkout next time."
                    />
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-0">
                    <Link to="/thanks" className="btn btn-success btn-block btn-lg">PAY $1329
                      <Icofont icon="long-arrow-right" />
                    </Link>
                  </Form.Group>
                </div>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div className="border shadow-sm-sm p-4 d-flex align-items-center bg-white mb-3">
                <Icofont icon="apple-pay-alt" className="mr-3 icofont-3x" />
                <div className="d-flex flex-column">
                  <h5 className="card-title">Apple Pay</h5>
                  <p className="card-text">Apple Pay lets you order now & pay later at no extra cost.</p>
                  <Link to="#" className="card-link font-weight-bold">LINK ACCOUNT <Icofont icon="link-alt" /></Link>
                </div>
              </div>
              <div className="border shadow-sm-sm p-4 d-flex bg-white align-items-center mb-3">
                <Icofont icon="paypal-alt" className="mr-3 icofont-3x" />
                <div className="d-flex flex-column">
                  <h5 className="card-title">Paypal</h5>
                  <p className="card-text">Paypal lets you order now & pay later at no extra cost.</p>
                  <Link to="#" className="card-link font-weight-bold">LINK ACCOUNT <Icofont icon="link-alt" /></Link>
                </div>
              </div>
              <div className="border shadow-sm-sm p-4 d-flex bg-white align-items-center">
                <Icofont icon="diners-club" className="mr-3 icofont-3x" />
                <div className="d-flex flex-column">
                  <h5 className="card-title">Diners Club</h5>
                  <p className="card-text">Diners Club lets you order now & pay later at no extra cost.</p>
                  <Link to="#" className="card-link font-weight-bold">LINK ACCOUNT <Icofont icon="link-alt" /></Link>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <h6 className="mb-3 mt-0 mb-3">Netbanking</h6>
              <Form>
                <ButtonToolbar>
                  <ToggleButtonGroup className="d-flex w-100" type="checkbox" name="options" defaultValue={1}>
                    <ToggleButton variant='info' value={1}>
                      HDFC <Icofont icon="check-circled" />
                    </ToggleButton>
                    <ToggleButton variant='info' value={2}>
                      ICICI <Icofont icon="check-circled" />
                    </ToggleButton>
                    <ToggleButton variant='info' value={3}>
                      AXIS <Icofont icon="check-circled" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
                <hr />
                <div className="form-row">
                  <Form.Group className="col-md-12">
                    <Form.Label>Select Bank
                    </Form.Label>
                    <br />
                    <Form.Control as="select" className="custom-select">
                      <option>Bank</option>
                      <option>One</option>
                      <option>Two</option>
                      <option>Three</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-0">
                    <Link to="/thanks" className="btn btn-success btn-block btn-lg">PAY $1329
                      <Icofont icon="long-arrow-right" /></Link>
                  </Form.Group>
                </div>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
              <h6 className="mb-3 mt-0 mb-3">Cash</h6>
              <p>Please keep exact change handy to help us serve you better</p>
              <hr />
              <Form>
                <Link to="/thanks" className="btn btn-success btn-block btn-lg">PAY $1329
                  <Icofont icon="long-arrow-right" /></Link>
              </Form>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  </div>

  )
} */