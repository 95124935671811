import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown, Image } from 'react-bootstrap';

import { BusinessContext } from '../../lib/BusinessContext';
import { CartProvider, CartContext } from '../../lib/CartContext';

import useTranslations from "../../lib/useTranslations";


const Header = (props) => {
  return (
    <CartProvider>
      <HeaderPage />
    </CartProvider>
  )
}

const HeaderPage = (props) => {
  const ref = useRef(0);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { departments, selectedProvince, provinceSelected, selectedCity, cities, setCityName, selectedBusiness, setSelectedBusiness,
    setSelectedCity, setBusinesses, setSelectedMenu } = useContext(BusinessContext);
  const { cartItems, getProductCost, totalItemsCost } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const location = useLocation();

  const closeMenu = () => {
    setIsNavExpanded(false);
  }

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      setIsNavExpanded(false);
    }

  }

  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    // returned function will be called on component unmount 
    return () => {
      document.removeEventListener('click', handleClick, false);
    }
  }, [selectedBusiness])

  const _deptCitiesDropdown = (dept) => {
    if (cities.length > 0) {
      return (
        <NavDropdown title={selectedCity ? selectedCity : 'City'} alignRight className="border-0">
          {cities.map((city, index) => (
            <NavDropdown.Item eventKey={index} key={'city_' + index} onClick={() => {
              setCityName(city);
            }}>{city}</NavDropdown.Item>
          ))}
        </NavDropdown>
      )
    } else {
      return (
        <span className="nav-link">{selectedCity ? selectedCity : 'City'}</span>
      )
    }
  }

  const _provincesDropdown = () => {
    return (
      <NavDropdown title={selectedProvince ? selectedProvince : 'Department'} alignRight className="border-0" style={{ color: 'rgba(0,0,0,.7)' }}>
        {departments.map((element, index) => (
          <NavDropdown.Item eventKey={index} key={'dept_' + index} onClick={() => {
            provinceSelected(element);
          }} activeclassname="active">{element}</NavDropdown.Item>

        ))}
      </NavDropdown>
    )
  }
  /* 
    const _cartDropdown = () => {
      if (selectedBusiness && cartItems.length > 0) {
  
        return (
  
          <NavDropdown activeclassname="active" alignRight className="dropdown-cart"
            title={
              <DropDownTitle
                className='d-inline-block'
                faIcon='shopping-basket'
                iconClass='mr-1'
                title={tr('Cart')}
                badgeClass='ml-1'
                badgeVariant='success'
                badgeValue={cartItems.length}
              />
            }
          >
  
            <div className="dropdown-cart-top shadow-sm">
              {selectedBusiness &&
                <CartDropdownHeader
                  className="dropdown-cart-top-header p-4"
                  title={selectedBusiness.name}
                  subTitle={selectedBusiness.address}
                  image="img/cart.jpg"
                  imageClass="img-fluid mr-3"
                  imageAlt={selectedBusiness.name}
                  NavLinkUrl="#"
                  NavLinkText={tr('"View Full Menu"')}
                />
              }
              <div className="dropdown-cart-top-body border-top p-4">
                {cartItems.map((item) =>
                  <CartDropdownItem
                    icoIcon='ui-press'
                    iconClass='text-success food-item'
                    title={item.name}
                    price={getProductCost(item)}
                    item={item}
                  />
                )}
              </div>
              <div className="dropdown-cart-top-footer border-top p-4">
                <p className="mb-0 font-weight-bold text-secondary">{tr('Sub Total')} <span className="float-right text-dark">${totalItemsCost()}</span></p>
                <small className="text-info">{tr('Extra charges may apply')}</small>
              </div>
              <div className="dropdown-cart-top-footer border-top p-2">
                <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/checkout"> {tr('Checkout')}</NavDropdown.Item>
              </div>
            </div>
          </NavDropdown>
  
        )
      }
  
      return <div></div>
  
    }
   */
  // console.log("render header", departments, selectedProvince, cities, selectedCity)

  return (
    <div ref={ref}>
      <Navbar onToggle={() => setIsNavExpanded(true)}
        expanded={isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav shadow-sm">
        <Container>
          <Navbar.Brand style={{ cursor: 'pointer' }}   >
            <div onClick={() => {
              localStorage.removeItem('selectedMenu')
              setSelectedMenu(null)
              setSelectedBusiness(null)
              setSelectedCity('')
              setBusinesses([])

              if (location.pathname !== "/") {
                window.location.href = "/"
              }

            }}>
              <Image src="/images/new-xoonoo-logo.png" alt='' />
            </div>
          </Navbar.Brand>

          <div className="show-only-large-devices">
            <span>Promos Exclusivas , Mas Rapido, Mas Facil</span>

            <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
              <span style={{
                marginLeft: 20,
                padding: 5,
                cursor: 'pointer',
                backgroundColor: '#F1AD3E',
                borderRadius: 5,
                color: 'black'
              }} >Descarga La App
              </span>
            </a>

          </div>


          <div className="show-only-small-devices">
            <span>Promos Exclusivas</span><br />
            <span>Mas Rapido, Mas Facil</span><br />
            <span></span><br />

            <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
              <span style={{
                marginTop: 20,
                padding: 5,
                cursor: 'pointer',
                backgroundColor: '#F1AD3E',
                borderRadius: 5,
                color: 'black'
              }} >Descarga La App
              </span>
            </a>

          </div>



          <Navbar.Toggle />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav activeKey={0} className="ml-auto" onSelect={closeMenu}>
              <a className="nav-link" href="https://ally.xoonoo.com">
                Hazte Aliado <span className="sr-only">(current)</span>
              </a>

              <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/history">
                {tr('My Orders')} <span className="sr-only">(current)</span>
              </Nav.Link>
              {/* <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/listing">
                {tr('Restaurants')} <span className="sr-only">(current)</span>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" >
                <Icofont icon='sale-discount' /> {tr('Restaurants')} <Badge variant="danger"></Badge>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers">
                <Icofont icon='sale-discount' /> {tr('Offers')} <Badge variant="danger">{tr('New')}</Badge>
              </Nav.Link> */}
              {/* <NavDropdown title={tr('Restaurants')} alignRight className="border-0">
                <NavDropdown.Item eventKey={2.1} as={NavLink} activeclassname="active" to="/listing">{tr('Listing')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={2.2} as={NavLink} activeclassname="active" to="/detail">{tr('Detail + Cart')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={2.3} as={NavLink} activeclassname="active" to="/checkout">{tr('Checkout')}</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={tr('Pages')} alignRight>
                <NavDropdown.Item eventKey={3.1} as={NavLink} activeclassname="active" to="/track-order">{tr('Track Order')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.2} as={NavLink} activeclassname="active" to="/invoice">{tr('Invoice')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.3} as={NavLink} activeclassname="active" to="/login">{tr('Login')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.4} as={NavLink} activeclassname="active" to="/register">{tr('Register')}</NavDropdown.Item>                
                <NavDropdown.Item eventKey={3.6} as={NavLink} activeclassname="active" to="/extra">{tr('Extra')}</NavDropdown.Item>

              </NavDropdown> */}
              {/* <NavDropdown alignRight
                title={
                  <DropDownTitle
                    className='d-inline-block'
                    image="img/user/4.png"
                    imageAlt='user'
                    imageClass="nav-osahan-pic rounded-pill"
                    title={tr('My Account')}
                  />
                }
              >
                <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders"><Icofont icon='food-cart' /> {tr('Orders')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.2} as={NavLink} activeclassname="active" to="/myaccount/offers"><Icofont icon='sale-discount' /> {tr('Offers')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/myaccount/favourites"><Icofont icon='heart' /> {tr('Favourites')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/myaccount/payments"><Icofont icon='credit-card' /> {tr('Payments')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.5} as={NavLink} activeclassname="active" to="/myaccount/addresses"><Icofont icon='location-pin' /> {tr('Addresses')}</NavDropdown.Item>
              </NavDropdown>

              {_cartDropdown()} */}

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;