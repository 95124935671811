
import React, {   /*, useContext, useEffect, , useEffect */ } from 'react';
import {
  Modal, Button, 
 } from 'react-bootstrap';

const MessageModal = ({
  children,
  title = "",
  buttonText = "OK",
  headerComponent,
  classNames = "",
  show,
  onHide,
  callback
}) => {

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton={true}>
        <Modal.Title as='h5' id="add-address">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ height: "auto" }}>
          {children}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'
          onClick={callback}
        >{buttonText}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default MessageModal;