import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Media } from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types';

import useTranslations from "../../lib/useTranslations";

function ChooseAddressCard(props) {

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const addressLine = () => {
    if (props.address.address && props.address.city) {
      return props.address.address + ', ' + props.address.city
    }
    return ''
  }

  return (
    <Card className={"bg-white addresses-item mb-4 " + (props.boxClass)}>
      <div className="gold-members p-4">
        <Media>
          <div className="mr-3"><Icofont icon={props.icoIcon} className={props.iconclassName} /></div>
          <div className="media-body">
            <h6 className="mb-1 text-secondary">{props.title}  </h6>
            <div className="text-black">{props.address.name}</div>
            <div className="text-black">{props.address.phone}</div>
            <p className="text-black">{addressLine() }</p>
            {props.address.instructions && 
              <p className="text-black">{props.address.instructions }</p>
            }
            
            <div className="mb-0 text-black font-weight-bold">
              {props.type === "hasAddress" ?
                (<div style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}

                ><Link className="btn btn-sm btn-success mr-2" to="#" onClick={props.onDeliverHereClick}> {tr("DELIVER HERE")} </Link>

                <span
                onClick={props.onDelete}
                
                ><Icofont icon={"trash"} className="icofont-2x" /></span>
                 {/* time to deliver to this address  <span>30MIN</span>   */}
                  
                  </div>)
                : <Link className="btn btn-sm btn-primary mr-2" to="#" onClick={props.onAddNewClick}> {tr("ADD NEW ADDRESS")} </Link>
              }
            </div>
          </div>
        </Media>
      </div>
    </Card>
  );
}

ChooseAddressCard.propTypes = {
  title: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclassName: PropTypes.string,
  address: PropTypes.object,
  onDeliverHere: PropTypes.func,
  onAddNewClick: PropTypes.func,
  type: PropTypes.string.isRequired
};

ChooseAddressCard.defaultProps = {
  type: 'hasAddress'
}


export default ChooseAddressCard;
