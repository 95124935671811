import React, { /*useState */ } from 'react'
//import PropTypes, { object } from 'prop-types'

import useTranslations from "../lib/useTranslations";
import Masonry from 'react-masonry-css'

function ProductSizeItem(props) {

  const { product, size, detailColour, onSelected } = props

  const checkMark = () => {
    return (
      <span className="col-4 centrar" style={{ fontSize: "20px", paddingTop: 0, paddingLeft: 10, textAlign: 'center' }}>
       <i className="icofont-check-circled"
        style={{
          color: 'green'
        }
        }
      ></i>
    </span>
    )
  }

  const offRadio = () => {
    return (
      <span className="col-4 centrar" style={{ fontSize: "20px", padding: 0, paddingTop: '10px',paddingLeft: "10px", textAlign: 'center' }}>
       <span style={{
        backgroundColor: '#fafafa',
        border: '2px solid #cacece',
        borderRadius: 50,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05), 0 -15px 10px -12px rgba(0, 0, 0, 0.05) inset',
        display: 'inline-block',
        padding: 11,
        position: 'relative'
      }}> </span> 
    </span>
    )

    // <input type="radio" />
  }

  // this size is selected if it is the product chosenSize

  //console.log("size option", product.chosenSize, size, )

  return (
    <div className="product-addon-item" onClick={() => onSelected(size)} >
      <div className="product-addon-item-top"
        style={
          {
            backgroundColor: detailColour,
          }
        }
      >
        <div className="valign-middle">
          {size.size}
        </div>
        <div className="valign-middle">
          ${size.price}
        </div>
      </div>

      <div className="product-addon-item-bottom centrar" >
        {size.size === product.chosenSize ? checkMark() :  offRadio() }
      </div>

    </div>
  )
}

function ProductSizeSelector(props) {
  const { sizeSelected, product, sizes } = props

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  return <select style={{
    width: '95%'
  }}
    onChange={(event) => {
      //console.log("selected", event.target.value)
      sizeSelected(event.target.value)
    }} >
    <option>{tr("Select Size")}</option>
    {sizes.map((a) =>

      <option key={"p_" + product.id + "_addon" + a.id} value={a.id}
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      > {a.size}  ${a.price}
      </option>
    )
    }
  </select>
}

export default function ProductSizes(props) {
  const { product, selectSize } = props;

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  /* eslint-disable eqeqeq */
  if (!product.Sizes || product.Sizes.length == 0)
    return (<div></div>)


  //if (props.ordersAvailable != true) return (<div></div>)

  const breakpointColumnsObj = {
    default: 5,
    //    1200: 5,
    900: 4,
    700: 3,
    500: 2
  };

  const _sizesContent = (sizes) => {

    // add the default size 
    if (product.size) {
      sizes = [{
        size: product.size,
        price: product.price,
      },...sizes]

      if (!product.chosenPrice && !product.chosenSize) {
        product.chosenPrice = product.price
        product.chosenSize = product.size
      }
    }


    // sort by price
    sizes.sort((a,b) => a.price - b.price)

    //console.log("_sizesContent with", product, sizes)

    if (sizes.length > 4) {
      // show selected Sizes and the Size selector
      // get the selected Sizes and show in a masonry element

      // show the Size selector

      return <div>

        <ProductSizeSelector product={product}
          sizes={sizes}
          sizeSelected={(id) => {
            //console.log("selected Size", id)
            selectSize(sizes.find((Size) => Size.id == id))
          }} />
      </div>
    }

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >

        {sizes.map((c) =>
          <ProductSizeItem
            key={"p_" + product.id + "_addon" + c.id}
            product={product} size={c}
            onSelected={selectSize}
            detailColour={props.detailColour}
          />
        )
        }

      </Masonry>
    )
  }

  //console.log("Sizes", product.Sizes)

  return (
    <div className="product-Sizes product-sizes">

      <h5 style={{ margin: "10px" }}>{tr("Size")}</h5>
      {/* <div className="open-Sizes-button"
        onClick={toggleSizes}
      >
        {showSizes &&
          <span>Adicionales -</span>
        }
        {showSizes == false &&
          <span>Adicionales +</span>
        }
      </div> */}

      {_sizesContent(product.Sizes)}

    </div>
  )

}
