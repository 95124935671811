import React, { useContext, useState, useEffect } from 'react';

//import PropTypes from 'prop-types';

import { uuidv4 } from "../lib/utils";
import { CartContext } from '../lib/CartContext';
import useTranslations from "../lib/useTranslations";
import { groupBy } from '../lib/utils'

import MessageModal from '../components/modals/MessageModal';

import ProductAddons from './addons'
// import ProductComponents from './components'
import ProductOptions from './options'
import ProductSizes from './sizes'


export default function Product(props) {
  const { onClose, optionsFirst, hasOrdering, value, setDisplayTotal, categories, isOffer } = props;
  const { getProductCost, getItemTotalCost, comboProductPriceForSize } = useContext(CartContext);

  const [product, setProduct] = useState({})
  //const [quantity, setQuantity] = useState(props.quantity || 0)
  //const [maxValue, setMaxValue] = useState(props.max || 5)
  const [displayPrice, setDisplayPrice] = useState(props.product.price)
  const [showMessageModal, setShowMessageModal] = useState(false)

  const forceUpdate = React.useReducer(() => ({}), {})[1]

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  /* eslint-disable no-unused-vars */
  const increaseAddon = (addon) => {

    let selectedAddons = product.selectedAddons || []

    // check that the product does not have a maximum number of addons
    // if so total the number of addons and check that it is less than the maximum
    if (product.maxAddons && product.maxAddons > 0) {
      let sum = selectedAddons.reduce((sum, a) => sum + a.quantity, 0)

      if (sum >= product.maxAddons) {
        return
      }
    }

    // if the product addons contains the addon increase the number else add it
    let found = selectedAddons.find((a) => a.name === addon.name)

    if (found !== undefined) {
      // remove if quantity > max
      //console.log("found", found)

      if (found.max === 1) {
        //console.log("remove", found) 
        return // product.selectedAddons = selectedAddons.filter((a) => a.name != addon.name)
      } else {
        found.quantity = (found.quantity || 0) + 1
      }

      //console.log("increaseAddon", found)

    } else {
      // add a copy of the addon
      let newAddon = Object.assign({}, addon)
      newAddon.quantity = 1
      selectedAddons.push(newAddon)
      //console.log("increaseAddon newAddon", newAddon)
      product.selectedAddons = selectedAddons
    }

    let productCost = getItemTotalCost(product)
    // adjust the price according to the addon price
    setDisplayPrice(productCost)

    setDisplayTotal(productCost, product)

    forceUpdate()
  }

  /* eslint-disable no-unused-vars */
  const decreaseAddon = (addon) => {
    let selectedAddons = product.selectedAddons || []

    //console.log("decreaseAddon", addon)
    // if the product addons contains the addon increase the number else add it
    let found = selectedAddons.find((a) => a.name === addon.name)

    if (found !== undefined) {
      found.quantity = found.quantity - 1
    }

    if (found.quantity === 0) {
      // remove 
      product.selectedAddons = selectedAddons.filter((a) => a.name !== addon.name)

    }

    let productCost = getItemTotalCost(product)
    // addjust the price according to the addon price
    setDisplayPrice(productCost)

    setDisplayTotal(productCost, product)

    forceUpdate()
  }

  /* eslint-disable no-unused-vars */
  const optionSelected = (option, group) => {

    let selectedOptions = product.selectedOptions || []

    let found = selectedOptions.find((o) => o.id == option.id)

    //console.log("option selected", option, found, selectedOptions)

    if (found) {
      // remove this option - it was clicked again
      selectedOptions = selectedOptions.filter((a) => a.id !== option.id)

    } else {

      // if the selected option is mutex (part of a radio group)
      if (group.mutex && selectedOptions.length > 0) {
        // remove other options in the same grouṕ
        selectedOptions = selectedOptions.filter((a) => a.group_name !== option.group_name)
      }

      selectedOptions.push(option)
    }

    product.selectedOptions = selectedOptions

    let productCost = getItemTotalCost(product)
    // addjust the price according to the addon price
    setDisplayPrice(productCost)

    setDisplayTotal(productCost, product)

    forceUpdate()

    //console.log("selected options", selectedOptions )
  }

  const optionDeselected = (option) => {

  }

  const _allMandataryOptionsSelected = () => {

    let selectedOptions = product.selectedOptions || []

    if (!product.OptionGroups || product.OptionGroups.length === 0)
      return true

    const groups = product.OptionGroups

    //console.log("option groups", groups)
    //console.log("selected options", selectedOptions)

    // for any group - if there is no selected option return false
    for (let i = 0; i < groups.length; i++) {
      // get the first option in the group and if it is mandatry check there is a selected option
      let group = groups[i]

      let options = group.Options.filter((opt) => opt.available === true)

      // if the group is mandatory and there are some available options
      // that are not selected do not allow the user to continue
      if (group.mandatory && options.length > 0) {
        // if there is no selected option in this group then return false
        let found = selectedOptions.find((a) => a.group_id === group.id)

        if (!found) return false
      }
    }

    return true
  }

  /* eslint-disable no-unused-vars */
  const componentSelected = (comp) => {
    /* 

   let selectedComponents = product.selectedComponents || []
   let found = selectedComponents.find((a) => a.name == comp.name)

   if (found != undefined) {
     // remove
     product.selectedComponents = selectedComponents.filter((a) => a.name != comp.name)
   } else {
     // add
     selectedComponents.push(comp)
     product.selectedComponents = selectedComponents
   } */

    comp.selected = !comp.selected
    setDisplayPrice(getItemTotalCost(product))

    forceUpdate()
  }

  const selectSize = (size) => {
    //console.log("select size", size)
    // set the size option to the chosen size
    product.chosenSize = size.size
    // set the display price to the chosen size
    product.chosenPrice = size.price

    let productCost = getItemTotalCost(product)
    // adjust the price according to the size price
    setDisplayPrice(productCost)

    setDisplayTotal(productCost, product)

    forceUpdate()
  }

  useEffect(() => {
    let prod = Object.assign({}, props.product)

    setProduct(prod)
    setDisplayPrice(prod.price)

    //console.log("product useEffect", prod)

  }, [props.product])

  const _statusSection = (message) => {
    return (<div className="centered" style={{
      marginLeft: 20,
      marginRight: 20,
      color: props.detailColour || 'red'
    }}>
      {message}
    </div>)
  }

  const optionsAndAddons = () => {
    if (optionsFirst) {
      return (
        <div>

          <ProductOptions product={product}
            detailColour={props.detailColour}
            darkMode={props.darkMode}
            ordersAvailable={props.ordersAvailable}
            onSelected={optionSelected}
          />

          {product.Options && product.Options.length > 0 && <hr />}

          <ProductAddons product={product}
            detailColour={props.detailColour}
            darkMode={props.darkMode}
            ordersAvailable={props.ordersAvailable}
            //onSelected={addonSelected}
            onIncrease={increaseAddon}
            onDecrease={decreaseAddon}
          />

        </div>
      )
    }

    return (
      <div>

        <ProductAddons product={product}
          detailColour={props.detailColour}
          darkMode={props.darkMode}
          ordersAvailable={props.ordersAvailable}
          //onSelected={addonSelected}
          onIncrease={increaseAddon}
          onDecrease={decreaseAddon}
        />

        <ProductOptions product={product}
          detailColour={props.detailColour}
          darkMode={props.darkMode}
          ordersAvailable={props.ordersAvailable}
          onSelected={optionSelected}
        />
      </div>
    )
  }

  const sizeOptions = () => {
    //console.log("sizeOptions with", product, props)

    if (product.Sizes && product.Sizes.length > 0) {
      return (<ProductSizes product={product} selectSize={selectSize} detailColour={props.detailColour} />)
    }
    return <></>
  }

  const selectComboItem = (product, combo, selectedItem, category) => {
    //  replace the combo selectedItem

    combo.selectedItem = selectedItem

    //console.log("product", product)

    let productCost = getItemTotalCost(product)
    // adjust the price according to the combo price
    setDisplayPrice(productCost)

    setDisplayTotal(productCost, product)

    forceUpdate()
  }

  const comboProductDisplayCost = (item, chosenSize, multiplier) => {

    if (product.price > 0) return ""

    return "$" + comboProductPriceForSize(item, chosenSize, multiplier)
  }

  const comboOptions = () => {

    if (product.type !== 'Combo') return <></>

    //console.log("combo", product)

    // display a drop down selector for each combo item
    let chosenSize = product.chosenSize ? product.chosenSize : product.size

    //console.log("product", chosenSize, product.size, product)

    return (
      <div>
        {product.Combos && product.Combos.map((combo, index) => {

          let category = categories.find((c) => c.id === combo.category_id)

          if (!category || !category.Products || category.Products.length === 0) return <></>

          // remove this product from the options
          let catprods = category.Products.filter((p) => p.id != product.id)

          return (
            <div className="combo_selector" key={`combo_${index}`}>
              <span>{combo.title}</span>
              <select onChange={(e) => {
                //console.log("combo selected", e.target.value)

                let item = category.Products.find((p) => p.id == e.target.value)

                selectComboItem(product, combo, item, category)

              }}>
                <option></option>
                {catprods.map((p) => {
                  return (
                    <option value={p.id} key={`comboprod_${p.id}`} >{p.name} {comboProductDisplayCost(p, chosenSize, combo.multiplier)}</option>
                  )
                }
                )}
              </select>
            </div>
          )
        }

        )}
      </div>
    )

  }

  const configs = product.Configs || []
  const statusMessage = configs.find((cfg) => cfg.name === 'statusMessage' && cfg.model === 'product')

  //console.log("product with", product)

  function ProductInfo(props) {
    const { product, price } = props

    // const decrement = () => {
    //   if (amount > 1)
    //     setAmount(amount - 1)
    // }

    // const increment = () => {
    //   setAmount(amount + 1)
    // }

    // const minusButton = () => {
    //   if (amount > 1) {
    //     return <div className="col-1 enabled-amount"
    //       style={{
    //         color: props.detailColour
    //       }}
    //       onClick={decrement}>-</div>
    //   }
    //   return <div className="col-1 disabled-amount" >-</div>
    // }

    //console.log("product", product)
    return (
      <div className="product-detail"  >

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 description">
            {product.description}
          </div>
        </div>
        {product.restricted == true &&
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 description">
              este producto no está disponible para llevar o entrega
            </div>
          </div>

        }

      </div>
    )

  }

  function ProductOrderButton(props) {
    const { product, price, onClose, detailColour } = props

    const [comments, setComments] = useState(null)

    const { addItemToCart /* cartItems,  setItemQuantity, removeItem,   , incrementItem,   decrementItem, clearCart, totalItemsCost */ } = useContext(CartContext);

    const [amount, setAmount] = useState(1)

    const addToCart = () => {

      // if this is an offer popup a dialog to install the app
      if (isOffer) {
        setShowMessageModal(true)

        return
      }

      // ensure all product combos are selected

      if (product.Combos && product.Combos.length > 0) {
        // if any combo does not have a selectedItem alter and return
        let notSelected = product.Combos.find((pc) => pc.selectedItem === undefined)

        if (notSelected) {
          alert(notSelected.title)
          return
        }
      }

      if (!hasOrdering) {
        alert(tr("This business cannot take orders at the moment, please check back later"))
        return
      }

      // ensure mandatory options are selected
      if (!_allMandataryOptionsSelected()) {
        alert(tr("Please select all mandatory options"))
        return
      }

      let newItem = Object.assign({}, product);
      newItem.quantity = value
      newItem.comments = comments
      newItem.uuid = uuidv4()
      let args = { id: newItem.id, quantity: 1 }  // not used
      addItemToCart(newItem, args);
      onClose()
    }

    //console.log("order button with", props)

    return (
      <div>
        <div className="product-info">

          <div className="">
            <h6>
              Comentarios
            </h6>
            <textarea name="comments" id="order_comments"
              placeholder='¿Sin salsas?¿Sin cebolla?...:'
              style={{
                width: '90%'
              }}
              onChange={(e) => {
                setComments(e.target.value)
              }}
            >
            </textarea>
          </div>
        </div>

        <div className="product-total" style={{ margin: "10px" }}>

          {/* <span className="plus-multiple"
            style={{
            }}
          >Total: <b>${value * price}</b></span> */}
        </div>


        <div className=" product-total">
          <div className="add-item-button btn-success"
            onClick={addToCart}
            style={{
              backgroundColor: detailColour,
            }}
          >
            <span style={{
              color: 'black'
            }}><i className='fa fa-shopping-cart'></i>
            </span>
            <span>Agregar al carito</span>
            <span></span>
          </div>
        </div>

      </div>
    )
  }

  return (

    <div className="product-details">
      <ProductInfo product={product}
        detailColour={props.detailColour}
        darkMode={props.darkMode}
        price={getItemTotalCost(product)}
      />

      {comboOptions()}

      {sizeOptions()}

      {optionsAndAddons()}

      <ProductOrderButton product={product} price={displayPrice} onClose={onClose} detailColour={props.detailColour} />

      {statusMessage &&
        <div >
          {_statusSection(statusMessage.value)}
        </div>
      }


      <MessageModal title={tr("Special offers are restricted")}
        show={showMessageModal} onHide={() => {
          setShowMessageModal(false)
         }}

        callback={() => {
          setShowMessageModal(false)
          window.open("https://fbh.xoonoo.com/1YMJ", "_blank")
        }}
      >
        <div className="d-flex justify-content-center" style={{
          fontSize: 48
        }}>
          <i className="text-success icofont-star"></i>
        </div>

        <div className="d-flex justify-content-center m-4 "
          style={{
            fontSize: 40,
            fontWeight: 'bold'
          }}
        >{tr("Offers")}</div>

        <div className='d-flex text-center justify-content-center'>
          {tr("This product can only be ordered via the XooNoo mobile app")}
        </div>

        <div className='d-flex text-center justify-content-center'>
          {tr("Download now")}!!
        </div>

        <div className="row mt-4 show-only-small-devices">
          <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
            <div className="col-6" >
              <div className="mt-1 centered">
                <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
              </div>

            </div>
          </a>
        </div>


      </MessageModal>


    </div>
  )
}

/*
      <ProductComponents product={product}
        detailColour={props.detailColour}
        darkMode={props.darkMode}
        ordersAvailable={props.ordersAvailable}
        onSelected={componentSelected}
      />

      <ProductOptions product={product}
        detailColour={props.detailColour}
        darkMode={props.darkMode}
        ordersAvailable={props.ordersAvailable}
        onChange={optionSelected} />

      <ProductOrderButton
        product={product}
        multiple={true}
        detailColour={props.detailColour}
        darkMode={props.darkMode}
        price={displayPrice}
        ordersAvailable={props.ordersAvailable}
        onClose={props.onClose} />



function ProductOrderButton(props) {
  const { product, price, onClose, detailColour } = props

  const [comments, setComments] = useState(null)

  const { addItemToCart  } = useContext(CartContext);

  const [amount, setAmount] = useState(1)
  const [value, setValue] = useState(1)

  const addToCart = () => {
    // if ordering is not available show an alert
    // if (props.ordersAvailable !== true) {
    //   alert("No podemos recibir su pedido en linea en este momento. Por favor comuníquese con nuestra meseras.")
    //   return
    // }

    let newItem = Object.assign({}, product);
    newItem.quantity = value
    newItem.comments = comments
    newItem.uuid = uuidv4()
    let args = { id: newItem.id, quantity: 1 }  // not used
    addItemToCart(newItem, args);
    onClose()
  }
  const IncrementItem = () => {
    setValue(value + 1);
  }

  const DecreaseItem = () => {
    //setQuantity(quantity - 1 );
    if (value > 1) {
      setValue(value - 1);
    }

    // if(quantity <= (min +1)) {
    //  }else {
    //      setState({ quantity: quantity - 1 });
    //    setValue({id:id,quantity: (quantity - 1 )});
    //  }
  }

  const decrement = () => {
    if (amount > 1)
      setAmount(amount - 1)
  }

  const increment = () => {
    setAmount(amount + 1)
  }

  const minusButton = () => {
    if (amount > 1) {
      return <div className="col-1 enabled-amount"
        style={{
          color: props.detailColour
        }}
        onClick={decrement}>-</div>
    }
    return <div className="col-1 disabled-amount" >-</div>
  }

  //console.log("order button with", props)

  return (
    <div>
      <div className="product-info">

        <div className="">
          <h6>
            Comentarios
          </h6>
          <textarea name="comments" id="order_comments"
            placeholder='¿Sin salsas?¿Sin cebolla?...:'
            style={{
              width: '90%'
            }}
            onChange={(e) => {
              setComments(e.target.value)
            }}
          >
          </textarea>
        </div>
      </div>

      <div className="product-total" style={{ margin: "10px" }}>
        <span className="count-number float-right" style={{ marginRight: "5px" }}>
          <Button variant="outline-secondary" onClick={DecreaseItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
          <input className="count-number-input" type="text" value={value} readOnly />
          <Button variant="outline-secondary" onClick={IncrementItem} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
        </span>
        <span className="plus-multiple"
          style={{
          }}
        >Total: <b>${value * price}</b></span>
      </div>


      <div className=" product-total">
        <div className="add-item-button"
          onClick={addToCart}
          style={{
            backgroundColor: detailColour,
          }}
        >
          <span style={{
            color: 'black'
          }}><i className='fa fa-shopping-cart'></i>
          </span>
          <span>Agregar al carito</span>
        </div>
      </div>

    </div>
  )
}


function ProductInfo(props) {
  const { product } = props

  //console.log("product", product)
  return (
    <div className="product-detail"  >

      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-12 description">
          {product.description}
        </div>
      </div>


    </div>
  )

}

*/