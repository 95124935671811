import React, { useContext, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel3';

import { Image, Badge } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
//import ProductBox from './ProductBox';
//import CategoriesCarousel from '../common/CategoriesCarousel';

import useTranslations from "../../lib/useTranslations";

/* eslint-disable  no-unused-vars */
import {  BusinessContext } from '../../lib/BusinessContext';
import { getFromApi } from '../../lib/api';
import { isEmpty } from 'lodash';

const TopSearch = (props) => {
	const { businesses, getCurrentUserLocation, getOnlineProducts, selectedProvince, selectedCity, showProducts } = useContext(BusinessContext);
	// const [offers, setOffers] = useState([]);
	const history = useHistory();

	const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

	useEffect(() => {
		// if (selectedProvince) {
		// 	getFromApi(`/api/v1/campaign/province/${selectedProvince}/Colombia`, {}, (data) => {
		// 		setOffers(data.result)
		// 	}, (error) => {
		// 		console.log('get offers failed', error)
		// 	})
		// }
		// if (selectedProvince && selectedCity) {
    //   /* eslint-disable react-hooks/exhaustive-deps */
		// 	getOnlineProducts()
		// }
	}, [businesses, selectedProvince, selectedCity])

	//console.log("top search context", selectedCity, showProducts)
	return (
		<section className="pt-5 pb-2 homepage-search-block position-relative show-only-large-devices"    >
			<div className="banner-overlay"></div>
			<Container>
				<Row className="d-flex align-items-center">
					<Col md={8}>
						<div className="homepage-search-title">
							<h1 className="mb-2 font-weight-normal"><span className="font-weight-bold">La App donde todos ganamos</span></h1>
							<h5 className="mb-5 text-secondary font-weight-normal">Donde los mejores clientes, negocios y conductores se encuentran</h5>
						</div>
					{/* 	<div className="homepage-search-form">
							<Form className="form-noborder">
								<div className="form-row">
									<Form.Group className='col-lg-3 col-md-3 col-sm-12'>
										<div className="location-dropdown">
											<Icofont icon='location-arrow' />
											<Select2 className="custom-select"
												data={[
													{ text: 'Breakfast', id: 1 },
													{ text: 'Lunch', id: 2 },
													{ text: 'Dinner', id: 3 },
													{ text: 'Cafés', id: 4 },
													{ text: 'Delivery', id: 5 }
												]}
												options={{
													placeholder: tr('Quick Searches'),
												}}
											/>
										</div>
									</Form.Group>
									<Form.Group className='col-lg-7 col-md-7 col-sm-12'>
										<Form.Control type="text" placeholder="Enter your delivery location" size='lg' />
										<span onClick={() => getCurrentUserLocation()}><Link className="locate-me" to="/" ><Icofont icon='ui-pointer' /> {tr('Locate Me')}</Link> </span>
									</Form.Group>
									<Form.Group className='col-lg-2 col-md-2 col-sm-12'>
										<Link to="listing" className="btn btn-primary btn-block btn-lg btn-gradient">{tr('Search')}</Link>
									</Form.Group>
								</div>
							</Form>
						</div> */}
						{/* <h6 className="mt-4 text-shadow font-weight-normal">{tr('E.g. Beverages, Pizzas, Chinese, Bakery, Indian')}...</h6> */}
						{/* <CategoriesCarousel /> */}
					</Col>
					<Col md={4}>
						<div className="osahan-slider pl-4 pt-3">
							{/* {!isEmpty(offers) &&
								<OwlCarousel nav loop {...options2} className="homepage-ad owl-theme">
									{offers.map((offer, idx) => (
										<div className="item" key={'offer_' + idx} >
											<ProductBox
												image={offer.campaign_foto}
												imageClass='img-fluid rounded'
												imageAlt='carousel'
												linkUrl='listing'
												imgStyle={{ height: 326, width: 326 }}
											/>
											<p style={{ textAlign: 'center' }}>{offer.campaign_name}</p>
										</div>
									))}
								</OwlCarousel>
							} */}
							{!isEmpty(showProducts) &&
								// <OwlCarousel nav loop {...options2} className="homepage-ad owl-theme">

								// 	{showProducts.map((product, idx) => (
								// 		<div className="item" key={'offer_' + idx}
								// 		>{console.log(product)}
								// 			<ProductBox
								// 				image={product.photo}
								// 				imageClass='img-fluid rounded'
								// 				imageAlt='carousel'
								// 				linkUrl='detail'
								// 				imgStyle={{ height: 326, width: 326 }}
								// 			/>
								// 			<p style={{ textAlign: 'center' }}>{product.name}</p>
								// 		</div>
								// 	))}
								// </OwlCarousel>
								<OwlCarousel nav loop {...options2} className="homepage-ad owl-theme">
									{showProducts.map((product, idx) => (
										<button /* className="item col-12 centered" */ key={idx}
											onClick={() => {
												//setMerchant(business)

												//console.log("selected business", business)

												getFromApi(`/api/v1/menu/location/${product.location_id}`, {}, (data) => {
													let menu = data.result[0];

													if (menu) {
                            console.log("menu data", menu)
                            const location = {
                              pathname: '/detail',
                              state: {selectedMenu: menu}
                            }
                          
                            history.push(location)
													} else {
														alert(tr("That business does not have an online menu or catalogue"))
													}
												}, (error) => {
													console.log('get business data failed', error)
												})
											}
											}
											style={{ backgroundColor: "transparent", border: "none" }}
										>
											<CarouselItem
												classContainer={''}
												title={product.name}
												//subTitle={business.description}
												imageAlt='Product'
												image={product.photo}
												imageClass=''
												linkUrl='detail'
											//offerText=''
											//time='20–25 min'
											//price='$250 FOR TWO'
											//showPromoted={true}
											//promotedVariant='dark'
											//favIcoIconColor='text-danger'
											// rating='3.1 (300+)'
											/>
										</button>

									))}
								</OwlCarousel>
							}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

const options2 = {
	responsive: {
		0: {
			items: 2,
		},
		764: {
			items: 2,
		},
		765: {
			items: 1,
		},
		1200: {
			items: 1,
		},
	},
	lazyLoad: true,
	loop: true,
	autoplay: true,
	autoplaySpeed: 1000,
	dots: false,
	autoplayTimeout: 2000,
	nav: true,
	navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
	autoplayHoverPause: true,
}

export default TopSearch;

function CarouselItem(props) {

  const { tr } = useTranslations();

  return (
    <div className={`row list-card bg-white h-100 rounded centered overflow-hidden position-relative shadow-sm  ${props.classContainer}`}
    >
      <div className="list-card-image centered list-card-home col-12" >

        { /*  link image */}
        <Image src={props.image} className={props.imageClass} alt={props.imageAlt} />
       
      </div>
      <div className="p-3 position-relative col-12">
        <div className="list-card-body">
          <h6 className="mb-1">{props.title}
          </h6>
          {props.subTitle ? (
            <p className="text-gray mb-3">{props.subTitle}</p>
          )
            : ''
          }
         
        </div>
        {props.offerText ? (
          <div className="list-card-badge">
            <Badge variant={props.offerColor}>OFFER</Badge> <small>{props.offerText}</small>
          </div>
        )
          : ""
        }
      </div>
    </div>
  );
}