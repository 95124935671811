import React, { useState, createContext } from "react";
import { putToApi, postToApi, getFromApi, signedUrl } from "./api"; 

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {

  const [user, setConfigUser] = useState({})
  const [config, setConfig] = useState([])
  const [defaults, setDefaults] = useState([])

  const getConfig = (locationId) => {
    // get the config for the location
   
    const url = signedUrl(`/api/v1/config/location/${locationId}`)

    // get the config from the API
    getFromApi(
      url,
      {},
      (response) => {
        let res = response.result;       
        //console.log("config result", res);

        setConfig(res);

      },
      (error) => {
        console.log(error);
      }
    );

    getFromApi("/api/v1/config/default_config", {},
      (response) => {
        let result = response.result
        setDefaults(result)

        //console.log("default config", result)
      }, (error) => {
        console.log(error);
      })
  }

  const defaultConfigValue = (key, model, defValue) => {
    if (defaults) {
      let cfg = defaults.find(c => c.model === model && c.name === key)
      if (cfg) {
        //console.log("return config value", key, cfg.value, defValue);
        return cfg.value
      }
    }
    // try up the config tree [product|service|campaign] -> menu -> location -> business
    if (["product", "service", "campaign"].includes(model)) {
      return defaultConfigValue(key, "menu", defValue)
    } else if (model === "menu") {
      return defaultConfigValue(key, "location", defValue)
    } else if (model === "location") {
      return defaultConfigValue(key, "business", defValue)
    }
    //console.log("return default value", key, defValue);

    return defValue;
  }

  const modelConfigValue = (key, model, defValue) => {
    if (config) {
      let cfg = config.find(c => c.model === model && c.name === key)
      if (cfg) {
        //console.log("return config value", key, cfg.value, defValue);
        return cfg.value
      }
    }
    // try up the config tree [product|service|campaign] -> menu -> location -> business
    if (["product", "service", "campaign"].includes(model)) {
      return modelConfigValue(key, "menu", defValue)
    } else if (model === "menu") {
      return modelConfigValue(key, "location", defValue)
    } else if (model === "location") {
      return modelConfigValue(key, "business", defValue)
    }
    //console.log("return default value", key, defValue);

    return defValue;
  }

  const configValue = (key, defValue) => {
    return modelConfigValue(key, 'location', defValue)
  }

  const updateConfigValue = (business, location, value, name, kind, description) => {
    // if the value is empty ignore it
    if (!value || value === "") {
      return
    }

    let old = config.find(c => c.name === name);

    // if the config value does not exist create it via the API
    if (!old) {
      const url = `/api/v1/ui/config`;
      let map = {
        name: name,
        value: value,
        model: "location",
        userId: user.id,
        businessId: business.id,
        locationId: location.id,
        kind: kind,
        description: description
      }

      postToApi(
        url,
        user,
        map,
        (response) => {
          console.log("create config response", response);
          let cfg = response.result;

          setConfig(config.concat(cfg));
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      // if the config value has changed then update it via the API
      if (old.value !== value) {
        const url = `/api/v1/ui/config/${old.id}`;

        old.value = value

        putToApi(
          url,
          user,
          old,
          (response) => {
            console.log("config updated", response);
            setConfig(config.slice());
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }


  }

  const intConfigValue = (key, model, defValue) => {
    if (config) {
      let cfg = config.find(c => c.model === model && c.name === key)
      if (cfg) {
        //console.log("return config value", key, cfg.value, defValue);
        if (cfg.value && typeof cfg.value === 'number')
          return cfg.value

        if (cfg.value && typeof cfg.value === 'string')
          return parseInt(cfg.value)
      }
    }
    // try up the config tree [product|service|campaign] -> menu -> location -> business
    if (["product", "service", "campaign"].includes(model)) {
      return intConfigValue(key, "menu", defValue)
    } else if (model === "menu") {
      return intConfigValue(key, "location", defValue)
    } else if (model === "location") {
      return intConfigValue(key, "business", defValue)
    }
    //console.log("return default value", key, defValue);

    let val = defaultConfigValue(key, model, defValue)

    if (val && typeof val === 'number')
      return val

    if (val && typeof val === 'string')
      return parseInt(val);

    return defValue
  }

  return (
    <ConfigContext.Provider
      value={{
        config,
        defaults,
        getConfig,
        configValue,
        modelConfigValue,
        defaultConfigValue,
        intConfigValue,
        updateConfigValue,
        setConfigUser
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
