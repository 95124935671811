import React, { createContext, useState, useEffect } from 'react';
import { getFromApi, postToApi } from '../lib/api';
import LatLon from './latlon';
import { isEmpty } from 'lodash';
import { removeAccents } from '../lib/utils'

import jquery from 'jquery'

const depts = ['Arauca', 'Antioquia','Boyaca', 'Huila', 'Quindio', 'Risaralda',  'Santander', 'Tolima']

export const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {

  const [departments, setDepartments] = useState(depts);
  const [cities, setCities] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [country, setCountry] = useState('Colombia');
  const [distinctList, setDistinctList] = useState(null);
  const [favourites, setFavourites] = useState([]);
  const [showProducts, setShowProducts] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null)
  const [selectedBusiness, setSelectedBusiness] = useState(null)
  const [selectedMenu, setSelectedMenu] = useState(null)

  const subscriptionLevel = (biz) => {
    if (!biz.Subscriptions || biz.Subscriptions.length === 0) {
      return 0
    }
    let now = new Date()

    // console.log("check expires", now, biz.Subscriptions)
    let sub = biz.Subscriptions.find(x => x.product.startsWith('Ultra'))
    if (sub !== undefined) {
      // check it is not expired
      let exp = Date.parse(sub.expires)
      if (now.getTime() < exp)
        return 30
    }

    sub = biz.Subscriptions.find(x => x.product.startsWith('Elite'))
    if (sub !== undefined) {
      // check it is not expired
      let exp = Date.parse(sub.expires)
      if (now.getTime() < exp)
        return 20
    }

    sub = biz.Subscriptions.find(x => x.product.startsWith('Pro'))
    if (sub !== undefined) {
      // check it is not expired
      let exp = Date.parse(sub.expires)
      if (now.getTime() < exp)
        return 10
    }

    sub = biz.Subscriptions.find(x => x.product.startsWith('Boost'))
    if (sub !== undefined) {
      // check it is not expired
      let exp = Date.parse(sub.expires)
      if (now.getTime() < exp)
        return 5
    }

    return 0
  }

  const orderBalance = (business) => {
    if (business.Balances && business.Balances.length > 0) {
      let balance = business.Balances[0]
      return balance.order_balance > 0 ? 1 : 0
    }
    return 0
  }

  function getDistance(here, biz) {
    if (!biz.latlng) {
      return ''
    }

    let latlon2 = LatLon.fromString(biz.latlng)
    let meters = Math.round(here.distanceTo(latlon2))

    return meters  // > 1000 ? (meters / 1000).toFixed(1) + ' km' : meters + ' m'
  }

  const provinceFromResponse = (response) => {

    let dept = response.principalSubdivision || ""

    //console.log("dept", dept)

    // remove departamento de and department
    dept = dept.replace('Departamento de', '')

    //console.log("dept", dept)
    dept = dept.replace('Department')

    //console.log("dept", dept)

    return dept.trim()
  }


 const getCurrentUserLocation = (departments, distinctList) => {
    // Get location of user

    //let testLat = 5.211932    //  honda Tolima
    //let testLon = -74.735165

    const success = position => {
      var url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`;

      // TEST different location - La Dorada Caldas - returns Ibague businesses
      //url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${testLat}&longitude=${testLon}&localityLanguage=en`;
      jquery.get(url)
        .then(response => {
          //console.log("reverse lookup", response)

          // ignore if the province has been chosen by the user
          if (selectedProvince) return;

          let province = removeAccents(provinceFromResponse(response))

          // if the province and city are in the database then use the data

          if (departments && departments.includes(province) === false) {
            console.log("unknown province", province, departments)
            return
          }

          console.log("set province", province, )

          setSelectedProvince(province);

          let cityList = distinctList.filter(x => x.province === province).map((x) => x.city);
          //console.log("getDistinctLocations set cities", department, cityList)
          setCities(cityList);

        })
    };
    const error = () => {
      console.log("Unable to retrieve your location");
    };
    navigator.geolocation.getCurrentPosition(success, error);
  }


  /* 
    const _useLastLocation = () => {
  
      let locationData = localStorage.getItem('locationData')
      if (locationData) {
        let locObj = JSON.parse(locationData);
  
        console.log("using last location", locObj)
  
        setCurrentLocation(locObj)
  
        //console.log("useEffect locData", businessParams)
        setSelectedProvince(locObj.province || locObj.department)
        setSelectedCity(locObj.city)
        getDistinctLocations(locObj.province || locObj.department);
      
        getBusinessesForCity(locObj);
      }
    }
   */
  useEffect(() => {

    // _useLastLocation()
    let favData = localStorage.getItem('favouriteBusinesses')
    if (favData) {
      let favBusinesses = JSON.parse(favData);
      setFavourites(favBusinesses)
    }

    let province = localStorage.getItem('selectedProvince')
    //console.log("useEffect", province)

    if (province) {
      setSelectedProvince(province);

      getDistinctLocations(province)
    } else {
       // determine the user's province
      getDistinctLocations(null, getCurrentUserLocation )
    }

  }, []);

  const cityMenus = (business, menus) => {
    // get the location ids for the current city
    let city = selectedCity || currentLocation
    let locIds = business.locations.filter((l) => l.city === city).map((l) => l.id)

    //console.log("locations IDs", business.id, business.name, city, locIds)

    // find menus with matching location IDs
    return menus.filter((m) => locIds.indexOf(m.location_id) >= 0)
  }

  const bestDeliveryMenu = (business, menus) => {

    let matching = cityMenus(business, menus)

    //console.log("matching menus", business.id, business.name, matching, menus)

    // if more than 1 menu matches find the closest based on Geo position
    if (matching.length > 1 && currentLocation) {
      let here = new LatLon(currentLocation.latitude, currentLocation.longitude)
      // determine the distance to the menu from the current GEO position
      for (let i = 0; i < matching.length; i++) {
        let menu = matching[i]
        let latlon2 = LatLon.fromString(menu.Location.latlng)
        menu.distance = Math.round(here.distanceTo(latlon2))
      }
      matching.sort((a, b) => { return a.distance - b.distance })
    }

    // use the first matching menu
    return matching[0] || menus[0]
  }

  const checkMenuPeriods = (menu) => {
    let periods = menu.Periods

    let dt = new Date()
    let daysecs = (dt.getHours() * 3600) + (dt.getMinutes() * 60)

    //console.log('my time in seconds', daysecs)

    // check the periods, null or empty periods means menu is available
    // period day flags are monday(0) to Sunday(6)
    // javascript days are Sunday(0) to Saturday(6)
    let daynum = dt.getDay() - 1
    if (daynum < 0) daynum = 6

    //console.log("periods", business.name, periods)

    if (periods != null && periods.length > 0) {

      const openPeriod = periods.find((p) => {
        let days = JSON.parse(p.days)

        //console.log("period", p)
        //console.log("period daysecs:", daysecs, "day:", days[daynum], "open:", p.open, "close:", p.close, "available:", (daysecs > p.open && daysecs < p.close))

        if (days[daynum] && (daysecs >= p.open && daysecs <= p.close)) {//console.log("days[daynum] && (daysecs > p.open && daysecs < p.close)"); 
          return true
        }
        else if (days[daynum] && p.open > p.close && ((daysecs >= p.open) || (daysecs <= p.close))) {//console.log("days[daynum] && p.open > p.close && ((daysecs > p.open) || (daysecs < p.close))");
          return true
        }
        else
          return false
      })

      //console.log("resolved open period", openPeriod)
      if (openPeriod) {
        // venue is open for business
        return { isOpen: true, open: openPeriod.open, close: openPeriod.close, closeIn: (openPeriod.close - daysecs), daysecs: daysecs }
      }

      // see if there is a period that opens later today
      const laterPeriod = periods.find((p) => {
        let days = JSON.parse(p.days)

        //console.log("period", p)
        //console.log("period daysecs:", daysecs, "day:", days[daynum], "open:", p.open, "close:", p.close, "available:", (daysecs > p.open && daysecs < p.close))

        if (days[daynum] && daysecs < p.open)
          return true
        else
          return false
      })

      //console.log("resolved later period", laterPeriod)
      if (laterPeriod) {
        // venue will open for business later today
        return { isOpen: false, open: laterPeriod.open, close: laterPeriod.close, openIn: (laterPeriod.open - daysecs), daysecs: daysecs }
      }
    }
    return { isOpen: false, open: null, close: null }
  }

  const getDistinctLocations = (department, callback) => {
   
    getFromApi('/api/v1/location/distinct_list/Colombia', {}, (data) => {
      //console.log("getDistinctLocations", department, data)
      setDistinctList(data.result)

      let departments = [];
      data.result.forEach(element => {
        element.province = element.province.charAt(0).toUpperCase() + element.province.slice(1);;
        if (departments.find(x => x.toLowerCase().replace(/\s/g, '') === element.province.toLowerCase().replace(/\s/g, '')) === undefined) departments.push(element.province);
      });
      
      departments.sort()
      //console.log("getDistinctLocations set departments", department, departments)
      setDepartments(departments)

      if (callback) {
        callback(departments, data.result)
      }

      // get the cities for the current department
      if (department) {
        let cityList = data.result.filter(x => x.province === department).map((x) => x.city);
        //console.log("getDistinctLocations set cities", department, cityList)
        setCities(cityList);
      } else if (selectedProvince) {
        let cityList = data.result.filter(x => x.province === selectedProvince).map((x) => x.city);
        //console.log("getDistinctLocations set cities", department, cityList)
        setCities(cityList);
      }

    }, (error) => {
      console.log('getDistinctLocations failed', error)
    })
  }

  const provinceSelected = (province) => {
    setSelectedProvince(province);

    localStorage.setItem('selectedProvince', province)

    if (distinctList) {
      // extract the city from the distinct data
      let citylist = distinctList.filter(x => x.province === province).map((obj) => { return obj.city });
      setCities(citylist);
    }

    //setSelectedCity(citylist[0]);

    //  let firstCity = citylist[0]

    //  getBusinessesForCity({
    //    city: firstCity,
    //    province: departmentName,
    //    department: departmentName,
    //    country: country,
    //    latitude: firstCity.latitude,
    //    longitude: firstCity.longitude,
    //  })

    // let businessList = [];
    // getFromApi(`/api/v1/business/city/${citylist[0]}/${departmentName}/Colombia`, {}, (data) => {
    //   //console.log("getCitiesApi result", data)
    //   data.result.forEach(element => {
    //     if (businessList.find(x => x.name.toLowerCase().replace(/\s/g, '') === element.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
    //   });

    //   //console.log("getCitiesApi businesses", businessList)
    //   setBusinesses(businessList)
    // }, (error) => {
    //   console.log('failed', error)
    // })
  }

  const _setupBusinessData = (biz, location) => {
    let here = new LatLon(location.latitude, location.longitude);
    biz.subscriptionLevel = subscriptionLevel(biz)
    biz.distance = getDistance(here, biz);
    biz.orderBalance = orderBalance(biz)

    // check to see if it is open
    if (biz.menus) {  // this will always be true
      let menu = bestDeliveryMenu(biz, biz.menus)
      let res = checkMenuPeriods(menu)

      if (res.isOpen) {
        biz.isOpen = true
        //console.log("open", biz.name)
      } else {
        biz.isOpen = false
        //console.log("closed", biz.name)
      }
    }
  }
  /* 
    const getLocalBusinesses = (location, callback) => {
      let businessList = [];
  
      let url = `/api/v1/menu/city/${location.city}/${location.department}/${location.country}`
  
      //console.log("getLocalBusinesses from", url, location)
  
      getFromApi(url, {}, (data) => {
        //console.log("getLocalBusinesses result", data)
  
        if (!data.result) {
          console.log("no data from", url, location)
          return
        }
        data.result.forEach(element => {
          if (businessList.find(x => x.name.toLowerCase().replace(/\s/g, '') === element.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
        });
  
  
        mapBusinessMenus(businessList, data.menus)
  
        // determine the subscription level and distance of each restaurant
        businessList.forEach(biz => {
          _setupBusinessData(biz, location)
  
        })
  
        // sort the restaurants by subscription level then order balance then distance from the current location
  
        // seperate into online and offline lists
        let online = businessList.filter((b) => b.isOpen)
        let offline = businessList.filter((b) => !b.isOpen)
  
        // sort the restaurants by subscription level then order balance
        online.sort((a, b) => {
          return a.rank - b.rank
        })
        offline.sort((a, b) => {
          return a.rank - b.rank
        })
  
        businessList = [...online, ...offline]
        //console.log("getLocalBusinesses businesses", businessList)
        setBusinesses(businessList)
  
        callback(businessList)
      }, (error) => {
        console.log('failed', error)
      })
    }
   */
  const businessHasOrdering = (business) => {

    let menu = bestDeliveryMenu(business, business.menus)
    let res = checkMenuPeriods(menu)

    // let hasOrdering = false
    // if (business.subscriptionLevel > 5) {
    //   hasOrdering = true
    // } else {
    //   // check ordering balance
    //   if (business.Balances && business.Balances.length > 0) {
    //     let balance = business.Balances[0]
    //     hasOrdering = balance.order_balance > 0
    //   }
    // }
    return res.isOpen
  }

  const getMultipleRandom = (products, bizIds, num) => {
    // pluck a product for each online biz
    let shuffled = [...products].sort(() => 0.5 - Math.random());

    let result = []
    bizIds.forEach((id, index) => {
      let p = shuffled.find((p) => p.business_id === id)
      if (p) {
        result.push(p)
      }
    })
    if (result.length == 0) {
      return shuffled.slice(0, num);
    }

    if (result.length < num && shuffled.length > (num - result.length)) {

      while (result.length < num && shuffled.length > 0) { 
        // get another product not already in the list
        result.push(shuffled.pop())
      }
    }

    return result.slice(0, num);
  }

  const getOnlineProducts = () => {
    // if we have a slected or discovered location
    // get the products for online businesses

    console.log("get online products")

    getFromApi(`/api/v1/product/featured/${selectedCity}/${selectedProvince}/Colombia`,
      {},
      (data) => {
        let products = [];

        // remove products for businesses that are offline
        //get online businesses
        let onlineBizIds = businesses.filter((b) => businessHasOrdering(b)).map(b => b.id)
        //console.log("online biz", onlineBizIds)

        let onlineProducts = data.result.filter((p) => {
          return onlineBizIds.includes(p.business_id)
        })

        //console.log("online products", onlineProducts)

        if (onlineProducts.length > 14) {
          products = getMultipleRandom(onlineProducts, onlineBizIds, 14)
        } else {
          products = onlineProducts
        }

        //console.log("show products ", products)
        setShowProducts(products)
      }, (error) => {
        console.log('getOnlineProducts failed', error)
      })
  }

  /*
  const downloadBusinessesForCity = (location, callback) => {
    let businessList = [];

    let url = `/api/v1/menu/city/${location.city}/${location.province || location.department}/${location.country}`
    //console.log("downloadBusinessesForCity from", url)

    getFromApi(url, {}, (data) => {
      //console.log("downloadBusinessesForCity", data)
      data.result.forEach(element => {
        if (businessList.find(x => x.name.toLowerCase().replace(/\s/g, '') === element.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
      });

      mapBusinessMenus(businessList, data.menus)

      // determine the subscription level of each business
      businessList.forEach(biz => {
        _setupBusinessData(biz, location)
      })

      // sort the restaurants by subscription level then order balance

      // seperate into online and offline lists
      let online = businessList.filter((b) => b.isOpen)
      let offline = businessList.filter((b) => !b.isOpen)

      // sort the restaurants by subscription level then order balance
      online.sort((a, b) => {
        return a.rank - b.rank
      })
      offline.sort((a, b) => {
        return a.rank - b.rank
      })

      businessList = [...online, ...offline]

      //console.log("downloadBusinessesForCity businesses", businessList)
      callback(businessList)
    }, (error) => {
      console.log('failed', error)
    })
  }

  const _getBusinessForFirstItem = (cities) => {
    // get the businesses for the first city in the list, shifting the array in the process
    let city = cities.shift()

    console.log("_getBusinessForFirstItem city", city, cities)

    // if there are none - recurse
    downloadBusinessesForCity(city, (list) => {

      if (listHasOnlineBusinesses(list) !== true) {
        _getBusinessForFirstItem(cities)
      } else {
        setBusinesses(list)
        setSelectedProvince(city.province)
        setSelectedCity(city.city)
      }
    })
  }
 
  const findClosestOnlineCity = (location) => {
    //console.log("find closest online city for", location)

    // sort the list of distinct locations by proximity
    let here = LatLon(location.latitude, location.longitude)

    if (distinctList) {
      // cycle through distinct locations creating a LatLon
      let cities = distinctList.map((l) => {
        // create a LatLon object from the string
        l.geoloc = LatLon.fromString(l.Latlng)
        l.distance = here.distanceTo(l.geoloc)

        return l
      }).sort((a, b) => {
        return a.distance - b.distance
      })

      _getBusinessForFirstItem(cities)
    } else {  // distinct list is null so first get the distinct list 
      getFromApi('/api/v1/location/distinct_list/' + location.country, {}, (data) => {
        console.log("findClosestOnlineCity", location.city, location.province, "distinctlist", data)

        let cities = data.result.map((l) => {
          // create a LatLon object from the string
          l.geoloc = LatLon.fromString(l.latlng)
          l.distance = here.distanceTo(l.geoloc)
          l.country = location.country

          return l
        }).sort((a, b) => {
          return a.distance - b.distance
        })

        _getBusinessForFirstItem(cities)

      }, (error) => {
        console.log('findClosestOnlineCity failed', error)
      })
    }
  }
 
  const listHasOnlineBusinesses = (list) => {
    const online = list.filter((b) => businessHasOrdering(b))

    return online.length > 0
  }

  const ensureOnlineBusinesses = (location, list) => {
    // filter the list by onlne businesses
    if (list.length === 0) {
      findClosestOnlineCity(location)
    }

    // if the list is empty find the closest city with an online menu
    if (listHasOnlineBusinesses(list) !== true) {
      findClosestOnlineCity(location)
    }
  }
 */
  /* 
    const getBusinessMenusForCity = (location) => {
      let businessList = [];
  
      let url = `/api/v1/menu/city/${location.city}/${location.province || location.department}/${location.country}`
      console.log("getBusinessMenusForCity from", url)
  
      getFromApi(url, {}, (data) => {
        //console.log("getBusinessesMenusForCity result", data)
        data.result.forEach(element => {
          if (businessList.find(x => x.name.toLowerCase().replace(/\s/g, '') === element.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
        });
  
        // determine the subscription level of each business
        businessList.forEach(biz => {
          biz.subscriptionLevel = subscriptionLevel(biz)
          biz.orderBalance = orderBalance(biz)
        })
  
        // sort the restaurants by subscription level then order balance
        businessList.sort((a, b) => {
          if (a.subscriptionLevel === b.subscriptionLevel) {
            return b.orderBalance - a.orderBalance;
          }
          return b.subscriptionLevel - a.subscriptionLevel;
        })
  
        mapBusinessMenus(businessList, data.menus)
  
        //console.log("getBusinessMenusForCity businesses", businessList)
        setBusinesses(businessList)
        ensureOnlineBusinesses(location, businessList)
      }, (error) => {
        console.log('failed', error)
      })
    }
   */
  const mapBusinessMenus = (businesses, menus) => {
    // 
    businesses.forEach((b) => {
      let bizmenus = menus.filter((m) => m.business_id === b.id)
      console.log("menus for business", b, bizmenus)
      b.menus = bizmenus

      if (bizmenus.length > 0) {
        console.log("use rank", b.name, bizmenus[0].Location.rank)
        b.rank = bizmenus[0].Location.rank
      }
    })
  }

  const getBusinessesForCity = (location) => {
    //console.log("getBusinessesForCity", location)
    // if we are given a location, use it to get the businesses for the city
    // and calculate the distance from the current location
    /*  if (location.latitude && location.longitude) {
       getLocalBusinesses(location, (list) => {
         ensureOnlineBusinesses(location, list)
       })
       return
     } */

    // otherwise get the businesses for the city without distance
    let businessList = [];

    let url = `/api/v1/menu/city/${location.city}/${location.province || location.department}/${location.country}`
    console.log("getBusinessesForCity from", url)

    getFromApi(url, {}, (data) => {
      //console.log("getBusinessesForCity result", data)
      data.result.forEach(element => {
        if (businessList.find(x => x.name.toLowerCase().replace(/\s/g, '') === element.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
      });


      mapBusinessMenus(businessList, data.menus)

      // determine the subscription level of each business
      businessList.forEach(biz => {
        _setupBusinessData(biz, location)
      })

      // seperate into online and offline lists
      let online = businessList.filter((b) => b.isOpen)
      let offline = businessList.filter((b) => !b.isOpen)

      // sort the restaurants by subscription level then order balance
      online.sort((a, b) => {
        return a.rank - b.rank
      })
      offline.sort((a, b) => {
        return a.rank - b.rank
      })

      businessList = [...online, ...offline]

      //console.log("getBusinessesForCity businesses", businessList)
      setBusinesses(businessList)
      //ensureOnlineBusinesses(location, businessList)
    }, (error) => {
      console.log('failed', error)
    })
  }

  const getCityCampaigns = (categoryName, city, department) => {
    let businessList = [];

    //console.log("getBusinessApi", city, "department", department, categoryName)

    if (city && department && isEmpty(categoryName)) {
      getFromApi(`/api/v1/campaign/city/${city}/${department}/Colombia`, {}, (data) => {
        //console.log("info data", data.result)
        data.result.forEach(element => {
          if (businessList.find(x => x.Business.name.toLowerCase().replace(/\s/g, '') === element.Business.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
        });
        console.log(businessList)
        setBusinesses(businessList)
      }, (error) => {
        console.log('failed', error)
      })
    } else if (department && isEmpty(city) && isEmpty(categoryName)) {
      getFromApi(`/api/v1/campaign/province/${department}/Colombia`, {}, (data) => {
        data.result.forEach(element => {
          if (businessList.find(x => x.Business.name.toLowerCase().replace(/\s/g, '') === element.Business.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
        });
        setBusinesses(businessList)
      }, (error) => {
        console.log('failed', error)
      })
    } else {
      getFromApi(`/api/v1/category/tagged/Colombia/${department}/${city}/${categoryName}`, {}, (data) => {
        data.result.forEach(element => {
          if (businessList.find(x => x.Business.name.toLowerCase().replace(/\s/g, '') === element.Business.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
        });
        setBusinesses(businessList)
      }, () => {
        console.log('failed')
      })
    }

  }

  const addContact = (deliveryData, menuData, ageConfirmed, dateOfBirth) => {

    let map = {
      id: 0,
      business_id: menuData.business_id,
      location_id: menuData.location_id,
      name: deliveryData.name,
      phone: deliveryData.phone,
      email: deliveryData.email,
      city: deliveryData.city,
      province: deliveryData.province,
      country: deliveryData.country,
      is_client: false,
      ageConfirmed: ageConfirmed,
      dob: dateOfBirth
    }

    postToApi("/api/v1/contact/menu", { id: 0 }, map, (result) => {

    }, (error) => {
      console.log("error adding contact", error)
    })
  }


  const clearSavedData = () => {
   
    //localStorage.removeItem("paymentMethod")
    //localStorage.removeItem("deliveryAddress")
 
    localStorage.removeItem("tableNum")
    localStorage.removeItem("locationData")
    localStorage.removeItem("selectedMenu")
    //localStorage.removeItem("paramData")
    setSelectedMenu(null)
    setSelectedBusiness(null)
  }

  return (
    <BusinessContext.Provider
      value={{

        // data
        businesses, setBusinesses,
        selectedMenu,

        reloadMenu: (menu) => {
          if (!menu) return
          setSelectedBusiness(menu.Business)
          setSelectedMenu(menu)
        },
        setSelectedMenu,

        cities,
        favourites,
        selectedCity,
        selectedProvince,
        departments,

        clearSavedData,

        // functions
        addContact,
        bestDeliveryMenu,
        businessHasOrdering,
        cityMenus,
        checkMenuPeriods,

        configValue: (business, name, defaultValue) => {
          if (business.Configs && business.Configs.length > 0) {
            let cfg = business.Configs.find((cfg) => cfg.name === name)
            if (cfg) return cfg.value
          }
          return defaultValue
        },

        locationConfigValue: (configs, id, name, defaultValue) => {
          if (configs && configs.length > 0) {
            let cfg = configs.find((cfg) => cfg.locationId === id && cfg.name === name)
            if (cfg) return cfg.value
          }
          return defaultValue
        },
        locationConfigBool: (configs, id, name, defaultValue) => {
          if (configs && configs.length > 0) {
            let cfg = configs.find((cfg) => cfg.locationId === id && cfg.name === name)
            if (cfg) return cfg.value === "true"
          }
          return defaultValue
        },

        configNumber: (business, name, defaultValue) => {
          if (business.Configs && business.Configs.length > 0) {
            let cfg = business.Configs.find((cfg) => cfg.name === name)
            if (cfg && cfg.value.length > 0) {
              try {
                return parseInt(cfg.value)
              } catch (ex) {
                console.log("could not parse int value", cfg.value)
              }
            }
          }
          return defaultValue
        },

        locationConfigNumber: (configs, id, name, defaultValue) => {
          if (configs && configs.length > 0) {
            let cfg = configs.find((cfg) => cfg.locationId === id && cfg.name === name)
            if (cfg && cfg.value.length > 0) {
              try {
                return parseInt(cfg.value)
              } catch (ex) {
                console.log("could not parse int value", cfg.value)
              }
            }
          }
          return defaultValue
        },

        getDistinctLocations,
        getBusinessesForCity,
        getOnlineProducts,
        provinceSelected,

        currentLocation,
        setCurrentLocation,
        setSelectedProvince,
        setSelectedCity,
        setCountry,

        showProducts,

        setCityName: (name) => {
          console.log("setCityName")
          setSelectedCity(name);
          getBusinessesForCity({
            city: name, department: selectedProvince,
            country: country
          })
        },

        selectedBusiness, setSelectedBusiness,

        setSelectedCategory: (name) => getCityCampaigns(name, selectedCity, selectedProvince),

        subscriptionLevel
      }}
    >
      {children}
    </BusinessContext.Provider>
  )
}

