import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import CouponCard from './common/CouponCard';
//import BankOffers from './common/BankOffers';
import { getFromApi } from '../lib/api';
/* eslint-disable no-unused-vars */
import { BusinessProvider, BusinessContext } from '../lib/BusinessContext';

import useTranslations from "../lib/useTranslations";

const Offers = (props) => {
	const { selectedProvince, selectedCity } = useContext(BusinessContext);
	const [offers, setOffers] = useState([]);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();
  
	useEffect(() => {
		let businessList = [];
		getFromApi(`/api/v1/campaign/city/${selectedCity}/${selectedProvince}/Colombia`, {}, (data) => {
			data.result.forEach(element => {
				if (businessList.find(x => x.Business.name.toLowerCase().replace(/\s/g, '') === element.Business.name.toLowerCase().replace(/\s/g, '')) === undefined) businessList.push(element);
			});
			setOffers(businessList);
		}, () => {
			console.log('failed')
		})
	}, [selectedProvince,selectedCity])

	return (
		<>
			<PageTitle
				title="Offers for you"
				subTitle="Explore top deals and offers exclusively for you!"
			/>
			<section className="section pt-5 pb-5">
				<Container>
					<Row>
						<Col md={12}>
							<h4 className="font-weight-bold mt-0 mb-3">Available Coupons</h4>
						</Col>
						{offers.map(offer => (
							<Col md={4}>
								<CouponCard
									title={offer.campaign_name}
									logoImage={offer.campaign_foto}
									subTitle={offer.discount}
									// copyBtnText='COPY CODE'
									// couponCode='OSAHANEAT50'
								/>
							</Col>
						))}
					</Row>
					{/* <Row className="pt-5">
						<Col xs={12}>
							<h4 className="font-weight-bold mt-0 mb-3">Bank Offers</h4>
						</Col>
						<Col md={6}>
							<BankOffers
								title='Get flat $.30 cashback using Amazon Pay'
								logoImage='img/bank/7.png'
								imageclassName='card-img'
								subTitle='Get flat $.30 cashback on orders above $.99 for 10 orders. No code required.'
							/>
						</Col>
						<Col md={6}>
							<BankOffers
								title='Get flat $.30 cashback using Osahan Pay'
								logoImage='img/bank/8.png'
								imageclassName='card-img'
								subTitle='Get flat $.30 cashback on orders above $.99 for 10 orders. No code required.'
							/>
						</Col>
					</Row> */}
				</Container>
			</section>
		</>
	);
}


export default Offers;