export const categoryData=[
    {name:'Burger',img:'img/list/1.png'},
    {name:'Pizza',img:'img/list/2.png'},
    {name:'Fast Food',img:'img/list/3.png'},
    {name:'Hot Dogs',img:'img/list/4.png'},
    {name:'Wings',img:'img/list/5.png'},
    {name:'Sandwich',img:'img/list/6.png'},
    {name:'Typical',img:'img/list/7.png'},
    {name:'Grill',img:'img/list/8.png'},
    {name:'Sushi',img:'img/list/9.png'},
    {name:'Chicken',img:'img/list/10.png'},
    {name:'Seafood',img:'img/list/11.png'},
    {name:'Arabic',img:'img/list/12.png'},
    {name:'Mexican',img:'img/list/13.png'},
    {name:'Asian',img:'img/list/14.png'},
    {name:'Italian',img:'img/list/15.png'},
    {name:'Peruvian',img:'img/list/16.png'},
    {name:'Chinese',img:'img/list/17.png'},
    {name:'Indian',img:'img/list/18.png'},
    {name:'International',img:'img/list/19.png'},
    {name:'Healthy',img:'img/list/20.png'},
    {name:'Breakfast',img:'img/list/21.png'},
    {name:'Vegetarian',img:'img/list/22.png'},
    {name:'Vegan',img:'img/list/23.png'},
    {name:'Drinks',img:'img/list/24.png'},
    {name:'Liquor',img:'img/list/25.png'},
    {name:'Desserts',img:'img/list/26.png'},
    {name:'Coffee',img:'img/list/27.png'},
    {name:'Crepes',img:'img/list/28.png'},
    {name:'Ice cream',img:'img/list/29.png'},
]