/* eslint-disable no-extend-native */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/*  Latitude/longitude spherical geodesy formulae & scripts           (c) Chris Veness 2002-2015  */
/*   - www.movable-type.co.uk/scripts/latlong.html                                   MIT Licence  */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/* eslint-disable-file prefer-const */


import Dms from './dms'

/**
 * Creates a LatLon point on the earth's surface at the specified latitude / longitude.
 *
 * @classdesc Tools for geodetic calculations
 * @requires Dms from 'dms.js'
 *
 * @constructor
 * @param {number} lat - Latitude in degrees.
 * @param {number} lon - Longitude in degrees.
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119);
 */
function LatLon (lat, lon) {
  // allow instantiation without 'new'
  if (!(this instanceof LatLon)) return new LatLon(lat, lon)

  this.lat = Number(lat)
  this.lon = Number(lon)
}

/**
 * Returns the distance from 'this' point to destination point (using haversine formula).
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @param   {number} [radius=6371e3] - (Mean) radius of earth (defaults to radius in metres).
 * @returns {number} Distance between this point and destination point, in same units as radius.
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119), p2 = new LatLon(48.857, 2.351);
 *     var d = p1.distanceTo(p2); // Number(d.toPrecision(4)): 404300
 */
LatLon.prototype.distanceTo = function (point, radius) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')
  radius = (radius === undefined) ? 6371e3 : Number(radius)

  const R = radius
  const φ1 = this.lat.toRadians(); const λ1 = this.lon.toRadians()
  const φ2 = point.lat.toRadians(); const λ2 = point.lon.toRadians()
  const Δφ = φ2 - φ1
  const Δλ = λ2 - λ1

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c

  return d
}

/**
 * Returns the (initial) bearing from 'this' point to destination point.
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @returns {number} Initial bearing in degrees from north.
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119), p2 = new LatLon(48.857, 2.351);
 *     var b1 = p1.bearingTo(p2); // b1.toFixed(1): 156.2
 */
LatLon.prototype.bearingTo = function (point) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')

  const φ1 = this.lat.toRadians(); const φ2 = point.lat.toRadians()
  const Δλ = (point.lon - this.lon).toRadians()

  // see http://mathforum.org/library/drmath/view/55417.html
  const y = Math.sin(Δλ) * Math.cos(φ2)
  const x = Math.cos(φ1) * Math.sin(φ2) -
            Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ)
  const θ = Math.atan2(y, x)

  return (θ.toDegrees() + 360) % 360
}

/**
 * Returns final bearing arriving at destination destination point from 'this' point; the final bearing
 * will differ from the initial bearing by varying degrees according to distance and latitude.
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @returns {number} Final bearing in degrees from north.
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119), p2 = new LatLon(48.857, 2.351);
 *     var b2 = p1.finalBearingTo(p2); // b2.toFixed(1): 157.9
 */
LatLon.prototype.finalBearingTo = function (point) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')

  // get initial bearing from destination point to this point & reverse it by adding 180°
  return (point.bearingTo(this) + 180) % 360
}

/**
 * Returns the midpoint between 'this' point and the supplied point.
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @returns {LatLon} Midpoint between this point and the supplied point.
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119), p2 = new LatLon(48.857, 2.351);
 *     var pMid = p1.midpointTo(p2); // pMid.toString(): 50.5363°N, 001.2746°E
 */
LatLon.prototype.midpointTo = function (point) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')

  // see http://mathforum.org/library/drmath/view/51822.html for derivation

  const φ1 = this.lat.toRadians(); const λ1 = this.lon.toRadians()
  const φ2 = point.lat.toRadians()
  const Δλ = (point.lon - this.lon).toRadians()

  const Bx = Math.cos(φ2) * Math.cos(Δλ)
  const By = Math.cos(φ2) * Math.sin(Δλ)

  const φ3 = Math.atan2(Math.sin(φ1) + Math.sin(φ2),
    Math.sqrt((Math.cos(φ1) + Bx) * (Math.cos(φ1) + Bx) + By * By))
  const λ3 = λ1 + Math.atan2(By, Math.cos(φ1) + Bx)

  return new LatLon(φ3.toDegrees(), (λ3.toDegrees() + 540) % 360 - 180) // normalise to −180…+180°
}

/**
 * Returns the destination point from 'this' point having travelled the given distance on the
 * given initial bearing (bearing normally varies around path followed).
 *
 * @param   {number} distance - Distance travelled, in same units as earth radius (default: metres).
 * @param   {number} bearing - Initial bearing in degrees from north.
 * @param   {number} [radius=6371e3] - (Mean) radius of earth (defaults to radius in metres).
 * @returns {LatLon} Destination point.
 *
 * @example
 *     var p1 = new LatLon(51.4778, -0.0015);
 *     var p2 = p1.destinationPoint(7794, 300.7); // p2.toString(): 51.5135°N, 000.0983°W
 */
LatLon.prototype.destinationPoint = function (distance, bearing, radius) {
  radius = (radius === undefined) ? 6371e3 : Number(radius)

  // see http://williams.best.vwh.net/avform.htm#LL

  const δ = Number(distance) / radius // angular distance in radians
  const θ = Number(bearing).toRadians()

  const φ1 = this.lat.toRadians()
  const λ1 = this.lon.toRadians()

  const φ2 = Math.asin(Math.sin(φ1) * Math.cos(δ) +
                        Math.cos(φ1) * Math.sin(δ) * Math.cos(θ))
  const λ2 = λ1 + Math.atan2(Math.sin(θ) * Math.sin(δ) * Math.cos(φ1),
    Math.cos(δ) - Math.sin(φ1) * Math.sin(φ2))

  return new LatLon(φ2.toDegrees(), (λ2.toDegrees() + 540) % 360 - 180) // normalise to −180…+180°
}

LatLon.fromString = function (str) {
  // possible variations
  // "[4.12345, -75.12345]"
  // "4.12345, -75.12345"

  if (!str || str.length === 0) return undefined
  if(str.indexOf("[") >= 0) {
    let parts = JSON.parse(str)
    return new LatLon(parseFloat(parts[0]), parseFloat(parts[1]))
  }

  let parts = str.split(",")

  return new LatLon(parseFloat(parts[0]), parseFloat(parts[1]))
}

/**
 * Returns the point of intersection of two paths defined by point and bearing.
 *
 * @param   {LatLon} p1 - First point.
 * @param   {number} brng1 - Initial bearing from first point.
 * @param   {LatLon} p2 - Second point.
 * @param   {number} brng2 - Initial bearing from second point.
 * @returns {LatLon} Destination point (null if no unique intersection defined).
 *
 * @example
 *     var p1 = LatLon(51.8853, 0.2545), brng1 = 108.547;
 *     var p2 = LatLon(49.0034, 2.5735), brng2 =  32.435;
 *     var pInt = LatLon.intersection(p1, brng1, p2, brng2); // pInt.toString(): 50.9078°N, 004.5084°E
 */
LatLon.intersection = function (p1, brng1, p2, brng2) {
  if (!(p1 instanceof LatLon)) throw new TypeError('p1 is not LatLon object')
  if (!(p2 instanceof LatLon)) throw new TypeError('p2 is not LatLon object')

  // see http://williams.best.vwh.net/avform.htm#Intersection

  const φ1 = p1.lat.toRadians(); const λ1 = p1.lon.toRadians()
  const φ2 = p2.lat.toRadians(); const λ2 = p2.lon.toRadians()
  const θ13 = Number(brng1).toRadians(); const θ23 = Number(brng2).toRadians()
  const Δφ = φ2 - φ1; const Δλ = λ2 - λ1

  const δ12 = 2 * Math.asin(Math.sqrt(Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)))
  if (δ12 === 0) return null

  // initial/final bearings between points
  let θ1 = Math.acos((Math.sin(φ2) - Math.sin(φ1) * Math.cos(δ12)) /
                        (Math.sin(δ12) * Math.cos(φ1)))
  if (isNaN(θ1)) θ1 = 0 // protect against rounding
  const θ2 = Math.acos((Math.sin(φ1) - Math.sin(φ2) * Math.cos(δ12)) /
                        (Math.sin(δ12) * Math.cos(φ2)))

  let θ12, θ21
  if (Math.sin(λ2 - λ1) > 0) {
    θ12 = θ1
    θ21 = 2 * Math.PI - θ2
  } else {
    θ12 = 2 * Math.PI - θ1
    θ21 = θ2
  }

  const α1 = (θ13 - θ12 + Math.PI) % (2 * Math.PI) - Math.PI // angle 2-1-3
  const α2 = (θ21 - θ23 + Math.PI) % (2 * Math.PI) - Math.PI // angle 1-2-3

  if (Math.sin(α1) === 0 && Math.sin(α2) === 0) return null // infinite intersections
  if (Math.sin(α1) * Math.sin(α2) < 0) return null // ambiguous intersection

  // α1 = Math.abs(α1);
  // α2 = Math.abs(α2);
  // ... Ed Williams takes abs of α1/α2, but seems to break calculation?

  const α3 = Math.acos(-Math.cos(α1) * Math.cos(α2) +
                         Math.sin(α1) * Math.sin(α2) * Math.cos(δ12))
  const δ13 = Math.atan2(Math.sin(δ12) * Math.sin(α1) * Math.sin(α2),
    Math.cos(α2) + Math.cos(α1) * Math.cos(α3))
  const φ3 = Math.asin(Math.sin(φ1) * Math.cos(δ13) +
                        Math.cos(φ1) * Math.sin(δ13) * Math.cos(θ13))
  const Δλ13 = Math.atan2(Math.sin(θ13) * Math.sin(δ13) * Math.cos(φ1),
    Math.cos(δ13) - Math.sin(φ1) * Math.sin(φ3))
  const λ3 = λ1 + Δλ13

  return new LatLon(φ3.toDegrees(), (λ3.toDegrees() + 540) % 360 - 180) // normalise to −180…+180°
}

/**
 * Returns (signed) distance from ‘this’ point to great circle defined by start-point and end-point.
 *
 * @param   {LatLon} pathStart - Start point of great circle path.
 * @param   {LatLon} pathEnd - End point of great circle path.
 * @param   {number} [radius=6371e3] - (Mean) radius of earth (defaults to radius in metres).
 * @returns {number} Distance to great circle (-ve if to left, +ve if to right of path).
 *
 * @example
 *   var pCurrent = new LatLon(53.2611, -0.7972);
 *   var p1 = new LatLon(53.3206, -1.7297), p2 = new LatLon(53.1887, 0.1334);
 *   var d = pCurrent.crossTrackDistanceTo(p1, p2);  // Number(d.toPrecision(4)): -307.5
 */
LatLon.prototype.crossTrackDistanceTo = function (pathStart, pathEnd, radius) {
  if (!(pathStart instanceof LatLon)) throw new TypeError('pathStart is not LatLon object')
  if (!(pathEnd instanceof LatLon)) throw new TypeError('pathEnd is not LatLon object')
  radius = (radius === undefined) ? 6371e3 : Number(radius)

  const δ13 = pathStart.distanceTo(this, radius) / radius
  const θ13 = pathStart.bearingTo(this).toRadians()
  const θ12 = pathStart.bearingTo(pathEnd).toRadians()

  const dxt = Math.asin(Math.sin(δ13) * Math.sin(θ13 - θ12)) * radius

  return dxt
}

/**
 * Returns maximum latitude reached when travelling on a great circle on given bearing from this
 * point ('Clairaut's formula'). Negate the result for the minimum latitude (in the Southern
 * hemisphere).
 *
 * The maximum latitude is independent of longitude; it will be the same for all points on a given
 * latitude.
 *
 * @param {number} bearing - Initial bearing.
 * @param {number} latitude - Starting latitude.
 */
LatLon.prototype.maxLatitude = function (bearing) {
  const θ = Number(bearing).toRadians()

  const φ = this.lat.toRadians()

  const φMax = Math.acos(Math.abs(Math.sin(θ) * Math.cos(φ)))

  return φMax.toDegrees()
}

/**
 * Returns the pair of meridians at which a great circle defined by two points crosses the given
 * latitude. If the great circle doesn't reach the given latitude, null is returned.
 *
 * @param {LatLon} point1 - First point defining great circle.
 * @param {LatLon} point2 - Second point defining great circle.
 * @param {number} latitude - Latitude crossings are to be determined for.
 * @returns { { lon1, lon2 } } | null
 */
LatLon.crossingParallels = function (point1, point2, latitude) {
  const φ = Number(latitude).toRadians()

  const φ1 = point1.lat.toRadians()
  const λ1 = point1.lon.toRadians()
  const φ2 = point2.lat.toRadians()
  const λ2 = point2.lon.toRadians()

  const Δλ = λ2 - λ1

  const x = Math.sin(φ1) * Math.cos(φ2) * Math.cos(φ) * Math.sin(Δλ)
  const y = Math.sin(φ1) * Math.cos(φ2) * Math.cos(φ) * Math.cos(Δλ) - Math.cos(φ1) * Math.sin(φ2) * Math.cos(φ)
  const z = Math.cos(φ1) * Math.cos(φ2) * Math.sin(φ) * Math.sin(Δλ)

  if (z * z > x * x + y * y) return null // great circle doesn't reach latitude

  const λm = Math.atan2(-y, x) // longitude at max latitude
  const Δλi = Math.acos(z / Math.sqrt(x * x + y * y)) // Δλ from λm to intersection points

  const λi1 = λ1 + λm - Δλi
  const λi2 = λ1 + λm + Δλi

  return { lon1: (λi1.toDegrees() + 540) % 360 - 180, lon2: (λi2.toDegrees() + 540) % 360 - 180 } // normalise to −180…+180°
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

/**
 * Returns the distance travelling from 'this' point to destination point along a rhumb line.
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @param   {number} [radius=6371e3] - (Mean) radius of earth (defaults to radius in metres).
 * @returns {number} Distance in km between this point and destination point (same units as radius).
 *
 * @example
 *     var p1 = new LatLon(51.127, 1.338), p2 = new LatLon(50.964, 1.853);
 *     var d = p1.distanceTo(p2); // Number(d.toPrecision(4)): 40310
 */
LatLon.prototype.rhumbDistanceTo = function (point, radius) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')
  radius = (radius === undefined) ? 6371e3 : Number(radius)

  // see http://williams.best.vwh.net/avform.htm#Rhumb

  const R = radius
  const φ1 = this.lat.toRadians(); const φ2 = point.lat.toRadians()
  const Δφ = φ2 - φ1
  let Δλ = Math.abs(point.lon - this.lon).toRadians()
  // if dLon over 180° take shorter rhumb line across the anti-meridian:
  if (Math.abs(Δλ) > Math.PI) Δλ = Δλ > 0 ? -(2 * Math.PI - Δλ) : (2 * Math.PI + Δλ)

  // on Mercator projection, longitude distances shrink by latitude; q is the 'stretch factor'
  // q becomes ill-conditioned along E-W line (0/0); use empirical tolerance to avoid it
  const Δψ = Math.log(Math.tan(φ2 / 2 + Math.PI / 4) / Math.tan(φ1 / 2 + Math.PI / 4))
  const q = Math.abs(Δψ) > 10e-12 ? Δφ / Δψ : Math.cos(φ1)

  // distance is pythagoras on 'stretched' Mercator projection
  const δ = Math.sqrt(Δφ * Δφ + q * q * Δλ * Δλ) // angular distance in radians
  const dist = δ * R

  return dist
}

/**
 * Returns the bearing from 'this' point to destination point along a rhumb line.
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @returns {number} Bearing in degrees from north.
 *
 * @example
 *     var p1 = new LatLon(51.127, 1.338), p2 = new LatLon(50.964, 1.853);
 *     var d = p1.rhumbBearingTo(p2); // d.toFixed(1): 116.7
 */
LatLon.prototype.rhumbBearingTo = function (point) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')

  const φ1 = this.lat.toRadians(); const φ2 = point.lat.toRadians()
  let Δλ = (point.lon - this.lon).toRadians()
  // if dLon over 180° take shorter rhumb line across the anti-meridian:
  if (Math.abs(Δλ) > Math.PI) Δλ = Δλ > 0 ? -(2 * Math.PI - Δλ) : (2 * Math.PI + Δλ)

  const Δψ = Math.log(Math.tan(φ2 / 2 + Math.PI / 4) / Math.tan(φ1 / 2 + Math.PI / 4))

  const θ = Math.atan2(Δλ, Δψ)

  return (θ.toDegrees() + 360) % 360
}

/**
 * Returns the destination point having travelled along a rhumb line from 'this' point the given
 * distance on the  given bearing.
 *
 * @param   {number} distance - Distance travelled, in same units as earth radius (default: metres).
 * @param   {number} bearing - Bearing in degrees from north.
 * @param   {number} [radius=6371e3] - (Mean) radius of earth (defaults to radius in metres).
 * @returns {LatLon} Destination point.
 *
 * @example
 *     var p1 = new LatLon(51.127, 1.338);
 *     var p2 = p1.rhumbDestinationPoint(40300, 116.7); // p2.toString(): 50.9642°N, 001.8530°E
 */
LatLon.prototype.rhumbDestinationPoint = function (distance, bearing, radius) {
  radius = (radius === undefined) ? 6371e3 : Number(radius)

  const δ = Number(distance) / radius // angular distance in radians
  const φ1 = this.lat.toRadians(); const λ1 = this.lon.toRadians()
  const θ = Number(bearing).toRadians()

  const Δφ = δ * Math.cos(θ)
  let φ2 = φ1 + Δφ

  // check for some daft bugger going past the pole, normalise latitude if so
  if (Math.abs(φ2) > Math.PI / 2) φ2 = φ2 > 0 ? Math.PI - φ2 : -Math.PI - φ2

  const Δψ = Math.log(Math.tan(φ2 / 2 + Math.PI / 4) / Math.tan(φ1 / 2 + Math.PI / 4))
  const q = Math.abs(Δψ) > 10e-12 ? Δφ / Δψ : Math.cos(φ1) // E-W course becomes ill-conditioned with 0/0

  const Δλ = δ * Math.sin(θ) / q
  const λ2 = λ1 + Δλ

  return new LatLon(φ2.toDegrees(), (λ2.toDegrees() + 540) % 360 - 180) // normalise to −180…+180°
}

/**
 * Returns the loxodromic midpoint (along a rhumb line) between 'this' point and second point.
 *
 * @param   {LatLon} point - Latitude/longitude of second point.
 * @returns {LatLon} Midpoint between this point and second point.
 *
 * @example
 *     var p1 = new LatLon(51.127, 1.338), p2 = new LatLon(50.964, 1.853);
 *     var p2 = p1.rhumbMidpointTo(p2); // p2.toString(): 51.0455°N, 001.5957°E
 */
LatLon.prototype.rhumbMidpointTo = function (point) {
  if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object')

  // http://mathforum.org/kb/message.jspa?messageID=148837

  const φ1 = this.lat.toRadians(); let λ1 = this.lon.toRadians()
  const φ2 = point.lat.toRadians(); const λ2 = point.lon.toRadians()

  if (Math.abs(λ2 - λ1) > Math.PI) λ1 += 2 * Math.PI // crossing anti-meridian

  const φ3 = (φ1 + φ2) / 2
  const f1 = Math.tan(Math.PI / 4 + φ1 / 2)
  const f2 = Math.tan(Math.PI / 4 + φ2 / 2)
  const f3 = Math.tan(Math.PI / 4 + φ3 / 2)
  let λ3 = ((λ2 - λ1) * Math.log(f3) + λ1 * Math.log(f2) - λ2 * Math.log(f1)) / Math.log(f2 / f1)

  if (!isFinite(λ3)) λ3 = (λ1 + λ2) / 2 // parallel of latitude

  return new LatLon(φ3.toDegrees(), (λ3.toDegrees() + 540) % 360 - 180) // normalise to −180…+180°
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

/**
 * Returns a string representation of 'this' point, formatted as degrees, degrees+minutes, or
 * degrees+minutes+seconds.
 *
 * @param   {string} [format=dms] - Format point as 'd', 'dm', 'dms'.
 * @param   {number} [dp=0|2|4] - Number of decimal places to use - default 0 for dms, 2 for dm, 4 for d.
 * @returns {string} Comma-separated latitude/longitude.
 */
LatLon.prototype.toString = function (format, dp) {
  if (format === undefined) format = 'dms'

  return Dms.toLat(this.lat, format, dp) + ', ' + Dms.toLon(this.lon, format, dp)
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

/** Extend Number object with method to convert numeric degrees to radians */
if (Number.prototype.toRadians === undefined) {
  Number.prototype.toRadians = function () {
    return this * Math.PI / 180
  }
}

/** Extend Number object with method to convert radians to numeric (signed) degrees */
if (Number.prototype.toDegrees === undefined) {
  Number.prototype.toDegrees = function () {
    return this * 180 / Math.PI
  }
}

export default LatLon
