import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation} from 'react-router-dom';

import { Row, Col, Container, Badge, Tab, Nav, Image } from 'react-bootstrap';

import Icofont from 'react-icofont';

import { getFromApi } from '../lib/api';
import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';
import { BusinessContext } from '../lib/BusinessContext';


const MenuGridItem = (props) => {
  const { menu, business, onSelected } = props;
  const { photo, name } = props.business;

  const [canOrder, setCanOrder] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [openIn, setOpenIn] = useState(null)
  const [closeIn, setCloseIn] = useState(null)

  const { checkMenuPeriods } = useContext(BusinessContext);
  const { tr } = useTranslations();

  //const { tr } = useTranslations();
  useEffect(() => {
    // check this business can order
    let hasOrdering = true // businessHasOrdering(business)
    setCanOrder(hasOrdering)

    let res = checkMenuPeriods(menu)

    //console.log("checkPeriod res", business.name, res.isOpen ? "OPEN" : "CLOSED",res )

    if (res.isOpen) {
      setIsOpen(true)
      if (res.closeIn && res.closeIn > 0 && res.closeIn < 3600) {
        setCloseIn(`${Math.floor(res.closeIn / 60)} min`)
      }
    } else {
      if (res.openIn && res.openIn > 0 && res.openIn < 7200) {
        setOpenIn(`${Math.floor(res.openIn / 60)} min`)
      }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [business])

  const timeInfo = () => {
    if (openIn) {
      return (
        <p className="card-text" style={{ color: 'green' }}>
          <span className="bg-light  rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Opens in")} {openIn}
          </span>
        </p>
      )

    } else if (closeIn) {
      return (
        <p className="card-text" style={{ color: 'red' }}>
          <span className="bg-light rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Closes in")} {closeIn}
          </span>
        </p>
      )
    } else {
      return (
        <p className="card-text">{menu.Location.address}</p>
      )
    }
  }

  if (canOrder && isOpen) {
    return (
      <div className="card card-business online"
        onClick={() => {
          //console.log("clicked", name)
          onSelected()
        }}
      >
        <img className="card-img-top" src={photo}  />
        <div className="card-body">
          <h1 className="card-name"> {menu.Location.name} </h1>
          {timeInfo()}
        </div>
      </div>
    );
  }

  //console.log("menu grid item", menu)

  return (
    <div className="card card-business offline"
      style={{
        opacity: "0.5"
      }}
    >
      <div className="member-plan position-absolute">
        <Badge pill variant='danger' >Offline</Badge>
      </div>
      <img className="card-img-top" src={photo} />
      <div className="card-body">
        <h1 className="card-name"> {menu.Location.name} </h1>
        {timeInfo()}
      </div>
    </div>
  );
};


const BusinessPage = (props) => {

  const [availableMenus, setAvailableMenus] = useState(null)
  const [business, setBusiness] = useState(null)

  // const [ locationKeys, setLocationKeys ] = useState([])
  const location = useLocation()
  const history = useHistory()

  //console.log(location.state) 

  const downloadAndNavigateToMenu = (menuId) => {

    localStorage.removeItem("selectedMenu")

    getFromApi(`/api/v1/menu/with_business_and_location/${menuId}`, {}, (data) => {
      //console.log("menu result", menuId, data.result)

      let menu = data.result

      // set the department and city
      //setSelectedProvince(menu.Location.province)
      //setSelectedCity(menu.Location.city)

      //console.log("menu data", menu)
      const location = {
        pathname: '/detail',
        state: { selectedMenu: menu }
      }

      history.push(location)
    }, (error) => {
      console.log('get menu failed', error)
    })

  }

  const _showMenu = ( menu) => {
    downloadAndNavigateToMenu(menu.id)
  }

  useEffect(() => {

    //console.log("state", location.state)

    let menus = location.state.business.Menus

    if (menus.length === 1) {
      //console.log("show menu", menus[0])
      _showMenu(  menus[0])
      return
    }

    setBusiness(location.state.business)

    // show an intermediary component to choose the location/menu
    // menus = biz.menus.filter((m) => {
    //   //console.log("filter",biz, m.Location, m)
    //   return m.enabled && m.Location && m.Location.city == city
    // })

    setAvailableMenus(menus)

  }, []);
  
  const getHeader = (business, menuData) => {

    let bizStyle = {}

    if (business.banner && business.banner.length > 1) {
      bizStyle = {
        backgroundImage: `url(${business.banner})`
      }

      return (
        <section className="restaurant-detailed-banner" >
          <div className="text-center banner-styled row" style={{ maxHeight: "400px", minHeight: "260px", width: "100%", margin: 0 }}>
            <div className='col-desapear col-xl' style={{ backgroundColor: "whitesmoke", padding: 0, zIndex: 1 }}>

            </div>
            <img fluid className="col-12 col-xl-10" src={business.banner} style={{ minHeight: "260px", maxHeight: "400px", maxWidth: "1200px", padding: 0 }} alt="" />
            <div className='col-desapear col-xl' style={{ backgroundColor: "whitesmoke", padding: 0, zIndex: 1 }}>

            </div>
          </div>
        
          <div className="restaurant-detailed-header" >
            <Container >
              <Row className="d-flex align-items-end">
                <Col md={8} xl={12}>
                  <div className="restaurant-detailed-header-left margin-xl" style={{ paddingTop: "30px" }}>
                    <Image fluid className="mr-3 float-left" alt="osahan" src={business ? business.photo : ''} />
                    <h2 className="text-white">{business.name}</h2>
                    <p className="text-white mb-1"><Icofont icon="location-pin" /> {menuData.Location.address}
                    </p>
                    <p className="text-white mb-0"><Icofont icon="food-cart" /> {menuData.Location.city}-{menuData.Location.province}
                    </p>
                  </div>
                </Col>
               
              </Row>
            </Container>
          </div>

        </section>
      )
    }

    return (
      <section className="restaurant-detailed-banner" >
        <div className="text-center banner-styled" style={bizStyle} >
          <Image fluid className="cover" src={business ? business.photo : ''} />
        </div>
        <div className="restaurant-detailed-header">
          <Container>
            <Row className="d-flex align-items-end">
              <Col md={8}>
                <div className="restaurant-detailed-header-left">
                  <Image fluid className="mr-3 float-left" alt="osahan" src={business ? business.photo : ''} />
                  <h2 className="text-white">{business.name}</h2>
                  <p className="text-white mb-1"><Icofont icon="location-pin" /> {menuData.Location.address}
                  </p>
                  <p className="text-white mb-0"><Icofont icon="food-cart" /> {menuData.Location.city}-{menuData.Location.province}
                  </p>
                </div>
              </Col>
           
            </Row>
          </Container>
        </div>

      </section>
    )
  }

  if (!business || !availableMenus) return <></>

  return <>
  {getHeader(business, availableMenus[0])}

  <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
    <Container>
      <Row>
        <Col md={12}>
          <div
            className="businesses"
          // breakpointCols={breakpointColumnsObj}
          // className="my-masonry-grid padding-home-product row centrar"
          // columnClassName="my-masonry-grid_column "
          >
            {availableMenus.map((menu, index) => (

              <MenuGridItem menu={menu} business={business} key={index} onSelected={() => {
                //console.log("menu selected", menu)
                _showMenu(menu)
              }} />

            ))}
          </div>

          <h4 className="mt-3">Selecione tú sede más cercana</h4>
        </Col>


      </Row>
    </Container>
  </section>

</>

}

export default BusinessPage;

/*

  useEffect(() => {

    //console.log("history", history)

    let city = selectedCity || props.city
    let province = selectedProvince || props.province

    let hasCredit = true // businessHasOrdering(business)
    setHasOrdering(hasCredit)

    if (!selectedMenu) {
      let menu
      getFromApi(`/api/v1/menu/business_in_city/${business.id}/${city}/${province}/Colombia`, {}, (data) => {

        if (data.result.length === 0) {
          alert(tr("That business does not have an online menu or catalogue"))
        } else if (data.result.length === 1) {
          menu = data.result[0]
          _showMenu(business, hasCredit, menu)
        } else {

          // get the menus in the same city
          let menus = cityMenus(business, data.result)

          // find the default domicilio menu or a menu for the current city                  
          let deliveryMenus = menus.filter((m) => m.deliveries === true)

          if (deliveryMenus.length === 1) {
            menu = deliveryMenus[0]
            _showMenu(business, hasCredit, menu)
          } else if (deliveryMenus.length > 1) {
            _chooseMenu(business, hasCredit, deliveryMenus)
          } else {
            _chooseMenu(business, hasCredit, menus)
          }
        }
      }, (error) => {
        console.log('get business data failed', error)
      })
    } else {
      checkmenu(selectedMenu, hasCredit)

      setOptionsFirst(locationConfigBool(business.Configs, selectedMenu.Location.id, "optionsBeforeAddons", false))
    }

  }, []);

  */