import React, { useState } from 'react'
//import PropTypes, { object } from 'prop-types'

import useTranslations from "../lib/useTranslations";
import Masonry from 'react-masonry-css'

function ProductAddonItem(props) {

  const { addon, detailColour } = props

  const onIncrease = (addon) => {
    if (addon.max > 0 && addon.quantity >= addon.max) {
      //console.log("ProductAddonItem onIncrease exceeded max", addon)
    } else {

      //console.log("ProductAddonItem onIncrease", addon)

      props.onIncrease(addon)
    }
  }

  const onDecrease = (addon) => {
    if (addon.quantity > 0) {
      props.onDecrease(addon)
    }
  }

  return (
    <div className="product-addon-item" >
      <div className="product-addon-item-top"
        style={
          {
            backgroundColor: detailColour,
          }
        }

      >
        <div className="valign-middle">
          {addon.name}
        </div>
        <div className="valign-middle">
          + ${addon.price}
        </div>
      </div>

      <div className="product-addon-item-bottom centrar" >
        <span className="addon-modifier col-4 centrar" style={{ fontSize: "28px", padding: 0, paddingLeft: "15px" }}
          onClick={() => {
            onDecrease(addon)
          }}   >
          {addon.quantity > 0 ? '-' : ''}
        </span>
        <span className="col-4 centrar" style={{ fontSize: "20px", padding: 0, paddingLeft: "10px" }}>
          {addon.quantity || 0}
        </span>
        <span className=" addon-modifier col-4 centrar" style={{ fontSize: "25px", padding: 0, paddingLeft: "10px" }}
          onClick={() => {
            onIncrease(addon)
          }} >
          +
        </span>

      </div>

      {/* <div className="product-addon-item-bottom" >
        <span className="addon-modifier" onClick={() => {
          onDecrease(addon)
        }}   >
          {addon.quantity > 0 ? '-' : ''}
        </span>
        <span className="">
          {addon.quantity || 0}
        </span>
        <span className=" addon-modifier" onClick={() => {

          onIncrease(addon)
        }} >
          +
        </span>

      </div> */}
    </div>


  )
}

function ProductAddonSelector(props) {
  const { addonSelected, product, addons } = props

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  // if we already have selected max product addons then return empty div
  let selectedAddons = product.selectedAddons || []

  if (product.maxAddons && product.maxAddons > 0) {
    let sum = selectedAddons.reduce((sum, a) => sum + a.quantity, 0)

    if (sum >= product.maxAddons) {
      //return <>{sum} {product.maxAddons}</>
      return <></>
    }
  }

  // filter addons that are already selected
  let newAddons = addons.filter((addon) => !selectedAddons.find((selected) => selected.id === addon.id)  )

  //console.log("ProductAddonSelector with", selectedAddons, newAddons, addons)

  if (newAddons.length === 0) return <></>

  return <select style={{
    width: '95%'
  }}
    onChange={(event) => {
      //console.log("selected", event.target.value)
      addonSelected(event.target.value)
    }} >
    <option>{tr("Select addons")}</option>
    {newAddons.map((a, index) =>
    
      <option key={"p_" + product.id + "_addon" + a.id + index} value={a.id}
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      > {a.name} + ${a.price}
      </option>
    )
    }
  </select>
}

export default function ProductAddons(props) {
  const { product } = props;

  /* eslint-disable no-unused-vars */
  const [showAddons, setShowAddons] = useState(true)

  const _selectedAddon = (product, addon) => {
    // if product.selectedAddons is defined and contains
    // the addon, return the selected addon, otherwise return the addon

    let selectedAddons = product.selectedAddons || []
    let found = selectedAddons.find((a) => a.name === addon.name)

    return found || addon
  }

  // const toggleAddons = () => {
  //   setShowAddons(!showAddons)
  // }

  /* eslint-disable eqeqeq */
  if (!product.Addons || product.Addons.length == 0)
    return (<div></div>)

  //if (props.ordersAvailable != true) return (<div></div>)

  const breakpointColumnsObj = {
    default: 5,
    //    1200: 5,
    900: 4,
    700: 3,
    500: 2
  };

  const selectedAddonsGrid = (addons) => {
    if (!addons || addons.length == 0) return <></>

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >

        {addons.filter((a) => a.quantity > 0).map((c) =>
          <ProductAddonItem
            key={"p_" + product.id + "_addon" + c.id}
            product={product} addon={_selectedAddon(product, c)}
            onIncrease={props.onIncrease}
            onDecrease={props.onDecrease}
            detailColour={props.detailColour}
          />
        )
        }

      </Masonry>
    )
  }

  const _addonsContent = (addons) => {

    //console.log("addonsContent with", showAddons, addons)

    if (!addons || addons.length == 0 || !showAddons) {
      return <></>
    }
    if (addons.length > 4) {
      // show selected addons and the addon selector
      // get the selected addons and show in a masonry element

      // show the addon selector


      return <div>

        {selectedAddonsGrid(product.selectedAddons)}

        <ProductAddonSelector product={product}
          addons={addons}
          addonSelected={(id) => {
            //console.log("selected addon", id)
            props.onIncrease(addons.find((addon) => addon.id == id))
          }} />
      </div>
    }

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >

        {addons.map((c) =>
          <ProductAddonItem
            key={"p_" + product.id + "_addon" + c.id}
            product={product} addon={_selectedAddon(product, c)}
            onIncrease={props.onIncrease}
            onDecrease={props.onDecrease}
            detailColour={props.detailColour}
          />
        )
        }

      </Masonry>
    )
  }

  //console.log("addons", product.Addons)

  return (
    <div className="product-addons product-options">

      {product.addonsTitle.length > 0
        ?
        <h5 style={{ margin: "10px" }}>{product.addonsTitle}</h5>
        : <h5 style={{ margin: "10px" }}> Adicionales</h5>
      }
      {/* <div className="open-addons-button"
        onClick={toggleAddons}
      >
        {showAddons &&
          <span>Adicionales -</span>
        }
        {showAddons == false &&
          <span>Adicionales +</span>
        }
      </div> */}

      {_addonsContent(product.Addons.filter((a) => a.available))}

    </div>
  )

}
