import React from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

// this is only used for cart items with selected addons and options
function CartDropdownItem(props) {

  const getSelectedAddons = () => {

    let addons

    // selectedAddons in the pending order
    if (props.item.selectedAddons) {
      addons = props.item.selectedAddons
    } else if (props.item.addons) {
      // addons in the item after refreshing the order from the API
      addons = JSON.parse(props.item.addons)
    }

    if (!addons || addons.length === 0) return <></>

    return (
      <div>
        {addons.map((a, index) => {
          return (
            <p className="mb-2" key={'addon'+index} >
              {a.name}
              <span className="float-right text-secondary">{a.price}</span>
            </p>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <p className="mb-2">
        <Icofont icon={props.icoIcon} className={"mr-1 " + props.iconClass} />
        {props.title}
        <span className="float-right text-secondary">{props.price}</span>
      </p>

      {getSelectedAddons()}

    </div>
  )
}

CartDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
};

CartDropdownItem.defaultProps = {
  title: '',
  price: '',
  icoIcon: '',
  iconclass: '',
  item: {},
}

export default CartDropdownItem;