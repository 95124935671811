
import React, { useState, useContext, useEffect /* */ } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Container, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';

import CheckoutItem from './common/CheckoutItem';
import Icofont from 'react-icofont';

import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';
import { BusinessContext } from '../lib/BusinessContext';

import OrderProgress from '../order/order_progress'
import { DisplayAddressCard } from './common/AddressCard'

function Thanks(props) {

  const [orderSubmitted, setOrderSubmitted] = useState(false)
  const [deliveryChargeRange, setChargeRange] = useState(null)
  const [deliveryPrice, setDeliveryPrice] = useState(null)
  const [deliverType, setDeliverType] = useState(null)
  const [deliveryData, setDeliveryData] = useState(null)
  const [packingCost, setPackingCost] = useState(null)
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState('Cash')
  const [methodData, SetMethodData] = useState(null)
  const [bankName, setBankName] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const { addContact, configValue, locationConfigNumber } = useContext(BusinessContext);
  const { cartItems, ageConfirmed, dateOfBirth, pendingOrder, setPendingOrder, addOrderHistory, submitOrder, totalItemsCost } = useContext(CartContext);

  const { tr, trmap /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const whatsappButton = (phone) => {

    if (phone.startsWith('+')) phone = phone.slice(1)
    if (phone.startsWith('57') === false)
      phone = '57' + phone

    phone = phone.replace(/[^0-9]/g, '')

    let text = `%F0%9F%91%8B%20Hola%20%0ADeseo%20hablar%20sobre%20mi%20pedido%3A%0A`
    let prefix = "https://wa.me/" + phone
    return (
      <a href={prefix + '/?text=' + text} target="_blank" rel="noopener noreferrer" >
        <i className="fa fa-whatsapp fa-2x"
          style={{
            paddingRight: 5,
            color: 'white'
          }}
        ></i>
      </a>

    )
  }

  const _paidOrderButton = (phone, id) => {

    if (phone.startsWith('+')) phone = phone.slice(1)
    if (phone.startsWith('57') === false)
      phone = '57' + phone

    phone = phone.replace(/[^0-9]/g, '')

    let text = `%F0%9F%91%8B%20Hola%2C%20mi%20pedido%20%5B${id}%5D%20en%20XOO%20NOO%20esta%20pagado%20con%20referencia%20%3A%20`
    let prefix = "https://wa.me/" + phone

    return (
      <a href={prefix + '/?text=' + text} target="_blank" rel="noopener noreferrer" >
        <div className="align-items-center" style={{ marginLeft: 0, marginRight: 2, height: 50, display: 'flex', justifyContent: 'space-evenly' }} >
          <div style={{ paddingTop: 5 }} className="font-weight-bold ">

            <i className="fa fa-whatsapp fa-2x"
              style={{
                paddingRight: 5,
                color: 'black'
              }}
            ></i>
          </div>
          <div className=" font-weight-bold align-items-center" style={{ color: 'black', }}>Enviar comprobante</div>

        </div>
      </a>

    )
  }

  const _iconForPaymentMethod = () => {
    //console.log("payment", paymentMethod, bankName, methodData)
    if (paymentMethod === 'Transfer' || paymentMethod === 'Transferencia') {
      if (bankName && bankName === 'Bancolombia') {
        return (
          <div><img src="/img/bank/bancolombia.png" style={{ maxWidth: '90%' }} alt="Bancolombia" /></div>
        )
      }
      if (bankName && bankName === 'Davivienda') {
        return (
          <div><img src="/img/bank/davivienda.png" style={{ maxWidth: '90%' }} alt="Davivienda" /></div>
        )
      }
      return (
        <div><img src="/images/transfer.png" height={40} alt="Transfer" /></div>
      )
    } else if (paymentMethod === 'Nequi') {
      return (
        <div><img src="/images/nequi.png" height={50} alt="Nequi" /></div>
      )
    } else if (paymentMethod === 'Cash' || paymentMethod === 'Efectivo') {
      return (
        <div><img src="/images/cash.png" height={50} alt="Cash" /></div>
      )
    } else if (paymentMethod === 'Daviplata') {
      return (
        <div><img src="/images/daviplata.png" height={50} alt="Daviplata" /></div>
      )
    }

  }

  const _paymentInfo = (id) => {

    if (paymentMethod !== 'Cash' && paymentMethod !== 'Efectivo') {
      return (
        <div className="row align-items-center" style={{ marginLeft: 0, marginRight: 2, marginBottom: 20, height: 50 }} >
          <div style={{ backgroundColor: '#fff', borderRadius: 12, paddingLeft: 10 }} className="col-6 font-weight-bold">{_iconForPaymentMethod()} <span className="font-weight-bold">{methodData || ''}</span></div>
          <div style={{ backgroundColor: '#F1AD3E', borderRadius: 12 }} className="col-6 font-weight-bold">{_paidOrderButton(selectedMenu.Location.phone, id)}</div>
        </div>
      )
    }
    return (
      <div className="row align-items-center" style={{ marginLeft: 0, marginRight: 2, marginBottom: 20, height: 50 }} >
        <div style={{ backgroundColor: '#fff', borderRadius: 12, paddingLeft: 0 }} className="col-6 font-weight-bold">{_iconForPaymentMethod()}
          <span className="font-weight-bold">{tr(paymentMethod)}</span></div>
      </div>
    )

  }

  const trackingButton = (phone, Bcolor, img, id) => {

    if (phone.startsWith('+')) phone = phone.slice(1)
    if (phone.startsWith('57') === false)
      phone = '57' + phone

    phone = phone.replace(/[^0-9]/g, '')

    let text = `%F0%9F%91%8B%20Hola%2C%20quiero%20hacer%20seguimiento%20de%20mi%20pedido%20%5B${id}%5D%20en%20XOO%20NOO`
    let prefix = "https://wa.me/" + phone
    return (
      <div style={{
        margin: 10
      }}>
        <a href={prefix + '/?text=' + text} target="_blank" rel="noopener noreferrer"

          className="btn btn-lg row centrar" style={{ backgroundColor: Bcolor, color: "white", margin: 0, padding: "10px" }}
        >
          <i className={img}
            style={{ color: 'white', padding: "10px" }}
          ></i>
          {tr("Track my order")}
          <Icofont icon="long-arrow-right" />

        </a>
      </div>
    )
  }

  const _deliveryDataDisplay = () => {

    const iconForTitle = (title) => {
      switch (title) {
        case tr("Home"): return "home";
        case tr("Work"): return "briefcase";
        default: return 'building-alt'
      }
    }
    if (deliverType === 'delivery') {
      return <DisplayAddressCard
        boxclassName="border border-success"
        title={deliveryData.title}
        icoIcon={iconForTitle(deliveryData.title)}
        iconclassName='icofont-3x'
        address={deliveryData}
        boxClass={''}
      />
    } else if (deliverType === 'takeaway') {
      return (
        <div id="takeaway-delivery" className=" delivery-item"    >

          <div className="row">
            <div className="col centered">
              <img src="/images/takeaway.png" alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col centered">
              {tr("Takeaway")}
            </div>
          </div>
        </div>
      )
    } else if (deliverType === 'table') {
      return (
        <div id="table-delivery" className="delivery-item">
          <div className="row">
            <div className="col centered">
              <img src="/images/table-service.png" alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col centered" style={{
              fontSize: '1.5em'
            }}>
              {tr("Table")} {deliveryData.tableNum}
            </div>
          </div>

        </div>
      )
    }

  }

  const location = useLocation()

  //console.log(location)

  const _fixupPhone = (phone) => {
    if (phone == null) {
      return ""
    }
    if (phone.startsWith('+')) return phone;

    if (phone.startsWith('3') && phone.length === 10) return `+57${phone}`

    return `+${phone}`
  }

  useEffect(() => {

    if (location.state) {

      //console.log("has location state", location.state)

      let mData = location.state.selectedMenu
      let deliveryData = location.state.deliveryData
      let paymentMethod = location.state.paymentMethod
      let items = location.state.items
      let deliverType = location.state.deliverType
      let methodData = location.state.methodData
      let bankName = location.state.bankName

      let packingCost = deliverType == "table" ? 0 : locationConfigNumber(mData.Business.Configs, mData.Location.id, "packingCost", null)

      setSelectedMenu(mData)
      //setCartItems(items)
      setDeliverType(deliverType)
      setDeliveryData(deliveryData)
      setPaymentMethod(paymentMethod)
      SetMethodData(methodData)
      setBankName(bankName)

      // get the delivery charge range from business configs
      /* eslint-disable react-hooks/exhaustive-deps */
      //console.log("menu business",mData.Business)
      setChargeRange(configValue(mData.Business, "deliveryChargeRange", null))
      /* eslint-disable react-hooks/exhaustive-deps */
      setPackingCost(packingCost)

      //console.log("data:",  deliveryData ,paymentMethod )
      let po = localStorage.getItem('pendingOrder')

      //console.log("submit order:",        orderSubmitted, pendingOrder, mData, deliveryData, paymentMethod, items.length      )

      if (!po && !orderSubmitted && !pendingOrder && mData && paymentMethod && items && items.length > 0) {

        /* eslint-disable react-hooks/exhaustive-deps */
        //setMinimumOrderValue(configNumber(mData.Business, "minimumOrderValue", 0))
        /* eslint-disable react-hooks/exhaustive-deps */
        //setMinimumOrderMessage(configValue(mData.Business, "minimumOrderMessage", null))

        // submit the order with the data from the localStorage and CartContext

        deliveryData.phone = _fixupPhone(deliveryData.phone)

        setErrorMessage(null)

        submitOrder(mData, deliverType, deliveryData, paymentMethod, items, packingCost, (data) => {
          setOrderSubmitted(true)

          // add the order contact
          addContact(deliveryData, mData, ageConfirmed, dateOfBirth)

          //let d = new Date()

          //let d = new Date()

          // add the order to the previous orders
          // data is the order ID returned from the API
          let order = {
            id: data.id,
          }
          //console.log("add order history", order)
          addOrderHistory(order)
        }, (error) => {
          console.log("error", error)
          setErrorMessage(error)
        })

      } else {
        setOrderSubmitted(true)

        if (po != null) {
          let order = JSON.parse(po)
          //console.log("pending order", order)
          setPendingOrder(order)
        }
      }
    } else {
      window.location.href = "/"
    }

  }, [])

  let order = pendingOrder || { items: [] };

  const items = order.items

  //console.log("thanks items", pendingOrder)


  const _getTotalCostIncPacking = () => {
    // if (pendingOrder) {
    //   return pendingOrder.totalcost + packingCost
    // }
    if (!deliverType) {
      return totalItemsCost(items)
    }
    if (deliverType !== "table") {
      return totalItemsCost(items) + packingCost
    }
    return totalItemsCost(items)
  }

  const _deliveryChargeElement = () => {
    let totalCost = _getTotalCostIncPacking()

    if (deliverType === "delivery" && deliveryPrice != null && parseInt(deliveryPrice) > 0) {
      let delFee = parseInt(deliveryPrice) 
      totalCost += delFee
      return <>
       <p className="mb-1 deliveryPrice">{tr("Delivery Fee")}
          <span className="float-right text-dark">$ {deliveryPrice}</span>
        </p>
        
      <h6 className="font-weight-bold mb-0">{tr("TO PAY")} <span className="float-right">${totalCost}</span></h6>
      </>
    }

    return <>
      {deliveryChargeRange && deliverType === "delivery" &&
        <p className="mb-1 deliveryChargeRange">{tr("Estimated Delivery Fee")}
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                {tr("Actual cost varies")}
              </Tooltip>
            }
          >
            <span className="text-info ml-1">
              <Icofont icon="info-circle" />
            </span>
          </OverlayTrigger>
          <span className="float-right text-dark">{deliveryChargeRange}</span>
        </p>
      }

      {/* total discount*/}
      { /*
                <p className="mb-1 text-success">Total Discount
                  <span className="float-right text-success">$1884</span>
                </p>

                */}
      <hr />
      <h6 className="font-weight-bold mb-0">{tr("TO PAY")} <span className="float-right">${totalCost}</span></h6>

      {deliveryChargeRange && packingCost && deliverType === "delivery" &&
        <div>&nbsp;</div>
      }

      {deliveryChargeRange && deliverType === "delivery" &&
        <span className="float-right"> + {deliveryChargeRange}</span>
      }
    </>
  }

  let totalCost = _getTotalCostIncPacking()

  // <Link className="btn btn-primary btn-lg" to="/myaccount/orders">{tr("GO HOME")}</Link>
  if (orderSubmitted) {
    return (
      <section className="section pt-5 pb-5 osahan-not-found-page">
        <Container>
          <Row>
            <Col md={8} className="text-center pb-5" style={{ paddingBottom: "3rem" }}>
              <Row className='centrar align-items-center' style={{ padding: "10px" }}>
                <div className="col">
                  <Image className="img-thanks col-4 col-sm-12" src="/img/thanks.png" alt="404" />
                </div>

                <div className="col" style={{ marginBottom: "10px" }}>
                  <span>{tr("Your order has been placed")}</span>
                </div>
              </Row>

              <div className="row">
                {/* order tracking stepper */}
                {pendingOrder &&
                  <OrderProgress order={order} menu={selectedMenu} setDeliveryPrice={(price) => {
                    //console.log("delivery price", price)
                    setDeliveryPrice(price)
                  }
                  } />
                }

              </div>

            </Col>
            {/* <Col md={8} className="text-center pt-5 pb-5">
              <Image className="img-fluid" src="/img/thanks.png" alt="404" />
              <h1 className="mt-2 mb-2">{tr("Congratulations")}</h1>
              <p className="mb-5">{tr("Your order has been placed")} </p>
              <Link className="btn btn-primary btn-lg" to="/">{tr("GO HOME")}</Link>
            </Col> */}

            <Col md={4}>
              <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                <div className="d-flex mb-1 osahan-cart-item-profile">
                  <Image fluid className="mr-3 rounded-pill" alt="osahan" src={selectedMenu.Business.photo || "/img/2.jpg"} />
                  <div className="d-flex flex-column">
                    <h6 className="mb-1 text-white">{selectedMenu.Business.name}</h6>
                    <p className="mb-1 text-white"><Icofont icon="location-pin" /> {selectedMenu.Location.address}, {selectedMenu.Location.city}</p>
                    <div className="mb-0 text-white row align-items-center" style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '1rem'
                    }}>
                      <div className='col-3 pr-0 align-end'>
                        {whatsappButton(selectedMenu.Location.phone)}
                      </div>
                      <div className='col pl-2'>
                        {selectedMenu.Location.phone}
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row align-items-center">
                  <div className="col d-flex justify-content-center">

                    {trackingButton("573246575352", "#25D366", "fa fa-whatsapp fa-lg", order.id)}
                  </div>

                </div>

                <div className="bg-white rounded shadow-sm mb-2">
                  {items.map((item, index) => {

                    //console.log("Thanks item", item)
                    return <CheckoutItem
                      key={'cart_item_' + index}
                      itemName={item.name}
                      cartItem={item}
                      price={item.chosenPrice || item.price}
                      priceUnit="$"
                      id={item.id}
                      quantity={item.quantity}
                      show={true}
                      minValue={0}
                      maxValue={7}

                    />
                  }
                  )}

                </div>

                { /* promo code */}

                <div className="mb-2 bg-white rounded p-2 clearfix">
                  { /* item cost */}
                  <p className="mb-1 itemTotal">{tr("Item Total")} <span className="float-right text-dark">${totalItemsCost(items)}</span></p>

                  {packingCost && deliverType && deliverType !== "table" &&
                    <p className="mb-1">{tr("Packaging charge")}
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {tr("Additional charges for packaging material")}
                          </Tooltip>
                        }
                      >
                        <span className="text-info ml-1">
                          <Icofont icon="info-circle" />
                        </span>
                      </OverlayTrigger>
                      <span className="float-right text-dark">{packingCost}</span>


                    </p>
                  }

                  { /*  delivery charge */}
                  {_deliveryChargeElement()}
                </div>

              </div>

              <div style={{
                backgroundColor: '#ddd',
                padding: 5
              }}>

                <h6>{tr("Payment Method")}</h6>

                {_paymentInfo(order.id)}

                <h6>{tr("Deliver To")}</h6>

                <div className="p-0" style={{ backgroundColor: '#fff' }}>
                  {_deliveryDataDisplay()}
                </div>

              </div>



              { /*
            <div className="alert alert-success" role="alert">
              You have saved <strong>$1,884</strong> on the bill
            </div>
                  */}
              <div className="pt-2"></div>


            </Col>
            <Col md={4} className="text-center pt-5 btn-show-goHome">
              <Link className="btn btn-primary btn-lg" to="/">{tr("GO HOME")}</Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  if (errorMessage) {
    return (
      <section className="section pt-5 pb-5 osahan-not-found-page">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 pb-5">
              <Image className="img-fluid" src="/img/logo.png" alt="thanks" />
              <h1 className="mt-2 mb-2">{tr("Error")}</h1>
              <p className="mb-5">{errorMessage} </p>

              <p>Por favor contactar {selectedMenu.Business.name}</p>
              <p> {selectedMenu.Location.phone}</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <section className="section pt-5 pb-5 osahan-not-found-page">
      <Container>
        <Row>
          <Col md={12} className="text-center pt-5 pb-5">
            <Image className="img-fluid" src="/img/logo.png" alt="thanks" />
            <h1 className="mt-2 mb-2">{tr("Submitting your order")}</h1>
            <p className="mb-5">{tr("Please wait")} </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Thanks;