import React, { useState } from 'react'

import Masonry from 'react-masonry-css'

import { groupBy } from '../lib/utils'

const breakpointColumnsObj = {
  default: 5,
  //    1200: 5,
  900: 4,
  700: 3,
  500: 2
};

function ProductOptionItem(props) {

  const { product, option, group, detailColour, onSelected } = props

  const checkMark = () => {
    return (
      <i className="icofont-check-circled"
        style={{
          color: 'green'
        }
        }
      ></i>
    )
  }

  //let classes = "justify-content-center product-option"

  // this option is selected if it is in the product.selectedOptions array
  let selectedOptions = product.selectedOptions || []
  let found = selectedOptions.find(o => o.id === option.id)

  return (
    <div className="product-addon-item" onClick={() => onSelected(option, group)} >
      <div className="product-addon-item-top"
        style={
          {
            backgroundColor: detailColour,
          }
        }
      >
        <div className="valign-middle">
          {option.name}
        </div>
        <div className="valign-middle">
          + ${option.price}
        </div>
      </div>

      <div className="product-addon-item-bottom centrar" >
        <span className="col-4 centrar" style={{ fontSize: "20px", padding: 0, paddingLeft: "10px" }}>
          {found ? checkMark() : 'X'}
        </span>
      </div>

    </div>
  )
}

function OptionDivTitle(props) {
  // if index > 0 start with a <hr>

  let title = `Debes de seleccionar 1 de estas opciones: ${props.groupName}`

  if (props.mandatory && props.mutex) {
    // noop
  } else if (props.mandatory) {
    title = `Debes de seleccionar las opciones de ${props.groupName}`
  } else if (props.mutex) {
    title = `Puedes seleccionar 1 de estas opciones: ${props.groupName}`
  } else {
    title = `Seleccionar otras opciones de ${props.groupName}`
  }

  return (
    <div >
      {props.index > 0 && <hr />}
      <h5 style={{ margin: "10px" }}>{title}</h5>
    </div>
  )
}

function OptionDescriptions(props) {
  const { product, options } = props

  // remove any options with an empty description
  let opts = options.filter((o) => o.description.length > 0)

  //console.log("filtered options", opts)

  return (
    <div style={{
      marginBottom: 10
    }} >
      {opts.map((o) =>
        <div className="row" key={`option_desc_${o.id}`}>
          <div className="col"><small>{o.name} : {o.description}</small></div>
        </div>
      )}
    </div>
  )
}

function OptionGroup(props) {
  const { product, group, options } = props

  //console.log("MutexOptionGroup options", options)

  return (
    <div className="product-options">
      <OptionDivTitle {...props} />

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >

        {options.map((opt) =>
          <ProductOptionItem
            key={"p_" + product.id + "_option" + opt.id}
            product={product} option={opt}

            group={group}
            detailColour={props.detailColour}

            onSelected={props.onSelected}
          />
        )
        }

      </Masonry>

      <OptionDescriptions {...props} />

    </div>
  )
}
/* eslint-disable no-unused-vars */
function MultiOptionGroup(props) {
  const { product, options } = props

  console.log("MutexOptionGroup options", options)

  return (
    <div className="product-options">
      <OptionDivTitle {...props} />

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >

        {options.map((opt) =>
          <ProductOptionItem
            key={"p_" + product.id + "_option" + opt.id}
            product={product} option={opt}

            detailColour={props.detailColour}

            onSelected={props.onSelected}
          />
        )
        }

      </Masonry>

    </div>
  )
}

function ProductOptionsOld(props) {

  const [showOptions, /* setShowOptions */] = useState(true)

  const product = props.product;
  if (!product.Options || product.Options.length === 0)
    return (<div></div>)

  let options = product.Options.filter((opt) => opt.available === true)

  const grouped = groupBy(options, opt => opt.group_name)
  const groups = Array.from(grouped.values())

  //console.log("final options", props.ordersAvailable, options, groups)

  if (!showOptions) return (<div></div>)

  return (
    groups.map((group, index) => {

      if (group[0].mutex) {
        return <OptionGroup
          key={"p_" + product.id + "_optiongroup" + index}
          product={product}
          options={group}
          detailColour={props.detailColour}
          ordersAvailable={props.ordersAvailable}
          mandatory={group[0].mandatory}
          index={index}
          mutex={true}
          groupName={group[0].group_name}
          onSelected={props.onSelected}
        />
      } else {
        return <OptionGroup
          key={"p_" + product.id + "_optiongroup" + index}
          product={product}
          options={group}
          detailColour={props.detailColour}
          ordersAvailable={props.ordersAvailable}
          mandatory={group[0].mandatory}
          index={index}
          mutex={false}
          groupName={group[0].group_name}
          onSelected={props.onSelected}
        />
      }
    }
    )
  )

}


export default function ProductOptions(props) {

  const [showOptions, /* setShowOptions */] = useState(true)

  const product = props.product;
  if (!product.OptionGroups || product.OptionGroups.length === 0)
    return (<div></div>)

  if (!showOptions) return (<div></div>)

  const groups = product.OptionGroups

  groups.sort((a, b) => a.sequence - b.sequence)

  //console.log("final options", groups)

  return (
    groups.map((group, index) => {

      let options = group.Options.filter((opt) => opt.available === true)

      if (options.length == 0) return <div key={"p_" + product.id + "_optiongroup" + index}></div>

      options.sort((a, b) => a.sequence - b.sequence)

      // ensure the group name has a value
      options.forEach(opt => {
        opt.group_name = group.name
      });

      if (group.mutex) {
        return <OptionGroup
          key={"p_" + product.id + "_optiongroup" + index}
          product={product}
          options={options}
          detailColour={props.detailColour}
          ordersAvailable={props.ordersAvailable}
          mandatory={group.mandatory}
          index={index}
          mutex={true}
          groupName={group.name}
          group={group}
          onSelected={props.onSelected}
        />
      } else {
        return <OptionGroup
          key={"p_" + product.id + "_optiongroup" + index}
          product={product}
          options={options}
          detailColour={props.detailColour}
          ordersAvailable={props.ordersAvailable}
          mandatory={group.mandatory}
          index={index}
          mutex={false}
          groupName={group.name}
          group={group}
          onSelected={props.onSelected}
        />
      }
    }
    )
  )

}

/*

function MutexOptionGroup(props) {
  const product = props.product

  let options = props.options.map((opt) => {
    if (opt.price > 0)
      return { label: `${opt.name} + \$${opt.price} COP`, value: opt.name }

    return { label: opt.name, value: opt.name }
  })

  function _onChange(value) {
    if (props.ordersAvailable == true) {
      // get the option for this name
      let option = props.options.find(o => o.name == value)
      props.onChange(option)
    }
  }

  return (
    <div className="product-options">
      <OptionDivTitle {...props} />

      <div className="options-row">
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">{props.groupName}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            //value={value}
            onChange={_onChange}
          >
            {options.map((opt) => <FormControlLabel value={opt.value} control={<Radio />} label={opt.label} />)}

          </RadioGroup>
        </FormControl>

      </div>
    </div>
  )
}

function MultiOptionGroup(props) {
  const product = props.product
  const options = props.options

  function _onChange(value) {
    if (props.ordersAvailable == true) {
      props.onChange(value)
    }
  }

  return (
    <div className="product-options">
      <OptionDivTitle {...props} />

      <div className="options-row">
        {options.map((c) =>
          <ProductOptionItem
            key={"p_" + product.id + "_option" + c.id}
            detailColour={props.detailColour}
            product={product}
            option={c}
            onChange={(opt) => {
              //console.log("onchange", opt.name, opt.value)
              _onChange(opt)
            }}
          />
        )}
      </div>
    </div>
  )
}


/////////// older


function MutexOptionGroup(props) {
  const product = props.product

  let options = props.options.map((opt) => {
    if (opt.price > 0)
      return { label: `${opt.name} + \$${opt.price} COP`, value: opt.name }

    return { label: opt.name, value: opt.name }
  })

  function _onChange(value) {
    if (props.ordersAvailable == true) {
      // get the option for this name
      let option = props.options.find(o => o.name == value)
      props.onChange(option)
    }
  }

  return (
    <div className="product-options">
      <OptionDivTitle {...props} />

      <div className="options-row">
        <RadioGroup
          className="options-row"
          itemClassName="product-option"
          labelClassName="product-option-label"
          options={options}
          name="product-option"
          inline={true}
          detailColour={props.detailColour}
          onChange={(opt) => {
            //console.log("onchange", opts.name, opts.value)
            
            _onChange(opt.value)
          }}
        />

      </div>
    </div>
  )
}
*/