import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { QRCodeSVG } from 'qrcode.react';
import { Badge , Image} from 'react-bootstrap';
import { getFromApi } from './lib/api';
import { BusinessContext } from './lib/BusinessContext';
import { CartContext } from './lib/CartContext';
import useTranslations from "./lib/useTranslations";

import TopSearch from './components/home/TopSearch';
import BareModal from './components/modals/BareModal';
import Icofont from 'react-icofont';
import BusinessPage from './Business'

const BusinessCard = (props) => {
  const { photo, name, description } = props.business;
  const { onSelected } = props

  const { business } = props;

  const [canOrder, setCanOrder] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [openIn, setOpenIn] = useState(null)
  const [closeIn, setCloseIn] = useState(null)

  const { bestDeliveryMenu, checkMenuPeriods } = useContext(BusinessContext);
  const { tr } = useTranslations();

  //const { tr } = useTranslations();
  useEffect(() => {
    // check this business can order
    let hasOrdering = true // businessHasOrdering(business)
    setCanOrder(hasOrdering)

    if (hasOrdering) {
      // check they are open
      if (business.menus) {
        let menu = bestDeliveryMenu(business, business.menus)
        let res = checkMenuPeriods(menu)

        //console.log("checkPeriod res", business.name, res.isOpen ? "OPEN" : "CLOSED",res )
        //console.log("menu periods", menu)

        if (res.isOpen) {
          setIsOpen(true)
          if (res.closeIn && res.closeIn > 0 && res.closeIn < 3600) {
            setCloseIn(`${Math.floor(res.closeIn / 60)} min`)
          }
        } else {
          if (res.openIn && res.openIn > 0 && res.openIn < 7200) {
            setOpenIn(`${Math.floor(res.openIn / 60)} min`)
          }
        }
      }

    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [business])

  const timeInfo = () => {
    if (openIn) {
      return (
        <p className="card-text" style={{ color: 'green' }}>
          <span className="bg-light  rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Opens in")} {openIn}
          </span>
        </p>
      )

    } else if (closeIn) {
      return (
        <p className="card-text" style={{ color: 'red' }}>
          <span className="bg-light rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Closes in")} {closeIn}
          </span>
        </p>
      )
    } else {
      return (
        <p className="card-text">{description}</p>
      )
    }
  }

  //console.log("businnes", name, canOrder, isOpen)

  if (canOrder && isOpen) {
    return (
      <div className="card card-business online"
        onClick={() => {
          //console.log("clicked", name)
          onSelected()
        }}
      >
        <img className="card-img-top" src={photo} alt="Card image cap" />
        <div className="card-body">
          <h1 className="card-name"> {name} </h1>
          {timeInfo()}
        </div>
      </div>
    );
  }

  return (
    <div className="card card-business offline"
      style={{
        opacity: "0.5"
      }}
    >
      <div className="member-plan position-absolute">
        <Badge pill variant='danger' >Offline</Badge>
      </div>
      <img className="card-img-top" src={photo} alt="Card image cap" />
      <div className="card-body">
        <h1 className="card-name"> {name} </h1>
        {timeInfo()}
      </div>
    </div>
  );
};

const Home = (props) => {
  /* eslint-disable no-unused-vars */
  //const [currentLocation, setCurrentLocation] = useState({});
  const [loading, setLoading] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)

  // const [chosenProvince, setChosenProvince] = useState(null)
  // const [chosenCity, setChosenCity] = useState(null)

  const { businesses, clearSavedData, departments, cities, selectedBusiness, selectedMenu, reloadMenu,
    setSelectedBusiness, selectedProvince, setSelectedProvince, provinceSelected,
    selectedCity,
    setSelectedCity,
    setCityName, } = useContext(BusinessContext);

  const { clearCartData } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const history = useHistory();


  const clearAllData = () => {
    clearSavedData()
    clearCartData()
  }

  const downloadAndNavigateToMenu = (menuId) => {

    localStorage.removeItem("selectedMenu")

    getFromApi(`/api/v1/menu/with_business_and_location/${menuId}`, {}, (data) => {
      console.log("menu result", menuId, data.result)

      // find the given menu
      //// IMPORTANT do not change this test to === otherwise use parseInt()
      /* eslint-disable eqeqeq */
      let menu = data.result

      // set the department and city
      setSelectedProvince(menu.Location.province)
      setSelectedCity(menu.Location.city)

      //console.log("menu data", menu)
      const location = {
        pathname: '/detail',
        state: { selectedMenu: menu }
      }

      history.push(location)
    }, (error) => {
      console.log('get menu failed', error)
    })

  }

  useEffect(() => {

    // on page load clear the cart
    //localStorage.removeItem("cartItems")
    //localStorage.removeItem("locationData")

    localStorage.removeItem("selectedMenu")
    //localStorage.removeItem("paymentMethod")
    //localStorage.removeItem("deliveryAddress")
    //localStorage.removeItem("pendingOrder")

    getTranslations();

    // get the data from the parameters
    const urlParams = new URLSearchParams(window.location.search);
    let menuId = urlParams.get('mid');
    const locationId = urlParams.get('lid');
    const businessId = urlParams.get('bid');
    const tableId = urlParams.get("tid");
    const orderId = urlParams.get("oid")
    const rating = urlParams.get("rate")
    const expo = urlParams.get("expo")

    if (businessId != null && locationId != null && menuId != null) {
      //console.log("params", businessId, locationId, menuId)
      setLoading(true)

      // handle Gordo links
      if (businessId === 303 && locationId === 265 && menuId === 141) {
        menuId = 166
      } else if (businessId === 303 && locationId === 267 && menuId === 143) {
        menuId = 167
      }


      if (tableId != null) {
        localStorage.setItem("tableNum", tableId)
      } else {
        localStorage.removeItem("tableNum")
      }

      // clear the cart in case any item in the cart is not in the same menu
      clearCartData(menuId)

      downloadAndNavigateToMenu(menuId)

    } else if (orderId && rating) {
      // go to the rating page to rate the order and delivery

    } else {
     // setOpenPopup(true)
    }

  }, [])

  const _getBusinessGrid = (businesses) => {
    const breakpointColumnsObj = {
      default: 5,
      1200: 4,
      900: 3,
      700: 2,
      500: 1
    };

    if (loading) {
      return (
        <>
          <div className='row justify-content-center' style={{ margin: 0 }}>
            <div className='loader '></div>

          </div>
          <div className='row justify-content-center' style={{ margin: 0 }}>
            <div>
              Cargando...
            </div>
          </div>
        </>
      )
    }

    else {
      return (
        <div
          className="businesses"
        >
          {businesses.map((business, index) => (
            <BusinessCard business={business} key={`hp_biz_` + index} onSelected={() => {
              //console.log("selected business", business)
              setSelectedBusiness(business)
            }} />
          ))}
        </div>
      )
    }
  }
  /* 
    if (selectedBusiness || selectedMenu) {
      //console.log("selected business", selectedBusiness)
  
      return (
        <BusinessPage business={selectedBusiness} />
      )
    }
  
    if (businesses && businesses.length > 0) {
      console.log("businesses", businesses)
      return (
        <>
          <TopSearch />
  
          <section className="section pt-3 pb-5 bg-white homepage-add-section ">
            {_getBusinessGrid(businesses)}
          </section>
        </>
      )
    }
   */
  return (
    <div className="home-body">

      <div className="home-body-content mt-5 pl-4">
        {/*    {selectedCity && businesses && businesses.length == 0 &&
          <div className="row mb-4">
            <div className="col-12">
              <span className="fs-1-5 fw-400 poppins">No hay ningún negocio abierto en la ciudad elegida.</span>

            </div>
          </div>

        } */}
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-12">
                <h1>Pide de tu Restaurante Favorito al Mejor Precio.</h1>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <h3>Conozca toda las ofertas, en la App.</h3>

              </div>
            </div>


            <div className=" centered mt-1">
            <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
              <div className="">
                <div className="centered"  >
                  <span style={{
                    padding: 10,
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 10,
                    fontSize: 15,
                  }}   >Redime Ofertas Exclusivas</span>

                </div>
              </div>
              </a>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <span className="fs-1-5 fw-400 poppins">Conoce aliados cerca de ti.</span>

              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <select className="province-selector"
                  onChange={(event) => {
                    clearAllData()
                    setSelectedCity('')
                    provinceSelected(event.target.value);

                    const location = {
                      pathname: `/p/${event.target.value}/show`,
                      state: { province: event.target.value }
                    }

                    history.push(location)
                  }}
                >
                  <option value="-">Elije tu departamento</option>

                  {departments.map((dept, index) => {
                    if (selectedProvince == dept) {
                      return (
                        (
                          <option key={'dept_' + index} value={dept} selected>{dept}</option>
                        )
                      )
                    } else {
                      return ((
                        <option key={'dept_' + index} value={dept}>{dept}</option>
                      ))
                    }

                  })}
                </select>

              </div>
            </div>

            {selectedProvince && cities &&
              <div className="row mt-4">
                <div className="col-12">
                  <select className="province-selector"
                    defaultValue="-"
                    onChange={(event) => {
                      let city = event.target.value
                      if (city == '-') city = ''
                      clearAllData()
                      console.log("setSelectedCity")
                      setSelectedCity(city)

                      let province = selectedProvince

                      if (city == '-') { city = '' } else {
                        const location = {
                          pathname: `/c/${province}/${city}/show`,
                          state: { city: city, province: province }
                        }

                        history.push(location)
                      }
                    }}
                  >
                    {selectedCity == '' &&
                      <option value="-" >Elije tu ciudad</option>
                    }

                    {cities.map((city, index) => (
                      <option key={'dept_' + index} value={city}>{city}</option>
                    ))}
                  </select>

                </div>
              </div>

            }

            <div className="row mt-4 show-only-large-devices">
              <div className="col-12">
                <h3>¡Pide Yá!</h3>

              </div>
            </div>

          </div>


          <div className="col show-only-large-devices">
            <div className="centered" style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}>

              <div className="centered">
                <QRCodeSVG value="https://fbh.xoonoo.com/1YMJ" />
              </div>

              <div className="mt-4 centered">
                <img src="/images/somos_aliados.png" style={{ width: 300 }}></img>
              </div>

            </div>

          </div>

          <div className="col show-only-large-devices">
            <div className="row">
              <div className="col">
                <div className="mt-4 centered">
                  <img src="/images/Club_screen1.png" style={{ width: 200 }}></img>
                </div>

                <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
                  <div className="mt-4 centered">
                    <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
                  </div>
                </a>
              </div>
              <div className="col">
                <div className="mt-4 centered">
                  <img src="/images/Club_screen2.png" style={{ width: 200 }}></img>
                </div>

                <a href="https://fbh.zono.cc/?link=https://zono.cc/download&apn=cc.zono.mobile&isi=6463274860&ibi=com.xoonoo.club" target="_blank">
                  <div className="mt-4 centered">
                    <img src="/images/appstore-badge.png" style={{ width: 150 }}></img>
                  </div>
                </a>
 
              </div>
            </div>


          </div>


        </div>


        <div className="row mt-4 show-only-small-devices">
          <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
            <div className="col-6" >
              <div className="mt-1 centered">
                <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
              </div>

            </div>
          </a>
 
          <a href="https://fbh.zono.cc/?link=https://zono.cc/download&apn=cc.zono.mobile&isi=6463274860&ibi=com.xoonoo.club" target="_blank">
            <div className="col-6">
              <div className="mt-1 centered">
                <img src="/images/appstore-badge.png" style={{ width: 150 }}></img>
              </div>

            </div>
          </a>

            <div>
            <span>
              
            </span>
          </div>
 
        </div>

        <BareModal show={openPopup} onHide={setOpenPopup} classNames='bg-black fg-white'  >
          <div className="row"    style={{padding: 2}}> 
            <div className="col-12 align-end">
              <span onClick={() => setOpenPopup(false)
              }
              style={{padding: 2}}
              >X</span>
            </div>
          </div>
          <div className="row"> 
            <div className="col-12 centered">
              
           <Image src="/images/new-xoonoo-logo.png" alt='' />
            </div>
          </div>
          <div className="row mt-2"> 
            <div className="col-12 centered">
            ¡Las Mejores Promos!</div>
          </div>
          <div className="row mt-2"> 
            <div className="col-12 centered">2X1 - 50% OFF! - HAPPY HOUR</div>
          </div>
          <div className="row mt-2"> 
            <div className="col-12 centered">y Mucho Mas...</div>
          </div>
          <div className="row mt-4"> 
            <div className="col-12 centered">
            <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
              <span style={{
                padding: 5,
                cursor: 'pointer',
                backgroundColor: '#F1AD3E',
                borderRadius: 5,
                color: 'black'
              }} >Descarga La App
              </span>
            </a>
            </div>
          </div>
          <div className="row mt-4"> 
            <div className="col-12 centered"          
            >
               <span onClick={() => setOpenPopup(false)
              }
              style={{padding: 2, color: '#929292'}}
              >No quiero ofertas</span>
              
              </div>
          </div>



        </BareModal>

      </div>

      <div className="row">
        <div className="col-sm-6 col-lg-3">
          <div className="pb-2 text-center ">
            <div>
              <a href="/docs/Colombia/terminos.html" target="blank">
                <p className="mt-0 mb-0">Términos y Condiciones</p>
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="pb-2  text-center ">
            <div>
              <a href="/docs/Colombia/privacy.html" target="blank">
                <p className="mt-0 mb-0"> Políticas de Privacidad</p>
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3">
          <div className="pb-2  text-center ">
            <div>
              <a href="/docs/Colombia/data_usage.html" target="blank">
                <p className="mt-0 mb-0">Tratamiento de Datos</p>
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3">
          <div className="pb-2  text-center ">
            <div>
              <a href="/docs/Colombia/cookies.html" target="blank">
                <p className="mt-0 mb-0">Política de Cookies</p>
              </a>

            </div>
          </div>
        </div>
      </div>

      <div className="pb-2 mt-2 text-center ">
        <div>
          <p className="mt-0 mb-0">© Copyright 2022 ZONO VENTURES SAS. All Rights Reserved</p>
        </div>
      </div>

    </div>
  );
}

export default Home