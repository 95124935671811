import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

import useTranslations from "../../lib/useTranslations";
function CardItem(props) {

  const { tr } = useTranslations();

  /* eslint-disable no-unused-vars */
  function showFavouriteBadge() {
    return (
      <div className={`favourite-heart position-absolute ${props.favIcoIconColor}`}>
        <Link to={props.linkUrl}>
          <Icofont icon='heart' />
        </Link>
      </div>
    )
  }

  /* eslint-disable no-unused-vars */
  function linkImage() {
    return (
      <Link to={props.linkUrl}>
        <Image src={props.image} className={props.imageClass} alt={props.imageAlt} style={{ height: 250, width: 250 }} />
      </Link>
    )
  }

  /* eslint-disable no-unused-vars */
  function linkTitle() {
    return (
      <Link to={props.linkUrl} className="text-black">{props.title}</Link>
    )
  }

  const timeInfo = () => {
    if (props.opensAt) {
      return (
        <p className="text-gray mb-3 time" style={{color: 'green'}}>
          <span className="bg-light  rounded-sm pl-2 pb-1 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Opens in")} {props.opensAt}
          </span>
        </p>
      )

    } else if (props.closesAt) {
      return (
        <p className="text-gray mb-3 time" style={{color: 'red'}}>
          <span className="bg-light rounded-sm pl-2 pb-1 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Closes in")} {props.closesAt}
          </span>
        </p>
      )
    } else if (props.time || props.price) {
      return (
        <p className="text-gray mb-3 time">
          {props.time ? (
            <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
              <Icofont icon='wall-clock' /> {props.time}
            </span>
          )
            : ""
          }
          {props.price ? (
            <span className="float-right text-black-50"> {props.price}</span>
          )
            : ""
          }
        </p>
      )
    }
  }

  return (
    <div className={`row list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm  ${props.classContainer}`}
      style={
        props.offline ?
          {
            opacity: "0.5"
          }
          : {}
      }

    >
      <div className="list-card-image list-card-home col-12" >
        {props.rating ? (
          <div className="star position-absolute">
            <Badge variant="success">
              <Icofont icon='star' /> {props.rating}
            </Badge>
          </div>
        )
          : ""
        }
        { /*  show favourite */}
        {props.showPromoted ? (
          <div className="member-plan position-absolute">
            <Badge variant={props.promotedVariant}>Promoted</Badge>
          </div>
        )
          : ""
        }
        {props.offline ? (
          <div className="member-plan position-absolute">
            <Badge pill variant='danger' >Offline</Badge>
          </div>
        )
          : ""
        }
        { /*  link image */}
        <Image src={props.image} className={props.imageClass} alt={props.imageAlt} />

      </div>
      <div className="p-3 position-relative col-12">
        <div className="list-card-body">
          <h6 className="mb-1">{props.title}
          </h6>
          {props.subTitle ? (
            <p className="text-gray mb-3">{props.subTitle}</p>
          )
            : ''
          }
          {timeInfo()}
        </div>
        {props.offerText ? (
          <div className="list-card-badge">
            <Badge variant={props.offerColor}>OFFER</Badge> <small>{props.offerText}</small>
          </div>
        )
          : ""
        }
      </div>
    </div>
  );
}
/* 
class CardItemXX extends React.Component {
  render() {
      return (
        <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
               <div className="list-card-image" style={{height:250,width:250}}>
                    {this.props.rating ? (
                    <div className="star position-absolute">
                      <Badge variant="success">
                        <Icofont icon='star'/> {this.props.rating}
                      </Badge>
                    </div>
                    )
                    :""
                }
                  <div className={`favourite-heart position-absolute ${this.props.favIcoIconColor}`}>
                    <Link to={this.props.linkUrl}>
                      <Icofont icon='heart'/>
                    </Link>
                  </div>
                  {this.props.showPromoted ? (
                    <div className="member-plan position-absolute">
                      <Badge variant={this.props.promotedVariant}>Promoted</Badge>
                    </div>
                    )
                      :""
                  }
                  <Link to={this.props.linkUrl}>
                    <Image src={this.props.image} className={this.props.imageClass} alt={this.props.imageAlt} style={{height:250,width:250}}/>
                  </Link>
               </div>
               <div className="p-3 position-relative">
                  <div className="list-card-body">
                     <h6 className="mb-1"><Link to={this.props.linkUrl} className="text-black">{this.props.title}</Link></h6>
                     {this.props.subTitle ? (
                       <p className="text-gray mb-3">{this.props.subTitle}</p>
                       )
                       :''
                   }
                   {(this.props.time || this.props.price)? (
                       <p className="text-gray mb-3 time">
                          {this.props.time ? (
                            <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                              <Icofont icon='wall-clock'/> {this.props.time}
                            </span> 
                            )
                            :""
                        }
              {this.props.price ? (
                            <span className="float-right text-black-50"> {this.props.price}</span>
                            )
                            :""
                          }
                       </p>
                        ):''
                   }
                  </div>
                  {this.props.offerText ? (
                    <div className="list-card-badge">
                       <Badge variant={this.props.offerColor}>OFFER</Badge> <small>{this.props.offerText}</small>
                    </div>
                    )
                    :""
                }
               </div>
            </div>
    );
  }
} */


CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  offerText: PropTypes.string,
  offerColor: PropTypes.string,
  subTitle: PropTypes.string,
  time: PropTypes.string,
  price: PropTypes.string,
  showPromoted: PropTypes.bool,
  promotedVariant: PropTypes.string,
  favIcoIconColor: PropTypes.string,
  rating: PropTypes.string,
};
CardItem.defaultProps = {
  imageAlt: '',
  imageClass: '',
  offerText: '',
  offerColor: 'success',
  subTitle: '',
  time: '',
  price: '',
  showPromoted: false,
  promotedVariant: 'dark',
  favIcoIconColor: '',
  rating: '',
}

export default CardItem;