import React, { useContext } from 'react';
import { CartContext } from '../../lib/CartContext';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Icofont from 'react-icofont';

/*
pizza delgada queso 17600
pizza delgada bocadillo 18650

medium

*/

function CheckoutItem(props) {

  const { quantity, priceUnit, price, itemName, maxValue, id, setValue } = props;
  
  const { comboProductPriceForSize } = useContext(CartContext);

  const IncrementItem = () => {
    if (quantity >= maxValue) {

    } else {
      //setQuantity(quantity + 1);
      setValue({ id: id, quantity: (quantity + 1) });
    }
  }

  const DecreaseItem = () => {
    //setQuantity(quantity - 1 );
    setValue({ id: id, quantity: (quantity - 1) });

    /*  if(quantity <= (min +1)) {
 
     }else {
         setState({ quantity: quantity - 1 });
       setValue({id:id,quantity: (quantity - 1 )});
     } */
  }
  /*  ToggleClick = () => {
     setState({ show: !show });
   } */

  const _itemOptions = (item) => {
    if (!item.selectedOptions || item.selectedOptions.length === 0) return <div className="item-options selected-options"></div>
    return (
      <div className="item-options  selected-options">
        {item.selectedOptions.map((opt) => {
          return (
            <div className="text-gray mb-0 space-between" key={"option_" + opt.id}>
              <span>{opt.group_name}:</span> <span>{opt.name}</span> <span>${opt.price}</span>
            </div>
          )
        })}

      </div>
    )
  }

  const addonNameQuantity = (addon) => {
    if (addon.quantity > 1) {
      return <span>{addon.name} ({addon.quantity})</span>
    }
    return <span>{addon.name}</span>
  }

  const _itemAddons = (item) => {
    if (!item.selectedAddons || item.selectedAddons.length === 0) return <div className="item-options selected-addons"></div>
    return (
      <div className="item-options selected-addons">
        {item.selectedAddons.map((opt) => {
          return (
            <div className="text-gray mb-0 space-between" key={"option_" + opt.id}>
              {addonNameQuantity(opt)} <span>${opt.price}</span>
            </div>
          )
        })}

      </div>
    )
  }

  const _itemCombos = (product) => {
    if (!product || product.type !== 'Combo') {
      return <></>
    }

    let chosenSize = product.chosenSize || product.size

    return (
      <div className="item-options selected-addons">
        {product.Combos && product.Combos.map((combo, index) => {
 
          return (
            <div className="text-gray mb-0 space-between" key={`combo_${index}`}>
               <span>{combo.selectedItem.name} </span> <span>${comboProductPriceForSize(combo.selectedItem, chosenSize, combo.multiplier) }</span>
            </div>
          )
        }

        )}
      </div>
    )
  }

  const _itemSize = (product) => {
    if (product.chosenSize && product.chosenSize !== '') {
      return (
        <div>
          <div className="text-gray mb-0 space-between" >
               <span>{product.chosenSize}</span>
            </div>
        </div>
      )
    }
    return <></>
  }

  //console.log("checkout Item", props.cartItem )

  if (setValue) {
    return (
      <div className="gold-members p-2 border-bottom">
        <p className="text-gray mb-0 float-right ml-2">{priceUnit}{price * quantity}</p>
        <span className="count-number float-right">
          <Button variant="outline-secondary" onClick={DecreaseItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
          <input className="count-number-input" type="text" value={quantity} readOnly />
          <Button variant="outline-secondary" onClick={IncrementItem} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
        </span>
        <div className="media">
          <div className="mr-2"><Icofont icon="ui-press" className="text-danger food-item" /></div>
          <div className="media-body">
            <p className="mt-1 mb-0 text-black">{itemName}</p>
          </div>
        </div>

        {/* chosen size */}
        {_itemSize(props.cartItem)}

        {/* combos */}
        {_itemCombos(props.cartItem)}

        {/* options */}
        {_itemOptions(props.cartItem)}


        {/* addons */}
        {_itemAddons(props.cartItem)}
      </div>
    );
  }

  return (
    <div className="gold-members p-2 border-bottom">
      <p className="text-gray mb-0 float-right ml-2">{priceUnit}{price * quantity}</p>
      <span className="count-number float-right">
        <input className="count-number-input" type="text" value={quantity} readOnly />
      </span>
      <div className="media">
        <div className="mr-2"><Icofont icon="ui-press" className="text-danger food-item" /></div>
        <div className="media-body">
          <p className="mt-1 mb-0 text-black">{itemName}</p>
        </div>
      </div>

      {/* combos */}
      {_itemCombos()}

      {/* options */}
      {_itemOptions(props.cartItem)}


      {/* addons */}
      {_itemAddons(props.cartItem)}
    </div>
  );
}

CheckoutItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  // setValue: PropTypes.func.isRequired  // if not set then this is on the thanks page
};
CheckoutItem.defaultProps = {
  show: true,
  priceUnit: '$'
}



export default CheckoutItem;