import React,{useContext} from 'react';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from '../home/ProductBox';
import { categoryData } from '../common/constant';
/* eslint-disable no-unused-vars */
import { BusinessProvider, BusinessContext } from '../../lib/BusinessContext';

const CategoriesCarousel = (props) => {
  const { setSelectedCategory } = useContext(BusinessContext);

  return (
    <OwlCarousel nav loop {...options} className="owl-carousel-category owl-theme">
      {categoryData.map((category, idx) => (
        <div className="item" key={'category_'+idx} onClick={() =>setSelectedCategory(category.name)}>
          <ProductBox
            boxClass='osahan-category-item'
            title={category.name}
            image={category.img}
            imageClass='img-fluid'
            imageAlt='carousel'
            linkUrl='#'
          />
        </div>
      ))}
    </OwlCarousel>
  );
}

const options = {
  responsive: {
    0: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
    1200: {
      items: 8,
    },
  },
  loop: true,
  lazyLoad: true,
  autoplay: true,
  dots: false,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  nav: true,
  navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}

export default CategoriesCarousel;