
import React, {   /*, useContext, useEffect, , useEffect */ } from 'react';
import {
  Modal, Button, 
 } from 'react-bootstrap';

const BareModal = ({
  children,
  title = "",
  buttonText = "OK",
  headerComponent,
  classNames = "",
  footer,
  show,
  onHide,
  callback
}) => {

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      contentClassName={classNames}
    >

      <Modal.Body>
        <div style={{ height: "auto" , }}>
          {children}
        </div>
      </Modal.Body>

    </Modal>
  );
}
export default BareModal;
/*

      <div class="modal-content"  style={{
        backgroundClip: 'padding-box',
        // backgroundColor: colour,
        border: '1px solid black',
        borderRadius: 5,
        color: colour // textColor || 'white',


      }}>
        <div color="black" class="modal-header">
          <h5 class="modal-title" id="add-address"></h5>
          <button type="button" class="close"><span aria-hidden="true">×</span>
            <span class="sr-only">Close</span></button>
        </div>
        <div class="modal-body">
          <div style="height: auto; background-color: black;">
            <img src="/images/new-xoonoo-logo.png" alt="" class=""></img>

          </div>
        </div><div class="modal-footer"><div><span>{footer}</span>
        </div>
        </div>
      </div>
*/