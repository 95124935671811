import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { BusinessContext } from '../lib/BusinessContext';
import { CartContext } from '../lib/CartContext';
import useTranslations from "../lib/useTranslations";


const ProvincePage = (props) => {
  /* eslint-disable no-unused-vars */
  //const [currentLocation, setCurrentLocation] = useState({});
  const [loading, setLoading] = useState(false)

  // const [chosenProvince, setChosenProvince] = useState(null)
  // const [chosenCity, setChosenCity] = useState(null)

  const { businesses, clearSavedData, departments, cities, selectedBusiness, selectedMenu, reloadMenu,
    setSelectedBusiness, selectedProvince, setSelectedProvince, provinceSelected,
    selectedCity,
    setSelectedCity,
    setCityName, } = useContext(BusinessContext);

  const { clearCartData } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const history = useHistory();
  const location = useLocation()

  useEffect(() => {

    if (location.state) {

    }

  }, [])


  const clearAllData = () => {
    clearSavedData()  // removes tableNum, locationData, selectedMenu, paramData
    clearCartData()
  }

  return (
    <div className="home-body">

      <div className="home-body-content mt-5 pl-4">

        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-12">
                <h1>Pide de tu Restaurante Favorito al Mejor Precio.</h1>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <h3>Conozca toda las ofertas, en la App.</h3>

              </div>
            </div>

            <div className=" centered mt-1">
            <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
              <div className="">
                <div className="centered"  >
                  <span style={{
                    padding: 10,
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 10,
                    fontSize: 15,
                  }}   >Redime Ofertas Exclusivas</span>

                </div>
              </div>
              </a>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <span className="fs-1-5 fw-400 poppins">Conoce aliados cerca de ti.</span>

              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <select className="province-selector"
                  onChange={(event) => {
                    clearAllData()
                    setSelectedCity('')
                    provinceSelected(event.target.value);

                    const location = {
                      pathname: `/p/${event.target.value}/show`,
                      state: { province: event.target.value }
                    }

                    history.replace(location)
                  }}
                >
                  <option value="-">Elije tu departamento</option>

                  {departments.map((dept, index) => {
                    if (selectedProvince == dept) {
                      return (
                        (
                          <option key={'dept_' + index} value={dept} selected>{dept}</option>
                        )
                      )
                    } else {
                      return ((
                        <option key={'dept_' + index} value={dept}>{dept}</option>
                      ))
                    }

                  })}
                </select>

              </div>
            </div>

            {selectedProvince && cities &&
              <div className="row mt-4">
                <div className="col-12">
                  <select className="province-selector"
                    onChange={(event) => {
                      let city = event.target.value
                      clearAllData()
                      setSelectedCity(city)

                      let province = selectedProvince

                      if (city == '-') { city = '' } else {
                        const location = {
                          pathname: `/c/${province}/${city}/show`,
                          state: { city: city, province: province }
                        }

                        history.push(location)
                      }

                    }}
                  >
                    {selectedCity == '' &&
                      <option value="-" selected>Elije tu ciudad</option>
                    }

                    {cities.map((city, index) => (
                      <option key={'dept_' + index} value={city}>{city}</option>
                    ))}
                  </select>

                </div>
              </div>

            }

            <div className="row mt-4 show-only-large-devices">
              <div className="col-12">
                <h3>¡Pide Yá!</h3>

              </div>
            </div>

          </div>

          <div className="col show-only-large-devices">
            <div className="row">
              <div className="col">
                <div className="mt-4 centered">
                  <img src="/images/Club_screen1.png" style={{ width: 200 }}></img>
                </div>

                <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
                  <div className="mt-4 centered">
                    <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
                  </div>
                </a>
              </div>
              <div className="col">
                <div className="mt-4 centered">
                  <img src="/images/Club_screen2.png" style={{ width: 200 }}></img>
                </div>

                <a href="https://fbh.zono.cc/?link=https://zono.cc/download&apn=cc.zono.mobile&isi=6463274860&ibi=com.xoonoo.club" target="_blank">
                  <div className="mt-4 centered">
                    <img src="/images/appstore-badge.png" style={{ width: 150 }}></img>
                  </div>
                </a>

              </div>
            </div>

          </div>


        </div>


        <div className="row mt-4 show-only-small-devices">
          <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
            <div className="col-6" >
              <div className="mt-1 centered">
                <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
              </div>

            </div>
          </a>

          <a href="hhttps://fbh.zono.cc/?link=https://zono.cc/download&apn=cc.zono.mobile&isi=6463274860&ibi=com.xoonoo.club" target="_blank">
            <div className="col-6">
              <div className="mt-1 centered">
                <img src="/images/appstore-badge.png" style={{ width: 150 }}></img>
              </div>

            </div>
          </a>

        </div>

      </div>



      <div className="pb-2 mt-2 text-center ">
        <div>
          <p className="mt-0 mb-0">© Copyright 2022 ZONO VENTURES SAS. All Rights Reserved</p>
        </div>
      </div>

    </div>
  );
}

export default ProvincePage