import * as React from 'react';
import PropTypes from 'prop-types';

// mui v4

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
//import StepContent from '@material-ui/core/StepContent';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';

import IconFont from 'react-icofont';


const QontoStepIconRoot = ({ theme, ownerState, children }) => {
  let style = {
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    }
  }

  return (
    <div style={style}>
      {children}
    </div>
  )
}



const ColorlibStepIconRoot = ({ theme, ownerState, children }) => {
  let style = {
    backgroundColor: theme && theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }

  return (
    <div style={style}>
      {children}
    </div>
  )
}


function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <IconForName icon="check" className="mr-3 icofont-3x" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  // Whether this step is active.
  // * @default false
  active: PropTypes.bool,
  className: PropTypes.string,
  //  Mark the step as completed. Is passed to child components.
  //  * @default false
  completed: PropTypes.bool,
};

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <IconForName icon="inbox" className="mr-3 icofont-3x" />,
    2: <IconForName icon="chef" className="mr-3 icofont-3x" />,
    3: <IconForName icon="check" className="mr-3 icofont-3x" />,
    4: <IconForName icon="motor-biker" className="mr-3 icofont-3x" />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}


function _generateIconFunc(icons, colour) {
  return (props) => {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  };
}


ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const IconForName = ({ icon }) => {
  return <IconFont icon={icon} className=" icofont-3x" />

}
/*
function _isEmpty(value) {
  return value === undefined || value === null || value === '' || value === 0
}

function _alignedRight(detailColour, label, value) {
  return (
    <div className="row cart-total">

      <div className="col-12 total"
        style={{
          color: detailColour,
          textAlign: 'end'
        }}
      >{label}: $ {value}</div>

    </div>
  )
}
 
function _costIfDefined(detailColour, label, value) {
  if (_isEmpty(value)) {
    return (
      <div></div>
    )
  }
  return _alignedRight(detailColour, label, value)
}
 */
function StandardStepperComponent(props) {
  //const [activeStep, setActiveStep] = React.useState(props.activeStep);

  /*   const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    }; */

  return (
    <div className="order-status-stepper">
      <Stepper activeStep={props.activeStep} orientation="horizontal" alternativeLabel >
        {props.steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

    </div>
  )
}

export function RejectedStepper(props) {
  let activeStep = 1;
  const order = props.order;

  const steps = [
    {
      label: '¡Pedido Pendiente!',
      description: 'Estamos esperando que el comercio confirma tu pedido.  Por favor no cierres esta pagina.',
    },
    {
      label: '¡Pedido Rechazado!',
      description:
        'El comercio rechaza tu pedido.',
    },

  ];

  return (
    <div>


      <StandardStepperComponent activeStep={activeStep} steps={steps} />
    </div>
  )
}

export function StandardStepper(props) {
  // determine the active step based on the order status
  let activeStep = 0;
  const order = props.order;

  const steps = [
    {
      label: '¡Pedido Pendiente!',
      //description: 'Estamos esperando que el comercio confirma tu pedido.  Por favor no cierres esta pagina.',
    },
    {
      label: '¡Pedido Confirmado!',
      //description:        'El comercio esta preparando tu pedido.',
    },
    {
      label: '¡Pedido Listo!',
      //description: 'Tu pedido esta listo y esta esperando ser despachado',
    },
    {
      label: '¡Pedido Enviado!',
      //description: 'Tu pedido esta en camino hacia ti.',
    },
  ];

  switch (order.status) {
    case 'new':
      activeStep = 0;
      break;
    case 'confirmed':
    case 'preparing':
    case 'deliveryaccepted':
      activeStep = 1;
      break;
    case 'ready':
      activeStep = 2;
      break;
    case 'dispatched':
    case 'delivered':
      activeStep = 3;
      break;
    default:
      activeStep = 0;
  }

  if (order.status === 'delivered') {
    steps[3] = {
      label: '¡Pedido Entregado!',
      //description: 'Tu pedido ha sido entregado.',
    }
  }

  //console.log("StandardStepper activeStep", activeStep, order.latitude, order.longitude)

  return (
    <div>


      <StandardStepperComponent activeStep={activeStep} steps={steps} />
    </div>
  )
}

export function TakeawayStepper(props) {
  // determine the active step based on the order status
  let activeStep = 0;
  const order = props.order;

  const steps = [
    {
      label: '¡Pedido Pendiente!',
      description: 'Estamos esperando que el comercio confirma tu pedido.  Por favor no cierres esta pagina.',
    },
    {
      label: '¡Pedido Confirmado!',
      description:
        'El comercio esta preparando tu pedido.',
    },
    {
      label: '¡Pedido Listo!',
      description: 'Tu pedido esta listo para ser recogido.',
    },
    {
      label: '¡Pedido Recogido!',
      description: 'Tu pedido ha sido recogido.',
    },
  ];

  switch (order.status) {
    case 'new':
      activeStep = 0;
      break;
    case 'confirmed':
    case 'preparing':
      activeStep = 1;
      break;
    case 'ready':
      activeStep = 2;
      break;
    case 'dispatched':
      activeStep = 3;
      break;
    default:
      activeStep = 0;
  }

  return (
    <div>

      <StandardStepperComponent activeStep={activeStep} steps={steps} />
    </div>
  )
}

export function CostsStepper(props) {
  // there is only 1 step that is active in this stepper
  // when the order status is updated another stepper will be rendered
  //let activeStep = 1;
  const order = props.order;

  console.log("cost stepper with", props)
  /* 
    let step1 = {
      label: '¡Pedido Pendiente!',
      description: 'Estamos esperando que el comercio confirma tu pedido.  Por favor no cierres esta pagina.',
    }
  
    let step2 = {
      label: '¡Confirmación Requerido!',
      description:
        'Se requiere la confirmación del costo final para procesar su pedido.',
    }
   
    if (order.status === 'deliverycost') {
      step2 = {
        label: 'Buscando mensajero',
        description:
          'Estamos buscando un mensajero para llevar tu pedido.',
      }
    }
  
   /*  const steps = [
      step1,
      step2,
      {
        label: '¡Pedido Confirmado!',
        description:
          'El comercio esta preparando tu pedido.',
      },
      {
        label: '¡Pedido Listo!',
        description: 'Tu pedido esta listo y esta esperando ser despachado',
      },
      {
        label: '¡Pedido Enviado!',
        description: 'Tu pedido esta en camino hacia ti.',
      },
    ]; */
  /* 
    function _stepContent(step) {
      if (order.shippingcost > 0) {
        return (
          <StepContent>
            <Typography variant="h6" >{step.description}</Typography>
  
            {_costIfDefined(props.detailColour, 'Envio', order.shippingcost)}
  
            {_alignedRight(props.detailColour, 'Nuevo Total', order.totalcost)}
  
            <ConfirmButtonContent order={order} onConfirm={props.onConfirm} />,
  
          </StepContent>
        )
      }
      return <StepContent>
        <Typography variant="h6" >
          {step.description}
        </Typography></StepContent>
    } */

  return (
    <div>


      <div className="order-status-stepper">

        <Stepper activeStep={props.activeStep} orientation="horizontal" alternativeLabel >
          {props.steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={_generateIconFunc({
                1: <IconForName icon="inbox" className="mr-3 icofont-3x" />,
                2: <IconForName icon={order.status === 'deliverycost' ? "search" : 'price'} className="mr-3 icofont-3x" />,
                3: <IconForName icon="chef" className="mr-3 icofont-3x" />,
                4: <IconForName icon="check" className="mr-3 icofont-3x" />,
                5: <IconForName icon="motor-biker" className="mr-3 icofont-3x" />,
              }, props.detailColour)}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>


      </div>
    </div>
  )
}

function ConfirmButtonContent(props) {
  return (
    <div className="row cart_control-button-row">
      <div className="col-md-12 confirm-button-col ">
        <div className="new-order-button" onClick={props.onConfirm} >
          Confirmar
        </div>
      </div>
    </div>
  )
}

function tablePaymentStepper(props) {
  // determine the active step based on the order status
  let activeStep = 0;
  const order = props.order;

  switch (order.status) {
    case 'new':
      activeStep = 0;
      break;
    case 'preparing':
      activeStep = 1;
      break;
    case 'ready':
      activeStep = 2;
      break;
    case 'paymentrequired':
      activeStep = 3;
      break;
    case 'paid':
      activeStep = 4;
      break;

    default:
      activeStep = 0;
      break;
  }


  //console.log("table order", order)
  return (
    <div>

      <StepperWithContent activeStep={activeStep} steps={[
        {
          label: '¡Pedido Pendiente!',
          description: 'Estamos esperando que el comercio prepara tu pedido.  Por favor no cierres esta pagina.',
        },
        {
          label: '¡Preparando Pedido!',
          description:
            'El comercio esta preparando tu pedido.',
        },
        {
          label: '¡Pedido Listo!',
          description: 'Tu pedido esta listo para llevar a la mesa',
        },
        {
          label: '¡Pedido Pago Requerido!',
          description:
            'Se requiere el pago del pedido.',
        },
        {
          label: '¡Pedido Pagado!',
          description:
            'El pago ha sido recibido, gracias.',
        },

      ]}
        iconComponent={
          _generateIconFunc({
            1: <IconForName icon="inbox" className="mr-3 icofont-3x" />,
            2: <IconForName icon="chef" className="mr-3 icofont-3x" />,
            3: <IconForName icon="check" className="mr-3 icofont-3x" />,
            4: <IconForName icon="pay" className="mr-3 icofont-3x" />,
            5: <IconForName icon="money" className="mr-3 icofont-3x" />,

          })
        }

      />

      {order.status == "paymentrequired" && <h6>Por favor pagar en caja</h6>}
    </div>
  )
}

export function PaymentStepper(props) {
  // determine the active step based on the order status
  let activeStep = 0;
  const order = props.order;

  if (order.deliverType == "table") {
    return tablePaymentStepper(props)
  }

  const steps = [
    {
      label: '¡Pedido Pendiente!',
      description: 'Estamos esperando que el comercio confirma tu pedido.  Por favor no cierres esta pagina.',
    },
    {
      label: '¡Pedido Pago Requerido!',
      description:
        'Se requiere el pago para procesar su pedido.',
      content: <ConfirmButtonContent order={order} onConfirm={props.onConfirm} />,
    },
    {
      label: '¡Pedido Pagado!',
      description:
        'El pago ha sido recibido, gracias.',
    },
    {
      label: '¡Pedido Confirmado!',
      description:
        'El comercio esta preparando tu pedido.',
    },
    {
      label: '¡Pedido Listo!',
      description: 'Tu pedido esta listo y esta esperando ser despachado',
    },
    {
      label: '¡Pedido Enviado!',
      description: 'Tu pedido esta en camino hacia ti.',
    },
  ];

  switch (order.status) {
    case 'new':
      activeStep = 0;
      break;
    case 'paymentrequired':
      activeStep = 1;
      break;
    case 'paid':
      activeStep = 2;
      break;
    case 'confirmed':
      activeStep = 3;
      break;
    case 'ready':
      activeStep = 4;
      break;
    case 'sent':
      activeStep = 5;
      break;
    default:
      activeStep = 0;
      break;
  }

  return (
    <div>


      <StepperWithContent activeStep={activeStep} steps={steps}
        iconComponent={
          _generateIconFunc({
            1: <IconForName icon="inbox" className="mr-3 icofont-3x" />,
            2: <IconForName icon="payment" className="mr-3 icofont-3x" />,
            3: <IconForName icon="receipt" className="mr-3 icofont-3x" />,
            4: <IconForName icon="chef" className="mr-3 icofont-3x" />,
            5: <IconForName icon="check" className="mr-3 icofont-3x" />,
            6: <IconForName icon="motor-biker" className="mr-3 icofont-3x" />,
          })
        }

      />
    </div>
  )
}

function ChangedIcons(props) {

  const { active, completed, className } = props;

  const icons = {
    1: <IconForName icon="inbox" className="mr-3 icofont-3x" />,
    2: <IconForName icon="exchange" className="mr-3 icofont-3x" />,
    3: <IconForName icon="check" className="mr-3 icofont-3x" />,
    4: <IconForName icon="check" className="mr-3 icofont-3x" />,
    5: <IconForName icon="chef" className="mr-3 icofont-3x" />,
    6: <IconForName icon="check" className="mr-3 icofont-3x" />,
    7: <IconForName icon="motor-biker" className="mr-3 icofont-3x" />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export function ChangedStepper(props, costs) {
  // determine the active step based on the order status
  let activeStep = 0;
  const order = props.order;

  const steps = [
    {
      label: '¡Pedido Pendiente!',
      description: 'Estamos esperando que el comercio confirma tu pedido.  Por favor no cierres esta pagina.',
    },
    {
      label: '¡Pedido Requerido Cambiar!',
      description:
        'Se requiere cambiar su pedido. El negocio te llama para confirmar el cambio.',
    },
    {
      label: '¡Confirmación Requerido!',
      description:
        'Haz cambiando de tu pedido.  Se requiere confirmación para procesar su pedido.',
      content: <ConfirmButtonContent order={order} onConfirm={props.onConfirm} />,
    },
    {
      label: '¡Pedido Confirmado!',
      description:
        'Estamos esperando que el comercio aceptar tu pedido.',
    },
    {
      label: '¡Pedido Aceptado!',
      description:
        'El comercio esta preparando tu pedido.',
    },
    {
      label: '¡Pedido Listo!',
      description: 'Tu pedido esta listo y esta esperando ser despachado',
    },
    {
      label: '¡Pedido Enviado!',
      description: 'Tu pedido esta en camino hacia ti.',
    },
  ];

  switch (order.status) {
    case 'new':
      activeStep = 0;
      break;
    case 'change':
      activeStep = 1;
      break;
    case 'confirmrequired':
      activeStep = 2;
      break;
    case 'confirmed':
      activeStep = 3;
      break;
    case 'accepted':
      activeStep = 4;
      break;
    case 'ready':
      activeStep = 5;
      break;
    case 'sent':
      activeStep = 6;
      break;
    default:
      activeStep = 0;
      break;
  }

  return (
    <div>
      <StepperWithContent activeStep={activeStep} steps={steps} iconComponent={ChangedIcons} />
    </div>
  )
}

function StepperWithContent(props) {
  /*  const [activeStep, setActiveStep] = React.useState(props.activeStep);
 
   const handleNext = () => {
     setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };
 
   const handleBack = () => {
     setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };
 
   const handleReset = () => {
     setActiveStep(0);
   }; */

  return (
    <div className="order-status-stepper">
      <Stepper activeStep={props.activeStep} orientation="horizontal" alternativeLabel >
        {props.steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={props.iconComponent}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>


    </div>
  )
}

/*
 <div className="row ">

        <div className="col status-title-row">
          {steps[activeStep].label}
        </div>

      </div>
        */